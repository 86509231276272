import axios from "../axiosInstance";

/*
SuperAdmin API's for organization
*/

// API to update the organization
const updateOrganizationSuperAdminAPI = async function (data) {
	try {
		const res = await axios.post("/api/superadmin/organization/update", data);

		if (res.status === 200) {
			return res.data;
		}
		throw new Error("Failed to update organization");
	} catch (e) {
		console.error(e);
		const message = e?.response?.data?.message || e?.message;
		throw new Error(message);
	}
};

// API to deactivate the organization
const deactivateOrganizationSuperAdminAPI = async function (data) {
	try {
		const res = await axios.post("/api/superadmin/organization/deactivate", data);

		if (res.status === 200) {
			return res.data;
		}
		throw new Error("Failed to deactivate organization");
	} catch (e) {
		console.error(e);
		const message = e?.response?.data?.message || e?.message;
		throw new Error(message);
	}
};

// API to add promo credits
const addPromoCreditsSuperAdminAPI = async function (data) {
	try {
		const res = await axios.post("/api/superadmin/transactions/promo-topup", data);

		if (res.status === 200) {
			return res.data;
		}
		throw new Error("Failed to update organization");
	} catch (e) {
		console.error(e);
		const message = e?.response?.data?.message || e?.message;
		throw new Error(message);
	}
};

// API to renew organization plan
const renewOrganizationPlanSuperAdminAPI = async function (data) {
	try {
		const res = await axios.post("/api/superadmin/plans/renew-plan", data);

		if (res.status === 200) {
			return res.data;
		}
		throw new Error("Failed to renew plan");
	} catch (e) {
		console.error(e);
		const message = e?.response?.data?.message || e?.message;
		throw new Error(message);
	}
};

// API to fetch the organizations
const fetchOrganizationsSuperAdminAPI = async function () {
	try {
		const res = await axios.get("/api/superadmin/organization/list");

		if (res.data) {
			return res.data.organizations;
		}
		throw new Error("Failed to fetch organizations");
	} catch (e) {
		console.error(e);
		const message = e?.response?.data?.message || e?.message;
		throw new Error(message);
	}
};

// API to update renewal date to today
const updateRenewDateSuperAdminAPI = async function (data) {
	try {
		const res = await axios.post("/api/superadmin/plans/update-renew-date-today", data);

		if (res.status === 200) {
			return res.data;
		}
		throw new Error("Failed to renew plan");
	} catch (e) {
		console.error(e);
		const message = e?.response?.data?.message || e?.message;
		throw new Error(message);
	}
};

const getExportOrganizationInterviews = async (organizationId, year, month, showCompletedInterviews) => {
	try {
		if (organizationId) {
			console.log(organizationId);
			console.log(year);
			console.log(month);
			const response = await axios({
				method: "post",
				url: `/api/organization/interviews/download`,
				responseType: "blob",
				data: {
					organizationId,
					year,
					month,
					showCompletedInterviews,
				},
			});
			return response;
		} else {
			throw new Error("Pass organizationId as well");
		}
	} catch (e) {
		console.log("failed to create xl file", organizationId);
		const message = e?.response?.data?.message || e.message;
		throw new Error(message);
	}
};
export {
	updateOrganizationSuperAdminAPI,
	deactivateOrganizationSuperAdminAPI,
	renewOrganizationPlanSuperAdminAPI,
	addPromoCreditsSuperAdminAPI,
	fetchOrganizationsSuperAdminAPI,
	updateRenewDateSuperAdminAPI,
	getExportOrganizationInterviews,
};
