import WorkIcon from "@mui/icons-material/Work";
import PersonIcon from "@mui/icons-material/Person";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { Countdown } from "./countdown";
import { InterviewRescheduler } from "./interview-rescheduler";
import { useEffect, useMemo, useState } from "react";

export const PreInterviewDetailsDisplay = ({ details }) => {
	const {
		interviewReport: {
			schedule,
			cancelled,
			reasonForCancellation,
			firstName,
			lastName,
			allowRescheduleByCandidate,
			maxRescheduleDateForCandidate,
			interviewTimeZone,
			joinEarly,
			joinOption,
			timeWindow,
			interviewCompleted,
			browserClosed,
			activeSession,
			disconnected,
		},
		openingTitle,
		orgName,
		supportName,
		supportPhone,
	} = details;

	const [joiningMessage, setJoiningMessage] = useState(
		calculateJoinTimeRange(schedule, joinOption, joinEarly, timeWindow, supportPhone, supportName),
	);
	const [isTimeout, setIsTimeout] = useState(false);

	// Check for the show-rescheduler parameter
	const showRescheduler = useMemo(() => {
		const urlParams = new URLSearchParams(window.location.search);
		return urlParams.get("show-rescheduler") === "true";
	}, []);

	const formattedDateTime = useMemo(() => {
		if (schedule) return getFormattedDateAndTime(schedule);
		else return "Date Not Specified";
	}, []);

	useEffect(() => {
		if (isTimeout || joinOption === "anytime") return;
		const interval = setInterval(() => {
			const message = calculateJoinTimeRange(
				schedule,
				joinOption,
				joinEarly,
				timeWindow,
				supportPhone,
				supportName,
			);
			if (message.type === "timeOut") {
				setIsTimeout(true);
				setJoiningMessage(message);
				clearInterval(interval);
			}
		}, 10000);
		return () => clearInterval(interval);
	}, [schedule, joinOption, joinEarly, timeWindow, isTimeout]);

	// Render the schedule information section
	const renderScheduleInfo = () => {
		if (cancelled) {
			return (
				<div className="bg-red-50 border border-red-200 p-3 rounded-md text-red-800">
					<h4 className="font-semibold">Interview Cancelled</h4>
					{reasonForCancellation && <p>Reason: {reasonForCancellation}</p>}
				</div>
			);
		}
		if (interviewCompleted) {
			return (
				<div className="bg-green-50 border border-green-200 p-3 rounded-md text-green-800">
					<h4 className="font-semibold">Interview Completed</h4>
				</div>
			);
		}
		if (browserClosed || disconnected) {
			return (
				<div className="bg-red-50 border border-red-200 p-3 rounded-md text-red-800">
					<h4 className="font-semibold">
						It looks like your interview session was interrupted. If this was unintentional or you
						need assistance to continue, please contact {supportPhone}({supportName}).
					</h4>
				</div>
			);
		}
		if (activeSession) {
			return (
				<div className="bg-green-50 border border-green-200 p-3 rounded-md text-green-800">
					<h4 className="font-semibold">
						Your interview is in progress. Please complete all required steps before exiting.
					</h4>
				</div>
			);
		}

		if (!schedule) return null;

		return (
			<>
				<div className="mb-4 p-3 bg-blue-50 rounded-md">
					<div className="flex items-center text-blue-800 mb-2">
						<AccessTimeIcon className="mr-2" />
						<span className="font-medium">{formattedDateTime}</span>
					</div>
					<Countdown
						targetTime={schedule}
						joinEarly={joinEarly}
						joinOption={joinOption}
						timeWindow={timeWindow}
					/>
				</div>
				<div className="mt-2 mb-3">
					<p
						className={`font-medium mb-4 ${joiningMessage.type === "timeOut" ? "text-red-500" : "text-gray-500"}`}
					>
						{joiningMessage.message}
					</p>
				</div>
				{showRescheduler ? (
					allowRescheduleByCandidate && joinEarly !== "anytime" ? (
						<InterviewRescheduler
							schedule={schedule}
							maxRescheduleDateForCandidate={maxRescheduleDateForCandidate}
							interviewTimeZone={interviewTimeZone}
						/>
					) : (
						<div className="bg-red-50 border border-red-200 p-3 rounded-md text-red-800">
							<h4 className="font-semibold mb-2">Unable to Reschedule Online</h4>
							<p className="text-sm mb-2">
								The recruiter has disabled online rescheduling for this interview. If you need
								to reschedule, please contact:
							</p>
							<div className="ml-2 text-sm">
								<p>
									<span className="font-medium">Recruiter:</span> {supportName}
								</p>
								<p>
									<span className="font-medium">Phone:</span> {supportPhone}
								</p>
							</div>
							<p className="text-xs mt-2 text-red-600">
								Please contact well in advance if you need to reschedule.
							</p>
						</div>
					)
				) : null}
			</>
		);
	};

	return (
		<div className="max-w-md mx-auto bg-white shadow-lg rounded-lg p-6 border border-gray-200">
			<div className="mb-4">
				<h2 className="text-2xl font-bold text-gray-800 flex items-center">
					<WorkIcon className="mr-2 text-blue-600" />
					{openingTitle}
				</h2>
				<p className="text-lg text-gray-600">{orgName}</p>
			</div>

			<div className="mb-4">
				<h3 className="text-xl font-semibold text-gray-700 flex items-center">
					<PersonIcon className="mr-2 text-blue-600" />
					{firstName} {lastName}
				</h3>
			</div>

			{renderScheduleInfo()}
		</div>
	);
};

const getFormattedDateAndTime = (schedule) => {
	try {
		return new Intl.DateTimeFormat("en-US", {
			weekday: "long",
			month: "long",
			day: "numeric",
			year: "numeric",
			hour: "numeric",
			minute: "numeric",
			hour12: true,
		}).format(new Date(schedule));
	} catch {
		return "";
	}
};

const calculateJoinTimeRange = (schedule, joinOption, joinEarly, timeWindow, supportPhone, supportName) => {
	try {
		const interviewTime = new Date(schedule);
		if (isNaN(interviewTime.getTime())) throw new Error();

		const diffMinutes = (interviewTime.getTime() - new Date().getTime()) / 60000;
		const earlyJoinTime = new Date(interviewTime.getTime() - 10 * 60000);
		const lateJoinTime = new Date(interviewTime.getTime() + 10 * 60000);
		const maxLateJoinTime = new Date(interviewTime.getTime() + timeWindow * 60 * 60000);

		if (joinOption === "anytime") {
			return {
				message: `You can join at any time, regardless of the scheduled date, as long as the position remains open.
					However, we encourage you to join as soon as possible to maximize your chances.`,
				type: "normal",
			};
		}

		if (
			(joinOption === "onTime" && diffMinutes < -10) ||
			(joinOption === "timeWindow" && diffMinutes < -timeWindow * 60)
		) {
			return {
				message: `The interview window for your scheduled session has closed. Unfortunately, you can no longer join this interview. If you believe this is an error or need assistance, please contact ${supportPhone}(${supportName}).`,
				type: "timeOut",
			};
		}

		if (joinOption === "onTime") {
			if (joinEarly) {
				return {
					message: `You can join between ${convertToAMPM(earlyJoinTime)} and ${convertToAMPM(lateJoinTime)} on ${formatDate(lateJoinTime)}.`,
					type: "normal",
				};
			} else if (!joinEarly && diffMinutes > 0) {
				return {
					message: `You can join between ${convertToAMPM(schedule)} and ${convertToAMPM(lateJoinTime)} on ${formatDate(lateJoinTime)}.`,
					type: "normal",
				};
			}
		}

		if (joinOption === "timeWindow") {
			if (joinEarly) {
				return {
					message: `You can join anytime between ${convertToAMPM(earlyJoinTime)} on ${formatDate(earlyJoinTime)} and ${convertToAMPM(maxLateJoinTime)} on ${formatDate(maxLateJoinTime)}.`,
					type: "normal",
				};
			} else if (!joinEarly && diffMinutes > 0) {
				return {
					message: `You can join between ${convertToAMPM(schedule)} on ${formatDate(schedule)} and ${convertToAMPM(maxLateJoinTime)} on ${formatDate(maxLateJoinTime)}.`,
					type: "normal",
				};
			}
		}
		return { message: "", type: "" };
	} catch {
		return { message: "", type: "" };
	}
};

function convertToAMPM(scheduleString) {
	try {
		let date = new Date(scheduleString);
		let hours = date.getHours();
		let minutes = date.getMinutes();
		let ampm = hours >= 12 ? "PM" : "AM";
		hours = hours % 12 || 12;
		return `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")} ${ampm}`;
	} catch {
		return "";
	}
}

function formatDate(scheduleString) {
	try {
		let date = new Date(scheduleString);
		let day = date.getDate();
		let month = date.toLocaleString("default", { month: "long" });
		let suffix = "th";
		if (day % 10 === 1 && day !== 11) suffix = "st";
		else if (day % 10 === 2 && day !== 12) suffix = "nd";
		else if (day % 10 === 3 && day !== 13) suffix = "rd";
		return `${day}${suffix} ${month}`;
	} catch {
		return "";
	}
}
