import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { Box, Typography, Button, TextField, useTheme, ThemeProvider } from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate, useParams } from "react-router-dom";
import { handleInterviewProcess } from "../utilities/interviewReportsApi";
import { fetchOpeningByIdForInterview } from "../utilities/openingsApi";
import { fetchInterviewReportByIdForInterview } from "../utilities/interviewReportsApi";
import { useDispatch, useSelector } from "react-redux";
import { getOrganizationName } from "../features/organization/organizationSlice";
import { clientLogger as clientLoggerApi } from "../utilities/loggingApi";
import { CountrySelector } from "../components/country-selector/country-selector";
import { isValidPhoneNumber } from "../utilities/validation";
import { DEFAULT_COUNTRY } from "../components/country-selector/countries";
import Preface from "../components/Interview/Preface";

const initialUserDetails = {
	firstName: "",
	lastName: "",
	preferredName: "",
	email: "",
	country: DEFAULT_COUNTRY,
	phoneNumber: "",
	experience: "",
	isProfileSearchable: false,
};

const UserDetails = () => {
	const theme = useTheme();
	const params = useParams();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [isResumeRequired, setisResumeRequired] = useState(false);
	const [userDetails, setUserDetails] = useState(initialUserDetails);
	const [orgName, setOrgName] = useState("");
	const [orgLogoUrl, setOrgLogoUrl] = useState("");
	const [snackbarOpen, setSnackBarOpen] = useState(false);
	const [snackBarMessage, setSnackBarMessage] = useState("");
	const [savingUserDeets, setSavingUserDeets] = useState(false);
	const [resume, setResume] = useState(null);
	const { organization } = useSelector((state) => state.organization);
	const [openingStatus, setOpeningStatus] = useState(true);
	const [visitedPreface, setVisitedPreface] = useState(false);
	const [openingData, setOpeningData] = useState(null);

	useEffect(() => {
		if (organization?.organizationName) {
			const { organizationName = "" } = organization;
			if (organizationName) {
				setOrgName(organizationName);
				setOrgLogoUrl(organization?.orgLogoUrl);
			}
		}
	}, [organization]);

	const openDatabase = () => {
		return new Promise((resolve, reject) => {
			const request = indexedDB.open("canDB", 1);

			request.onerror = function (event) {
				reject("Database error: " + event.target.errorCode);
			};

			request.onsuccess = function (event) {
				resolve(event.target.result);
			};

			// Create the object store if needed
			request.onupgradeneeded = function (event) {
				let db = event.target.result;
				db.createObjectStore("canDB", {
					keyPath: "id",
					// autoIncrement: true,
				});
			};
		});
	};

	const checkAndRedirectIfSessionExists = async () => {
		const db = await openDatabase();
		const transaction = db.transaction(["canDB"], "readonly");
		const objectStore = transaction.objectStore("canDB");

		const request = objectStore.get(1);
		request.onsuccess = async function (event) {
			const cursor = event.target.result;
			if (cursor) {
				if (cursor?.openingId === params.openingId) {
					try {
						const resp = await fetchInterviewReportByIdForInterview(cursor?.interviewReportId);
						if (Array.isArray(resp) && resp.length === 0) {
							console.log("No interview report found");
						} else {
							navigate(`start/${cursor?.interviewReportId}`, {
								replace: true,
							});
						}
					} catch (error) {
						if (error.message === "No report with the given ID") {
							console.log("No interview report found");
						} else {
							console.log("Error fetching interview report:", error.message);
						}
					}
				} else {
					console.log("Different openingID. Proceed with the form.");
				}
			} else {
				console.log("No matching session found. Display the form.");
			}
		};
		request.onerror = function (event) {
			console.error("Error checking for existing session:", event.target.errorCode);
		};
	};

	const storeSessionInfo = async (openingId, interviewReportId) => {
		const db = await openDatabase();
		const transaction = db.transaction(["canDB"], "readwrite");
		const objectStore = transaction.objectStore("canDB");

		const sessionInfo = {
			id: 1,
			openingId: openingId,
			interviewReportId: interviewReportId,
		};

		const request = objectStore.put(sessionInfo);
		request.onsuccess = function (event) {
			localStorage.setItem("alreadyHasAnActiveTab", "false");
		};
		request.onerror = function (event) {
			console.error("Error storing session info:", event.target.errorCode);
		};
	};

	useEffect(() => {
		async function getOpeningDetails() {
			if (params.openingId) {
				const res = await fetchOpeningByIdForInterview(params.openingId);
				setOpeningData(res);
				if (!res?.status || !res?.canConductInterview) {
					setOpeningStatus(false);
					return;
				}
				checkAndRedirectIfSessionExists();
				if (res) {
					if ("questionsBasedOnResume" in res) {
						setisResumeRequired(res.questionsBasedOnResume);
					}
				}
				console.log(orgName);
				if (!orgName && res.organizationId) {
					try {
						dispatch(getOrganizationName(res.organizationId));
					} catch (error) {
						console.log(error);
					}
				}
			}
		}
		getOpeningDetails();
	}, [params]);

	const isValidEmail = (email) => {
		// Regex Explanation:
		// ^ asserts position at start of the string
		// [^@]+ matches one or more characters that are not '@'
		// @ matches the '@' character
		// [^@]+ matches one or more characters that are not '@' ensuring no additional '@' is in the domain
		// \. matches the '.' character
		// [^@.]+ matches one or more characters that are not '@' or '.', ensuring no additional '.' or '@' after the domain
		// $ asserts position at the end of the string
		// The part before the '@' symbol does not contain '@'.
		// The part between the '@' and the '.' does not contain another '@' or '.'.
		// The part after the '.' does not contain another '@' or '.'.
		const emailRegex = /^[^@]+@[^@]+\.[^@.]+$/;
		return emailRegex.test(email);
	};

	const handleUserDetailsChange = (e) => {
		const { name, value, type, checked } = e.target;
		setUserDetails((prev) => {
			if (type === "checkbox") {
				return { ...prev, [name]: checked };
			} else {
				return { ...prev, [name]: value };
			}
		});
	};

	const handleResumeUpload = (e) => {
		const file = e.target.files[0];
		setResume(file);
	};

	const handleUserDetailsSubmit = async (e) => {
		e.preventDefault();

		// Validate the user input details
		if (
			userDetails.firstName.trim() === "" ||
			userDetails.lastName.trim() === "" ||
			userDetails.preferredName.trim() === "" ||
			userDetails.email.trim() === "" ||
			userDetails.phoneNumber.trim() === "" ||
			!isValidEmail(userDetails.email) ||
			!isValidPhoneNumber(userDetails.phoneNumber) ||
			+userDetails.experience < 0 ||
			userDetails.experience === "" ||
			!userDetails.country
		) {
			// Set the appropriate error message
			let message = "Few Details are missing!";
			if (!isValidEmail(userDetails.email)) {
				message = "Invalid email format!";
			} else if (!isValidPhoneNumber(userDetails.phoneNumber)) {
				message = "Phone number must be between 6 and 11 digits and contain only numbers.";
			} else if (+userDetails.experience < 0 || userDetails.experience === "") {
				message = "Invalid experience";
			}

			setSnackBarMessage(message);
			setSnackBarOpen(true);
			return;
		}
		const preferredNamePattern = /^[a-zA-Z ]{3,30}$/; // 3-30 characters, only alphabets and spaces
		const isPreferredNameValid =
			preferredNamePattern.test(userDetails.preferredName) &&
			!userDetails.preferredName.startsWith("aws:");

		if (!isPreferredNameValid) {
			setSnackBarMessage(
				"Preferred name must be 3-30 characters long and must not contain any special characters or numbers.",
			);
			setSnackBarOpen(true);
			return;
		}

		// Prepare the form data
		const formData = new FormData();
		for (let key in userDetails) {
			if (key === "email") {
				userDetails[key] = userDetails[key]?.toLowerCase();
			}
			if (typeof userDetails[key] === "object" && userDetails[key] !== null) {
				formData.append(key, JSON.stringify(userDetails[key]));
			} else {
				formData.append(key, userDetails[key]);
			}
		}

		// Handle resume requirement
		if (isResumeRequired) {
			if (resume !== null) {
				formData.append("resume", resume);
			} else {
				setSnackBarMessage("Resume field is required");
				setSnackBarOpen(true);
				return;
			}
		}

		formData.append("openingId", params.openingId);
		setSavingUserDeets(true);

		// Perform the API call
		const response = await handleInterviewProcess(formData);
		// console.log(`response: ${JSON.stringify(response)}`);

		if (response?.failed) {
			// Handle failure
			setSnackBarMessage(response?.message);
			setSnackBarOpen(true);
		} else {
			function clientLogger(message, data = {}) {
				const clientLoggerData = {
					logStreamName: response?.data,
					...data,
				};
				clientLoggerApi(message, clientLoggerData);
			}
			clientLogger(
				`User details submitted successfully. ${response?.existing ? "Existing interview found redirecting to interview page." : "New interview created."}`,
			);
			setSnackBarMessage(response?.message ? response?.message : "Details saved successfully.");
			setSnackBarOpen(true);
			await storeSessionInfo(params.openingId, response?.data);
			navigate(`start/${response?.data}`, { replace: true });
		}

		setSavingUserDeets(false);
	};

	const handleSnackBarClose = (e) => {
		setSnackBarOpen(false);
	};

	const action = (
		<React.Fragment>
			<IconButton size="small" aria-label="close" color="inherit" onClick={handleSnackBarClose}>
				<CloseIcon fontSize="small" />
			</IconButton>
		</React.Fragment>
	);

	return (
		<ThemeProvider theme={theme}>
			{openingStatus ? (
				<Box className="bg-gray-200 min-h-screen pb-3">
					<Box className="w-full bg-white p-4 flex justify-between items-center border-b border-gray-200">
						<Box className="flex flex-row items-center justify-center">
							{orgLogoUrl && (
								<Box className="flex items-center">
									<img
										src={orgLogoUrl}
										alt="logo"
										className="h-10 w-10 mr-2"
										style={{ objectFit: "contain" }}
									/>
								</Box>
							)}
							<Typography
								variant="span"
								className={`text-xl font-bold ${orgLogoUrl ? "" : "pl-10"}`}
							>
								{orgName ? orgName : "Acme Corp"}
							</Typography>
						</Box>
					</Box>
					{visitedPreface ? (
						(isMobile ? openingData?.isMobileInterviewAllowed : true) ? (
							<>
								<Box className="flex justify-center space-x-4 mt-7">
									<Box id="step1Bar" className="bg-[#10B981] rounded h-2 w-24"></Box>
									<Box id="step2Bar" className="bg-gray-300 rounded h-2 w-24"></Box>
									<Box id="step3Bar" className="bg-gray-300 rounded h-2 w-24"></Box>
									<Box id="step4Bar" className="bg-gray-300 rounded h-2 w-24"></Box>
									{openingData?.isFaceMatchRequired && (
										<Box id="step5Bar" className="bg-gray-300 rounded h-2 w-24"></Box>
									)}
								</Box>
								<Box
									id="panel1"
									className="bg-white p-6 rounded-lg shadow-lg w-3/4 md:w-1/2 lg:w-1/3 mt-7 mx-auto"
								>
									<h2 className="text-center text-2xl font-bold mb-6">User Information</h2>
									<form>
										<Box className="flex space-x-4 mb-4">
											<div className="w-1/2">
												<label
													htmlFor="firstName"
													className="block text-sm font-medium text-gray-600 mb-2"
												>
													First Name
												</label>
												<TextField
													sx={{
														"& fieldset": {
															border: "1px solid #E5E7EB",
														},
													}}
													color="black"
													size="small"
													required
													value={userDetails.firstName}
													onChange={handleUserDetailsChange}
													disabled={savingUserDeets}
													type="text"
													id="firstName"
													name="firstName"
													className="border-gray-200 rounded w-full"
												/>
											</div>
											<div className="w-1/2">
												<label
													htmlFor="lastName"
													className="block text-sm font-medium text-gray-600 mb-2"
												>
													Last Name
												</label>
												<TextField
													sx={{
														"& fieldset": {
															border: "1px solid #F2F3F5",
														},
													}}
													color="black"
													size="small"
													required
													value={userDetails.lastName}
													onChange={handleUserDetailsChange}
													disabled={savingUserDeets}
													type="text"
													id="lastName"
													name="lastName"
													className=" border-gray-200 rounded w-full"
												/>
											</div>
										</Box>
										<Box className="mb-4">
											<label
												htmlFor="preferredName"
												className="block text-sm font-medium text-gray-600 mb-2"
											>
												Preferred Name
											</label>
											<TextField
												sx={{
													"& fieldset": {
														border: "1px solid #F2F3F5",
													},
												}}
												color="black"
												size="small"
												required
												type="text"
												id="preferredName"
												name="preferredName"
												className=" border-gray-200 rounded w-full"
												value={userDetails.preferredName}
												onChange={handleUserDetailsChange}
												disabled={savingUserDeets}
											/>
										</Box>
										<Box className="mb-4">
											<label
												htmlFor="email"
												className="block text-sm font-medium text-gray-600 mb-2"
											>
												Email Address
											</label>
											<TextField
												sx={{
													"& fieldset": {
														border: "1px solid #F2F3F5",
													},
												}}
												color="black"
												size="small"
												required
												type="text"
												id="email"
												name="email"
												className=" border-gray-200 rounded w-full"
												value={userDetails.email}
												onChange={handleUserDetailsChange}
												disabled={savingUserDeets}
											/>
										</Box>
										<Box className="mb-4">
											<label
												htmlFor="experience"
												className="block text-sm font-medium text-gray-600 mb-2"
											>
												Experience in Years
											</label>
											<TextField
												sx={{
													"& fieldset": {
														border: "1px solid #F2F3F5",
													},
												}}
												color="black"
												size="small"
												required
												type="number"
												id="candidate-experience"
												name="experience"
												className=" border-gray-200 rounded w-full"
												value={userDetails.experience}
												onChange={handleUserDetailsChange}
												disabled={savingUserDeets}
											/>
										</Box>
										<Box className="mb-4">
											<CountrySelector
												value={userDetails.country}
												onChange={(newValue) => {
													handleUserDetailsChange({
														target: { name: "country", value: newValue },
													});
												}}
												disabled={savingUserDeets}
											/>
										</Box>
										<Box className="mb-4">
											<label
												htmlFor="phoneNumber"
												className="block text-sm font-medium text-gray-600 mb-2"
											>
												Phone Number
											</label>
											<TextField
												sx={{
													"& fieldset": {
														border: "1px solid #F2F3F5",
													},
												}}
												color="black"
												size="small"
												required
												type="text"
												id="phoneNumber"
												name="phoneNumber"
												className=" border-gray-200 rounded w-full"
												value={userDetails.phoneNumber}
												onChange={handleUserDetailsChange}
												disabled={savingUserDeets}
											/>
										</Box>

										{isResumeRequired && (
											<Box className="mb-4">
												<label
													htmlFor="resume"
													className="block text-sm font-medium text-gray-600 mb-2"
												>
													Upload Resume
												</label>
												<TextField
													sx={{
														"& .MuiInputBase-root": {
															padding: "6px",
															fontSize: "0.9rem",
														},
														"& .MuiInputBase-input": {
															padding: "4px",
														},
														"& fieldset": {
															border: "1px solid #F2F3F5",
														},
														"&:hover fieldset": {
															border: "1px solid #F2F3F5 !important",
														},
													}}
													color="black"
													size="small"
													required
													disabled={savingUserDeets}
													accept="application/pdf"
													type="file"
													onChange={handleResumeUpload}
													id="resume"
													name="resume"
													className="text-sm border-gray-200 rounded w-full "
												/>
											</Box>
										)}
										{orgName?.toLowerCase() === "zinterview.ai" && (
											<Box className="mb-4">
												<Typography
													variant="body1"
													className="text-sm font-semibold text-gray-600 mb-2"
												>
													Make Profile Searchable?
												</Typography>
												<Box className="flex items-center">
													<input
														type="checkbox"
														className="scale-125 flex-shrink-0 align-middle"
														name="isProfileSearchable"
														id="isProfileSearchable"
														checked={userDetails?.isProfileSearchable}
														onChange={handleUserDetailsChange}
													/>
													<label
														htmlFor="makeProfileSearchable"
														className="text-sm text-gray-700 ml-2"
													>
														Yes, I would like my profile to be searchable for
														other employers looking for candidates for similar
														positions.
													</label>
												</Box>
											</Box>
										)}
										<Box className="flex justify-end mt-4">
											<Box
												onClick={handleUserDetailsSubmit}
												id="continue1"
												className={`normal-case cursor-pointer bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 focus:outline-none focus:border-none text-xs ${
													savingUserDeets && "cursor-not-allowed opacity-50"
												}`}
												component={Button}
												// variant='contained'
												type="submit"
												// fullWidth
												// size='large'
												disabled={savingUserDeets}
											>
												{savingUserDeets ? (
													<span>
														{"Saving Details... "}{" "}
														<i className="fas fa-circle-notch fa-spin mr-1"></i>
													</span>
												) : (
													<span>
														{"Continue "}{" "}
														<i className="fas fa-arrow-right mr-1"></i>
													</span>
												)}
											</Box>
										</Box>
									</form>
								</Box>
							</>
						) : (
							<Box
								id="panel2"
								className="bg-white p-6 rounded-lg shadow-lg w-3/4 md:w-1/2 lg:w-1/3 mt-10 mx-auto"
							>
								{" "}
								<h2 className="text-center text-2xl font-bold mb-6">
									Just a heads-up! To participate in this interview, you'll need to switch
									to a desktop browser. This ensures you get the best possible experience
									and can fully engage with the interview process. Thanks for your
									cooperation!
								</h2>
							</Box>
						)
					) : (
						<Preface
							setVisitedPreface={setVisitedPreface}
							isMobileInterviewAllowed={openingData?.isMobileInterviewAllowed}
						/>
					)}
					<Snackbar
						open={snackbarOpen}
						autoHideDuration={4000}
						onClose={handleSnackBarClose}
						message={snackBarMessage}
						action={action}
					/>
				</Box>
			) : (
				<Box
					id="panel1"
					className="bg-white p-6 rounded-lg shadow-lg w-3/4 md:w-1/2 lg:w-1/3 mt-10 mx-auto"
				>
					<h2 className="text-center text-2xl font-bold mb-6">
						This opening is no longer accepting new applications.
					</h2>
				</Box>
			)}
		</ThemeProvider>
	);
};

export default UserDetails;
