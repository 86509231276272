import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import authService from "./authService";
import { apiSlice } from "../api/apiSlice";
import { resetOpeningDetails } from "../createOpening/createOpeningSlice";
import { resetOrganization } from "../organization/organizationSlice";

let user = null;

const initialState = {
	user: user,
	isLoading: false,
	isSuccess: false,
	isError: false,
	message: "",
};

export const registerUser = createAsyncThunk("auth/register", async (formData, thunkAPI) => {
	try {
		const res = await authService.registerUser(formData);
		return res;
	} catch (error) {
		console.log("error while registering the user");
		console.log(error);
		const message = error?.response?.data?.message || error.message;
		return thunkAPI.rejectWithValue(message);
	}
});

export const loginUser = createAsyncThunk("auth/login", async (formData, thunkAPI) => {
	try {
		const res = await authService.loginUser(formData);
		console.log("success login", res);
		return res;
	} catch (error) {
		console.log("error while logging in the user");
		console.log(error);
		const message = error?.response?.data?.message || error.message;
		return thunkAPI.rejectWithValue(message);
	}
});

export const twoFactorAuth = createAsyncThunk("auth/twoFactorAuth", async (res, thunkAPI) => {
	try {
		const response = await authService.handleTwoFactorAuth(res);
		return response;
	} catch (error) {
		console.log("error while twoFactorAuth");
		console.log(error);
		const message = error?.response?.data?.message || error.message;
		return thunkAPI.rejectWithValue(message);
	}
});

export const logoutUser = createAsyncThunk("auth/logout", async (_, thunkAPI) => {
	try {
		await authService.logoutUser();
		thunkAPI.dispatch(apiSlice.util.resetApiState());
		thunkAPI.dispatch(resetOpeningDetails());
		thunkAPI.dispatch(resetOrganization());
		return true;
	} catch (e) {
		console.log("Failed to logout");
		console.log(e);
	}
});

export const getAuthStatus = createAsyncThunk("auth/get-auth-status", async (_, thunkAPI) => {
	try {
		const res = await authService.getAuthStatus();
		return res;
	} catch (e) {
		console.log(e);
		const message = e?.response?.data?.message || e.message;
		return thunkAPI.rejectWithValue(message);
	}
});

const authSlice = createSlice({
	name: "auth",
	initialState,
	reducers: {
		reset: (state, action) => {
			state.isLoading = false;
			state.isSuccess = false;
			state.isError = false;
			state.message = "";
		},
		setuser: (state, action) => {
			state.user = action.payload;
		},
		addOpeningIDIntoStarredOpenings: (state, action) => {
			if (!action.payload || !state.user?.StarredOpenings) return;
			state.user.StarredOpenings?.push(action.payload);
		},
		removeOpeningIDFromStarredOpenings: (state, action) => {
			if (!action.payload || !state.user?.StarredOpenings) return;
			const index = state.user.StarredOpenings?.findIndex((id) => id === action.payload);
			if (index !== -1) {
				state.user.StarredOpenings.splice(index, 1);
			}
		},
		replaceFolderNames: (state, action) => {
			state.user.folderNames = action.payload;
		},
		addFolderName: (state, action) => {
			state.user.folderNames?.unshift(action.payload);
		},
		editFolderName: (state, action) => {
			const index = state.user.folderNames?.findIndex((ele) => ele._id === action.payload._id);
			if (index !== -1) {
				state.user.folderNames[index].name = action.payload.name;
			}
		},
		removeFolderName: (state, action) => {
			const index = state.user.folderNames?.findIndex((ele) => ele._id === action.payload);
			if (index !== -1) {
				state.user.folderNames.splice(index, 1);
			}
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(registerUser.pending, (state, { payload }) => {
				state.isLoading = true;
			})
			.addCase(registerUser.fulfilled, (state, { payload }) => {
				state.isLoading = false;
				state.isSuccess = true;
				state.isError = false;
				state.message = payload.message;
				state.user = payload.data;
			})
			.addCase(registerUser.rejected, (state, { payload }) => {
				state.isLoading = false;
				state.message = payload;
				state.isError = true;
				state.isSuccess = false;
				state.user = null;
			})

			.addCase(loginUser.pending, (state, { payload }) => {
				state.isLoading = true;
			})
			.addCase(loginUser.fulfilled, (state, { payload }) => {
				state.isLoading = false;
				state.isSuccess = true;
				state.isError = false;
				state.message = payload.message;
				state.user = payload.data;
			})
			.addCase(loginUser.rejected, (state, { payload }) => {
				state.isLoading = false;
				state.message = payload;
				state.isError = true;
				state.isSuccess = false;
				state.user = null;
			})
			.addCase(twoFactorAuth.pending, (state, { payload }) => {
				state.isLoading = true;
			})
			.addCase(twoFactorAuth.fulfilled, (state, { payload }) => {
				state.isLoading = false;
				state.isSuccess = true;
				state.isError = false;
				state.message = payload?.message;
				state.user = payload.data;
			})
			.addCase(twoFactorAuth.rejected, (state, { payload }) => {
				state.isLoading = false;
				state.message = payload;
				state.isError = true;
				state.isSuccess = false;
			})
			.addCase(getAuthStatus.pending, (state, { payload }) => {
				state.isLoading = true;
			})
			.addCase(getAuthStatus.fulfilled, (state, { payload }) => {
				state.isLoading = false;
				state.isSuccess = true;
				state.isError = false;
				state.message = payload?.message;
				state.user = payload.data;
			})
			.addCase(getAuthStatus.rejected, (state, { payload }) => {
				state.isLoading = false;
				state.message = payload;
				state.isError = true;
				state.isSuccess = false;
			})
			.addCase(logoutUser.fulfilled, (state, { payload }) => {
				state.isLoading = false;
				state.isSuccess = true;
				state.isError = false;
				state.user = null;
				state.message = "";
			})
			.addCase(logoutUser.rejected, (state, { payload }) => {
				state.isLoading = false;
				state.isSuccess = true;
				state.isError = false;
				state.user = null;
				state.message = "";
			});
	},
});

const authReducer = authSlice.reducer;
export default authReducer;
export const {
	reset,
	setuser,
	addOpeningIDIntoStarredOpenings,
	removeOpeningIDFromStarredOpenings,
	replaceFolderNames,
	addFolderName,
	editFolderName,
	removeFolderName,
} = authSlice.actions;
