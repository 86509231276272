import html2pdf from "html2pdf.js";
import { downloadEvaluationReportDocx } from "../../utilities/evaluation-report-api";
import { useState, useCallback } from "react";
import { toast } from "react-toastify";
import SimCardDownloadIcon from "@mui/icons-material/SimCardDownload";
import { Button, CircularProgress, Menu, MenuItem } from "@mui/material";
import { useParams } from "react-router-dom";
import axios from "axios";

export const DownloadEvaluationReportMenu = ({
	isDownloading,
	setIsDownloading,
	fileName,
	setExpandedStates,
}) => {
	const { interviewReportId } = useParams();
	const [anchorEl, setAnchorEl] = useState(null);
	const isOpen = Boolean(anchorEl);
	const [isPdfDownloading, setIsPdfDownloading] = useState(false);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const downloadPdfThroughCanvas = useCallback(() => {
		handleClose();
		let s = fileName + ".pdf";
		const options = {
			margin: 10,
			filename: s,
			image: { type: "jpeg", quality: 0.98 },
			html2canvas: { scale: 2 },
			jsPDF: { unit: "mm", format: "a4", orientation: "landscape" },
		};

		const Root = document.getElementById("root");
		html2pdf().set({
			pagebreak: { mode: ["avoid-all", "css", "legacy"] },
		});
		setIsDownloading(true);
		setExpandedStates((prev) => {
			let newPrev = {};
			Object.keys(prev).forEach((key) => {
				newPrev[key] = true;
			});
			return newPrev;
		});
		setTimeout(() => {
			html2pdf(Root, options);
			setIsDownloading(false);
		}, 500);
	}, [fileName, setIsDownloading, setExpandedStates]);

	const checkJobStatus = async (jobId) => {
		try {
			const response = await axios.get(`/api/evaluationReports/pdf-job-status/${jobId}`);
			return response.data;
		} catch (error) {
			console.error("Error checking job status:", error);
			throw error;
		}
	};

	const pollJobStatus = async (jobId) => {
		const pollInterval = 5000; // 5 seconds
		const maxAttempts = 36; // 3 minute maximum
		let attempts = 0;

		return new Promise((resolve, reject) => {
			const poll = async () => {
				try {
					const result = await checkJobStatus(jobId);

					switch (result.status) {
						case "success":
							resolve(result.url);
							break;

						case "error":
							reject(new Error(result.message || "PDF generation failed"));
							break;

						default:
							attempts++;
							if (attempts >= maxAttempts) {
								reject(new Error("PDF generation timed out"));
								return;
							}
							setTimeout(poll, pollInterval);
					}
				} catch (error) {
					reject(error);
				}
			};

			poll();
		});
	};

	const downloadPdfFile = async (url) => {
		try {
			const response = await axios.get(url, {
				responseType: "blob",
			});

			const blob = new Blob([response.data], { type: "application/pdf" });
			const downloadUrl = window.URL.createObjectURL(blob);
			const link = document.createElement("a");
			link.href = downloadUrl;
			link.setAttribute("download", `${fileName}.pdf`);
			document.body.appendChild(link);
			link.click();
			link.remove();
			window.URL.revokeObjectURL(downloadUrl);
		} catch (error) {
			console.error("Error downloading PDF file:", error);
			throw error;
		}
	};

	const handleDownloadPdf = async () => {
		const currentUrl = window.location.href;

		setIsPdfDownloading(true); // Downloading Start..

		try {
			// First try the queue-based approach
			try {
				// Initialize PDF generation job
				const response = await axios.post(`/api/evaluationReports/download-report-pdf`, {
					url: currentUrl,
				});

				const { jobId, status } = response.data;

				if (status === "pending") {
					// Poll for job completion
					const pdfUrl = await pollJobStatus(jobId);
					await downloadPdfFile(pdfUrl);
					toast.success("PDF downloaded successfully");
				}
			} catch (queueError) {
				console.error("Queue-based PDF generation failed:", queueError);
				// Fallback to canvas method
				console.log("Falling back to canvas method...");
				downloadPdfThroughCanvas();
			}
		} catch (error) {
			console.error("PDF download failed:", error);
			toast.error("Failed to download PDF. Please try again.");
		} finally {
			setIsPdfDownloading(false);
		}
	};

	const downloadDocx = async () => {
		try {
			handleClose();
			setIsDownloading(true);
			await downloadEvaluationReportDocx(interviewReportId, fileName + ".docx");
		} catch (error) {
			toast.warn(error.message);
		} finally {
			setIsDownloading(false);
		}
	};

	return (
		<>
			{!isDownloading && (
				<Button
					id="download-menu-btn"
					aria-controls={isOpen ? "basic-menu" : undefined}
					aria-haspopup="true"
					aria-expanded={isOpen ? "true" : undefined}
					onClick={handleClick}
				>
					<SimCardDownloadIcon style={{ color: "#581C87", fontSize: "20px" }} />
				</Button>
			)}

			<Menu
				id="download-menu"
				anchorEl={anchorEl}
				open={isOpen}
				onClose={handleClose}
				MenuListProps={{
					"aria-labelledby": "download-menu-btn",
				}}
			>
				<MenuItem onClick={handleDownloadPdf} disabled={isPdfDownloading}>
					{isPdfDownloading ? (
						<>
							<CircularProgress size={16} style={{ marginRight: "8px", color: "#581C87" }} />
							Generating PDF...
						</>
					) : (
						"Download as Pdf"
					)}
				</MenuItem>
				<MenuItem onClick={downloadDocx}>Download as Docx</MenuItem>
			</Menu>
		</>
	);
};
