import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import FolderIcon from "@mui/icons-material/Folder";
const SearchOpenings = ({
	folderNames,
	selectedFolder,
	setSelectedFolder,
	defaultFolder,
	setOpenAddEditFolderNameModal,
	searchOpeningRef,
	setSearchOpening,
	isFolderNamesLoading,
}) => {
	return (
		<Box className="flex flex-wrap items-center ml-auto space-x-2">
			<FormControl
				size="small"
				className="min-w-[210px] border-2 border-transparent hover:border-blue-500 focus-within:border-blue-500"
			>
				<InputLabel id="demo-simple-select-label" className="text-xs text-gray-400">
					Choose Folder
				</InputLabel>
				<Select
					labelId="demo-simple-select-label"
					id="demo-simple-select"
					label="Choose folder"
					className="bg-white text-xs rounded-md"
					disabled={isFolderNamesLoading}
					value={
						folderNames?.find((folder) => folder?._id === selectedFolder?._id) || defaultFolder
					}
					onChange={(e) => {
						if (e.target.value === null) {
							setOpenAddEditFolderNameModal(true);
							return;
						}
						setSelectedFolder(e.target.value);
					}}
				>
					<MenuItem value={defaultFolder}>
						<div className="flex items-center">
							<FolderIcon className="mr-2 text-indigo-500 text-xs" />
							<p className="text-xs">All (default)</p>
						</div>
					</MenuItem>
					{folderNames?.map((folder, index) => (
						<MenuItem key={index} value={folder}>
							<div className="flex items-center">
								<FolderIcon className="mr-2 text-indigo-500 text-xs" />
								<p className="text-xs">{folder?.name}</p>
							</div>
						</MenuItem>
					))}
					<MenuItem value={null}>
						<div className="flex items-center font-bold">
							<CreateNewFolderIcon className="mr-2 text-indigo-500 text-sm" />
							<p className="text-xs">Add New Folder</p>
						</div>
					</MenuItem>
				</Select>
			</FormControl>
			<Box className="relative w-[195px]">
				<input
					className="border rounded-md p-2 pl-10 w-full"
					type="search"
					name="opening"
					ref={searchOpeningRef}
					id="opening-name-search"
					placeholder={`Search Opening`}
					onChange={(e) => setSearchOpening(e.target.value)}
				/>
				<i className="fas fa-search absolute top-2 left-3 text-gray-400 text-sm"></i>
			</Box>
		</Box>
	);
};

export default SearchOpenings;
