import React, { useState } from "react";
import { Box, Typography, Button } from "@mui/material";
import { updateConsent } from "../../utilities/interviewReportsApi";
import { toast } from "react-toastify";
import { CONSENT_TYPES } from "../../utilities/constants";

const CheatingWarning = ({ _id, createdByAdmin, isFaceMatchRequired }) => {
	const [checked, setChecked] = useState(false);
	const [showSpinner, setShowSpinner] = useState(false);

	return (
		<Box className="bg-gray-200 min-h-screen pb-3">
			<Box className="flex justify-center space-x-4 mt-10">
				<Box id="step1Bar" className="bg-gray-300 rounded h-2 w-24"></Box>
				<Box id="step2Bar" className="bg-green-500 rounded h-2 w-24"></Box>
				<Box id="step3Bar" className="bg-gray-300 rounded h-2 w-24"></Box>

				{createdByAdmin && <Box id="step4Bar" className="bg-gray-300 rounded h-2 w-24"></Box>}
				{isFaceMatchRequired && <Box id="step5Bar" className="bg-gray-300 rounded h-2 w-24"></Box>}
			</Box>
			<Box className="bg-white p-6 rounded-lg shadow-lg w-3/4 md:w-1/2 lg:w-1/2 mt-10 mx-auto text-sm">
				<Typography variant="h2" className="text-center text-xl font-bold mb-6">
					Zero Tolerance for Cheating
				</Typography>
				<Typography variant="p" className="block mt-2">
					You are in a closely monitored environment. Any attempt to gain an unfair
					advantage—whether by using unauthorized tools, external help, or other methods—will be
					detected. Violations may lead to the immediate termination of your interview and could be
					reported to the hiring manager, resulting in permanent disqualification.
				</Typography>
				<Box className="my-4 flex gap-2 flex-row items-stretch">
					<input
						type="checkbox"
						className="scale-125 flex-shrink-0 align-middle"
						checked={checked}
						onChange={() => setChecked(!checked)}
					/>
					<label className="flex-grow-[1] select-none">
						I understand that any form of cheating will result in immediate disqualification.
					</label>
				</Box>
				<Box className="flex justify-center mt-6">
					<Button
						disabled={showSpinner || !checked}
						className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 focus:outline-none text-xs focus:border-none normal-case disabled:opacity-50"
						onClick={async () => {
							if (!checked) {
								toast.warn("Please check the box to proceed.");
								return;
							}
							setShowSpinner(true);
							try {
								const resp = await updateConsent(_id, CONSENT_TYPES.ALL);
								if (resp) {
									window.location.reload();
								} else {
									toast.error("Something went wrong. Please try again.");
								}
							} catch (error) {
								toast.error("Something went wrong. Please try again.");
								console.log(`Error in updating welcome page status: ${error}`);
							} finally {
								setTimeout(() => setShowSpinner(false), 2000);
							}
						}}
					>
						{showSpinner ? (
							<i className="fas fa-circle-notch fa-spin mr-1"></i>
						) : (
							<span>
								Continue <i className="fas fa-arrow-right mx-1"></i>
							</span>
						)}
					</Button>
				</Box>
			</Box>
		</Box>
	);
};

export default CheatingWarning;
