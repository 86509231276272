import React from "react";
import { Box, Typography, Button } from "@mui/material";

const Preface = ({ setVisitedPreface, isMobileInterviewAllowed = false }) => {
	return (
		<>
			<Box className="bg-gray-200 min-h-screen pb-3">
				<Box className="bg-white p-6 rounded-lg shadow-lg w-3/4 md:w-1/2 lg:w-1/2 mt-10 mx-auto text-sm">
					<Typography variant="h2" className="text-center text-xl font-bold mb-6">
						Welcome to Your AI-Powered Interview!
					</Typography>
					<Typography variant="h2" className="text-lg font-bold mb-4 mt-2">
						To ensure a smooth experience, we’ll need a few permissions before you begin:
					</Typography>
					<Typography variant="p">
						This interview requires you to provide the following permissions for a smooth
						experience:
					</Typography>
					<ul className="list-disc ml-6 mt-4">
						<li>
							<strong>Webcam Access</strong> – To capture your video responses.
						</li>
						<li>
							<strong>Screen Recording Access</strong> – To monitor relevant activities during
							your interview.
						</li>
						<li>
							<strong>Microphone Access</strong> - So we can hear your responses clearly.
						</li>
					</ul>
					<br />
					<Typography variant="p">
						{isMobileInterviewAllowed ? (
							<span>
								You can take this interview on both <strong>laptop and mobile</strong>, but
								for the best experience, we <strong>highly recommend using a laptop</strong>.
							</span>
						) : (
							<span>
								You can take the interview on your <strong>laptop only</strong>.
							</span>
						)}
					</Typography>
					<br />
					<br />
					<Typography variant="p">
						<strong>Just a heads-up!</strong> This session will be recorded, including your screen{" "}
						<strong>activity, audio, and video</strong>. But don’t worry – your data is handled
						with care and in line with our privacy policy.
					</Typography>
					<br />
					<br />
					<Typography variant="p">
						Take a deep breath, relax, and give it your best shot. <strong>Good luck</strong>!
					</Typography>
					<Box className="flex justify-center mt-6">
						<Button
							id="continue1"
							className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 focus:outline-none text-xs focus:border-none normal-case disabled:opacity-50"
							onClick={() => {
								setVisitedPreface(true);
							}}
						>
							Proceed <i className="fas fa-arrow-right mx-1"></i>
						</Button>
					</Box>
				</Box>
			</Box>
		</>
	);
};

export default Preface;
