import React, { useState } from "react";
import Button from "../components/QuestionBank/Button";
import QuestionBankTable from "../components/QuestionBank/QuestionBankTable";
import { toast } from "react-toastify";
import { CircularProgress, MenuItem } from "@mui/material";
import QbModal from "../components/QuestionBank/QbModal";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { ColStack, CustomSelectwithController, Label } from "../components/QuestionBank/Common";
import { ErrorMessage } from "./CreateUpdateOpening/CreateUpdateOpeningWithJD";
import MultiLineTextArea from "../components/MultiLineTextArea";
import {
	useAddNewCategoryMutation,
	useAddNewQuestionMutation,
	useGetCategoriesQuery,
	useGetQuestionBankQuery,
	useUpdateQuestionMutation,
	useDeleteCategoryMutation,
} from "../features/api/apiSlice";
import { Link, useSearchParams } from "react-router-dom";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { difficultyLevels } from "../utilities/constants";

export const ADDCATEGORY = "ADDCATEGORY";
export const ADDQUESTION = "ADDQUESTION";
export const UPDATEQUESTION = "UPDATEQUESTION";

const QuestionBank = () => {
	const [searchParams] = useSearchParams();
	const paramsModalType = searchParams.get("modalType");
	const defaultModalType =
		paramsModalType === ADDCATEGORY || paramsModalType === ADDQUESTION ? paramsModalType : "";
	const [modalType, setModalType] = useState(defaultModalType);
	const [categoryFilterType, setCategoryFilterType] = useState("All");
	const [toUpdateDoc, setToUpdateDoc] = useState({});

	const {
		data: questionBankRaw,
		isFetching: isQBFetching,
		isLoading: IsQBLoading,
		isSuccess: IsQBSuccess,
	} = useGetQuestionBankQuery();
	const {
		data: categoriesRaw,
		isFetching: isCategoriesFetching,
		isLoading: isCategoriesLoading,
		isSuccess: isCategoriesSuccess,
	} = useGetCategoriesQuery();

	if (isCategoriesLoading || IsQBLoading) {
		return (
			<div
				style={{ minHeight: `calc(100vh - var(--navbar-height))` }}
				className="flex justify-center items-center"
			>
				<CircularProgress />
			</div>
		);
	}

	const openModal = (modalType = "") => setModalType(modalType);
	const closeModal = () => {
		setModalType("");
		setToUpdateDoc({});
	};

	const isModalOpen = !!modalType;
	if (isCategoriesSuccess && IsQBSuccess) {
		const { categories } = categoriesRaw;
		const { questionBank } = questionBankRaw;

		return (
			<div style={{ minHeight: `calc(100vh - var(--navbar-height,78px))` }} className="bg-gray-100 ">
				<div className="bg-gray-50 p-4 text-xs text-inherit">
					<p className="text-gray-700">
						The Question Bank is where you can create and organize a collection of interview
						questions under specific topics. While creating an opening, hiring managers can choose
						to use questions from the Question Bank if required. By default, Zinterview
						dynamically generates questions based on the job description provided.
					</p>
				</div>
				<div className="py-6 ">
					<div className="w-5/6 mx-auto bg-white rounded-lg  p-6 shadow gap-4 flex flex-col">
						<h2 className="text-md font-semibold">Question Bank</h2>

						<div className="flex justify-between items-center flex-wrap gap-3">
							<div>
								<Button colorVariant={"emerald"} onClick={() => openModal(ADDCATEGORY)}>
									<i className="fas fa-folder-plus mr-2"></i>Add Category
								</Button>
							</div>
							<div>
								<Button colorVariant={"blue"} onClick={() => openModal(ADDQUESTION)}>
									<i className="fas fa-plus-circle mr-2"></i>Add New Questions
								</Button>
							</div>
						</div>

						{/* Filter by category buttons */}
						<div className="flex flex-row gap-4 flex-wrap">
							<Button
								colorVariant={"gray"}
								selected={categoryFilterType === "All"}
								onClick={(e) => {
									setCategoryFilterType("All");
								}}
								disabled={isCategoriesFetching}
							>
								{"All"}
							</Button>
							{categories.map((category) => {
								const { _id: id, categoryName } = category;
								return (
									<CategoryButton
										key={id}
										id={id}
										selected={categoryFilterType === id}
										setCategoryFilterType={setCategoryFilterType}
										isCategoriesFetching={isCategoriesFetching}
										categoryName={categoryName}
									/>
								);
							})}
						</div>

						{/* Questions Table */}
						<QuestionBankTable
							isFetching={isQBFetching}
							questionBank={questionBank}
							filter={categoryFilterType}
							setModalType={setModalType}
							setToUpdateDoc={setToUpdateDoc}
						/>

						{/* Modals to add new question and new category */}
						<QbModal open={isModalOpen} handleClose={closeModal}>
							{modalType === ADDCATEGORY ? (
								<AddNewCategory handleModalClose={closeModal} />
							) : null}
							{modalType === ADDQUESTION ? (
								<AddNewQuestion handleModalClose={closeModal} categories={categories} />
							) : null}
							{modalType === UPDATEQUESTION ? (
								<AddNewQuestion
									handleModalClose={closeModal}
									categories={categories}
									values={toUpdateDoc}
									isUpdate={true}
								/>
							) : null}
						</QbModal>
					</div>
				</div>
			</div>
		);
	}
};

export default QuestionBank;

const newCategoryDefaults = {
	categoryName: "",
};

const newCategorySchema = z.object({
	categoryName: z.string().trim().min(1, "Category name cannot be empty").max(50),
});
const AddNewCategory = ({ handleModalClose }) => {
	const [addNewCategory, { isLoading: isAddingNewCategory }] = useAddNewCategoryMutation();

	const methods = useForm({
		defaultValues: newCategoryDefaults,
		resolver: zodResolver(newCategorySchema),
	});
	const {
		register,
		formState: { errors },
		handleSubmit,
	} = methods;

	const handleFormSubmit = async (data) => {
		try {
			await addNewCategory(data).unwrap();
			handleModalClose();
			toast.success(`${data.categoryName} Category has been added!`);
		} catch (error) {
			const status = error?.response?.status;
			const message = error?.response?.data?.message || error.message;
			if (status === 400) {
				toast.warn(message);
			}
		}
	};

	return (
		<>
			<div className="create-update-opening">
				<h3 className="text-md leading-6 font-medium text-gray-900 mb-2">Add New Category</h3>
				<form noValidate onSubmit={handleSubmit(handleFormSubmit)} className="flex flex-col gap-4">
					<ColStack>
						<Label htmlFor={"new-category"}>Category Name</Label>
						<input
							autoFocus
							type="text"
							id="new-category"
							{...register("categoryName")}
							className="mt-1 w-full rounded-md border p-2 disabled:opacity-50"
							placeholder="Enter category name"
							disabled={isAddingNewCategory}
						/>
						<ErrorMessage errorCriteria={errors?.categoryName?.message} />
					</ColStack>
					<div className="flex flex-row justify-end gap-4">
						<Button type="submit" colorVariant={"emerald"} disabled={isAddingNewCategory}>
							Add Category
						</Button>
						<Button
							colorVariant={"gray"}
							onClick={handleModalClose}
							disabled={isAddingNewCategory}
						>
							Cancel
						</Button>
					</div>
				</form>
			</div>
		</>
	);
};

const newQuestionDefaults = {
	difficulty: "Normal",
	category: "",
	question: "",
	isBulkEntry: false,
	delimiter: "\n",
};

const newQuestionSchema = z.object({
	difficulty: z.enum(["Easy", "Normal", "Hard"]),
	question: z
		.string()
		.trim()
		.min(5, "Question should be atleast 5 characters long")
		.max(50000, "Questions cannot exceed 50000 characters"),
	category: z.string().min(1),
	qid: z.string().optional(),
	isBulkEntry: z.boolean(),
	delimiter: z.string().optional(),
});

const AddNewQuestion = ({ handleModalClose, categories = [], values = {}, isUpdate = false }) => {
	const methods = useForm({
		defaultValues: newQuestionDefaults,
		values: {
			...newQuestionDefaults,
			...(isUpdate ? values : {}),
		},
		resolver: zodResolver(newQuestionSchema),
	});

	const {
		formState: { errors },
		handleSubmit,
		control,
		register,
		watch,
	} = methods;

	const isBulkEntry = watch("isBulkEntry");

	const [addNewQuestion, { isLoading: isAddingQuestion }] = useAddNewQuestionMutation();
	const [updateQuestion, { isLoading: isUpdatingQuestion }] = useUpdateQuestionMutation();
	const handleFormSubmit = async (data) => {
		try {
			if (isUpdate) {
				await updateQuestion(data).unwrap();
			} else {
				await addNewQuestion(data).unwrap();
			}
			handleModalClose();
		} catch (error) {
			const status = error?.response?.status;
			const message = error?.response?.data?.message || error.message;
			if (status === 400) {
				toast.warn(message);
			}
		}
	};

	return (
		<>
			<div className="create-update-opening">
				<h3 className="text-md leading-6 font-medium text-gray-900 mb-2">Add New Questions</h3>
				<form noValidate onSubmit={handleSubmit(handleFormSubmit)} className="flex flex-col gap-4">
					<ColStack>
						<CustomSelectwithController
							getItemsJSX={() =>
								categories.map((category) => {
									const { categoryName, _id: id } = category;
									return (
										<MenuItem
											key={id}
											value={id}
											className="border-none outline-none text-sm"
										>
											{categoryName}
										</MenuItem>
									);
								})
							}
							items={categories}
							control={control}
							id={"category-name"}
							name={"category"}
							label={"Category"}
						/>
						<ErrorMessage errorCriteria={errors?.category?.message} />
					</ColStack>
					<ColStack>
						<CustomSelectwithController
							getItemsJSX={() => {
								return Object.entries(difficultyLevels).map(([key, value]) => {
									return (
										<MenuItem
											key={value}
											value={key}
											className="border-none outline-none text-sm"
										>
											{value}
										</MenuItem>
									);
								});
							}}
							control={control}
							id={"difficulty"}
							name={"difficulty"}
							label={"Difficulty"}
						/>
						<ErrorMessage errorCriteria={errors?.difficulty?.message} />
					</ColStack>
					<ColStack>
						<Label htmlFor={"new-question"}>Questions</Label>
						<MultiLineTextArea
							autoFocus={true}
							control={control}
							registeredName={"question"}
							id={"new-question"}
							placeholder={"Enter questions separated by the chosen delimiter"}
							minRows={2}
							disabled={isAddingQuestion || isUpdatingQuestion}
						/>
						<ErrorMessage errorCriteria={errors?.question?.message} />
						<Link
							to={"/admin/create-markdown-tables"}
							target="_blank"
							className="text-blue-500 my-auto flex flex-row items-center gap-1 text-sm no-underline hover:text-blue-700"
						>
							Generate Markdown
							<OpenInNewIcon fontSize="10px" />
						</Link>
					</ColStack>

					{!isUpdate && (
						<div className="flex flex-row gap-1 select-none">
							<input type="checkbox" id="is-qb-bulk-entry" {...register("isBulkEntry")} />
							<Label htmlFor={"is-qb-bulk-entry"}>Bulk Entry</Label>
						</div>
					)}
					{!isUpdate && isBulkEntry ? (
						<ColStack>
							<Label htmlFor={"delimiter-qb"}>Delimiter</Label>
							<input
								type="text"
								id="delimiter-qb"
								{...register("delimiter")}
								className="mt-1 w-full rounded-md border p-2"
								placeholder="Enter delimiter (default is newline)"
							/>
						</ColStack>
					) : null}
					<div className="flex flex-row justify-end gap-4">
						<Button
							type="submit"
							colorVariant={"blue"}
							disabled={isAddingQuestion || isUpdatingQuestion}
						>
							Save
						</Button>
						<Button
							colorVariant={"gray"}
							onClick={handleModalClose}
							disabled={isAddingQuestion || isUpdatingQuestion}
						>
							Cancel
						</Button>
					</div>
				</form>
			</div>
		</>
	);
};

const CategoryButton = ({ id, selected, setCategoryFilterType, isCategoriesFetching, categoryName }) => {
	const [deleteCategory, { isLoading: isDeletingCategory }] = useDeleteCategoryMutation();

	const handleDeleteCategoryIconClick = async (e) => {
		try {
			const res = await deleteCategory(id).unwrap();
			setCategoryFilterType("All");
			toast.success(res.message);
		} catch (error) {
			toast.warn(error.data?.message || error.message);
		}
	};
	return (
		<Button
			colorVariant={"gray"}
			selected={selected}
			onClick={(e) => {
				setCategoryFilterType(id);
			}}
			disabled={isCategoriesFetching}
			deleteIcon={true}
			deleteIconDisabled={isDeletingCategory}
			onDeleteIconClick={handleDeleteCategoryIconClick}
		>
			{categoryName}
		</Button>
	);
};
