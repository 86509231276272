/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.16 public/models/658d0d87800d4dee05a8fa5a.glb -o src/components/Avatar.jsx -r public 
*/

import React, { useEffect, useRef, useState } from "react";
import { useAnimations, useFBX, useGLTF } from "@react-three/drei";
import { useFrame, useThree } from "@react-three/fiber";
import * as THREE from "three";
import RhubarbLipSync from "./RhubarbLipSync.json";

const corresponding = {
	A: "viseme_PP",
	B: "viseme_kk",
	C: "viseme_I",
	D: "viseme_AA",
	E: "viseme_O",
	F: "viseme_U",
	G: "viseme_FF",
	H: "viseme_TH",
	X: "viseme_PP",
};

// const smoothMorphTarget = true;
const morphTargetSmoothing = 0.5;

export function Avatar(props) {
	const [isEyesClosed, setIsEyesClosed] = useState(false);
	const blinkIntervalRef = useRef(null);
	// const [lipSyncData, setLipSyncData] = useState(RhubarbLipSync);
	// const [audioDuration, setAudioDuration] = useState(0);
	const { metadata, mouthCues } = RhubarbLipSync;
	const [hasRenderedOnce, setHasRenderedOnce] = useState(false);

	// useEffect(() => {
	// 	// Function to handle the loadedmetadata event
	// 	const handleLoadedMetadata = () => {
	// 		setAudioDuration(props.audioState.duration);
	// 	};

	// 	// Add event listener for loadedmetadata
	// 	props.audioState && props.audioState.addEventListener("loadedmetadata", handleLoadedMetadata);

	// 	// Remove event listener on cleanup
	// 	return () => {
	// 		props.audioState && props.audioState.removeEventListener("loadedmetadata", handleLoadedMetadata);
	// 	};
	// }, [props.audioState]);

	// useEffect(() => {
	// 	if (audioDuration > 0) {
	// 		// Calculate how many times to repeat the pattern
	// 		const repeatCount = Math.floor(audioDuration / lipSyncData.metadata.duration);
	// 		// Repeat the mouthCues pattern
	// 		let adjustedMouthCues = [];
	// 		for (let i = 0; i < repeatCount; i++) {
	// 			adjustedMouthCues = adjustedMouthCues.concat(
	// 				lipSyncData.mouthCues.map((cue) => ({
	// 					start: cue.start + i * lipSyncData.metadata.duration,
	// 					end: cue.end + i * lipSyncData.metadata.duration,
	// 					value: cue.value,
	// 				})),
	// 			);
	// 		}

	// 		// Update the state with the adjusted mouth cues
	// 		setLipSyncData({ ...lipSyncData, mouthCues: adjustedMouthCues });
	// 	}
	// }, [audioDuration]);

	useEffect(() => {
		blinkIntervalRef.current = setInterval(() => {
			setIsEyesClosed(true);

			setTimeout(() => {
				setIsEyesClosed(false);
			}, 200);
		}, 5000);

		return () => clearInterval(blinkIntervalRef.current);
	}, []);

	useEffect(() => {
		const eyesClosedValue = isEyesClosed ? 1 : 0;
		// nodes.Wolf3D_Head.morphTargetInfluences[
		// 	nodes.Wolf3D_Head.morphTargetDictionary['eyesClosed']
		// ] = eyesClosedValue;

		nodes.Wolf3D_Head.morphTargetInfluences[nodes.Wolf3D_Head.morphTargetDictionary["eyesClosed"]] =
			THREE.MathUtils.lerp(
				nodes.Wolf3D_Head.morphTargetInfluences[
					nodes.Wolf3D_Head.morphTargetDictionary["eyesClosed"]
				],
				eyesClosedValue,
				0.8,
			);
	}, [isEyesClosed]);

	// useFrame(() => {
	// 	if (!props.audioState) {
	// 		// setAnimation('Breathing');
	// 		Object.values(corresponding).forEach((value) => {
	// 			if (!smoothMorphTarget) {
	// 				nodes.Wolf3D_Head.morphTargetInfluences[nodes.Wolf3D_Head.morphTargetDictionary[value]] =
	// 					0;
	// 				nodes.Wolf3D_Teeth.morphTargetInfluences[
	// 					nodes.Wolf3D_Teeth.morphTargetDictionary[value]
	// 				] = 0;
	// 			} else {
	// 				nodes.Wolf3D_Head.morphTargetInfluences[nodes.Wolf3D_Head.morphTargetDictionary[value]] =
	// 					THREE.MathUtils.lerp(
	// 						nodes.Wolf3D_Head.morphTargetInfluences[
	// 							nodes.Wolf3D_Head.morphTargetDictionary[value]
	// 						],
	// 						0,
	// 						morphTargetSmoothing,
	// 					);

	// 				nodes.Wolf3D_Teeth.morphTargetInfluences[
	// 					nodes.Wolf3D_Teeth.morphTargetDictionary[value]
	// 				] = THREE.MathUtils.lerp(
	// 					nodes.Wolf3D_Teeth.morphTargetInfluences[
	// 						nodes.Wolf3D_Teeth.morphTargetDictionary[value]
	// 					],
	// 					0,
	// 					morphTargetSmoothing,
	// 				);
	// 			}
	// 		});
	// 		return;
	// 	} else {
	// 		const currentAudioTime = props.audioState.currentTime;
	// 		Object.values(corresponding).forEach((value) => {
	// 			if (!smoothMorphTarget) {
	// 				nodes.Wolf3D_Head.morphTargetInfluences[nodes.Wolf3D_Head.morphTargetDictionary[value]] =
	// 					0;
	// 				nodes.Wolf3D_Teeth.morphTargetInfluences[
	// 					nodes.Wolf3D_Teeth.morphTargetDictionary[value]
	// 				] = 0;
	// 			} else {
	// 				nodes.Wolf3D_Head.morphTargetInfluences[nodes.Wolf3D_Head.morphTargetDictionary[value]] =
	// 					THREE.MathUtils.lerp(
	// 						nodes.Wolf3D_Head.morphTargetInfluences[
	// 							nodes.Wolf3D_Head.morphTargetDictionary[value]
	// 						],
	// 						0,
	// 						morphTargetSmoothing,
	// 					);

	// 				nodes.Wolf3D_Teeth.morphTargetInfluences[
	// 					nodes.Wolf3D_Teeth.morphTargetDictionary[value]
	// 				] = THREE.MathUtils.lerp(
	// 					nodes.Wolf3D_Teeth.morphTargetInfluences[
	// 						nodes.Wolf3D_Teeth.morphTargetDictionary[value]
	// 					],
	// 					0,
	// 					morphTargetSmoothing,
	// 				);
	// 			}
	// 		});
	// 		for (let i = 0; i < lipSyncData.mouthCues.length; i++) {
	// 			const mouthCue = lipSyncData.mouthCues[i];
	// 			if (currentAudioTime >= mouthCue.start && currentAudioTime <= mouthCue.end) {
	// 				if (!smoothMorphTarget) {
	// 					nodes.Wolf3D_Head.morphTargetInfluences[
	// 						nodes.Wolf3D_Head.morphTargetDictionary[corresponding[mouthCue.value]]
	// 					] = 1;
	// 					nodes.Wolf3D_Teeth.morphTargetInfluences[
	// 						nodes.Wolf3D_Teeth.morphTargetDictionary[corresponding[mouthCue.value]]
	// 					] = 1;
	// 				} else {
	// 					nodes.Wolf3D_Head.morphTargetInfluences[
	// 						nodes.Wolf3D_Head.morphTargetDictionary[corresponding[mouthCue.value]]
	// 					] = THREE.MathUtils.lerp(
	// 						nodes.Wolf3D_Head.morphTargetInfluences[
	// 							nodes.Wolf3D_Head.morphTargetDictionary[corresponding[mouthCue.value]]
	// 						],
	// 						1,
	// 						morphTargetSmoothing,
	// 					);
	// 					nodes.Wolf3D_Teeth.morphTargetInfluences[
	// 						nodes.Wolf3D_Teeth.morphTargetDictionary[corresponding[mouthCue.value]]
	// 					] = THREE.MathUtils.lerp(
	// 						nodes.Wolf3D_Teeth.morphTargetInfluences[
	// 							nodes.Wolf3D_Teeth.morphTargetDictionary[corresponding[mouthCue.value]]
	// 						],
	// 						1,
	// 						morphTargetSmoothing,
	// 					);
	// 				}
	// 				break;
	// 			}
	// 		}

	// 		const isSpeaking = lipSyncData.mouthCues.some(
	// 			(mouthCue) => currentAudioTime >= mouthCue.start && currentAudioTime <= mouthCue.end,
	// 		);

	// 		if (!isSpeaking) {
	// 			Object.values(corresponding).forEach((value) => {
	// 				nodes.Wolf3D_Head.morphTargetInfluences[nodes.Wolf3D_Head.morphTargetDictionary[value]] =
	// 					0;
	// 				nodes.Wolf3D_Teeth.morphTargetInfluences[
	// 					nodes.Wolf3D_Teeth.morphTargetDictionary[value]
	// 				] = 0;
	// 			});
	// 		}
	// 	}
	// 	// const currentAudioTime = props.audioState.currentTime;

	// 	// for (let i = 0; i < props.lipSync.mouthCues.length; i++) {
	// 	// 	const mouthCue = props.lipSync.mouthCues[i];
	// 	// 	if (
	// 	// 		currentAudioTime >= mouthCue.start &&
	// 	// 		currentAudioTime <= mouthCue.end
	// 	// 	) {
	// 	// 		if (!smoothMorphTarget) {
	// 	// 			nodes.Wolf3D_Head.morphTargetInfluences[
	// 	// 				nodes.Wolf3D_Head.morphTargetDictionary[
	// 	// 					corresponding[mouthCue.value]
	// 	// 				]
	// 	// 			] = 1;
	// 	// 			nodes.Wolf3D_Teeth.morphTargetInfluences[
	// 	// 				nodes.Wolf3D_Teeth.morphTargetDictionary[
	// 	// 					corresponding[mouthCue.value]
	// 	// 				]
	// 	// 			] = 1;
	// 	// 		} else {
	// 	// 			nodes.Wolf3D_Head.morphTargetInfluences[
	// 	// 				nodes.Wolf3D_Head.morphTargetDictionary[
	// 	// 					corresponding[mouthCue.value]
	// 	// 				]
	// 	// 			] = THREE.MathUtils.lerp(
	// 	// 				nodes.Wolf3D_Head.morphTargetInfluences[
	// 	// 					nodes.Wolf3D_Head.morphTargetDictionary[
	// 	// 						corresponding[mouthCue.value]
	// 	// 					]
	// 	// 				],
	// 	// 				1,
	// 	// 				morphTargetSmoothing
	// 	// 			);
	// 	// 			nodes.Wolf3D_Teeth.morphTargetInfluences[
	// 	// 				nodes.Wolf3D_Teeth.morphTargetDictionary[
	// 	// 					corresponding[mouthCue.value]
	// 	// 				]
	// 	// 			] = THREE.MathUtils.lerp(
	// 	// 				nodes.Wolf3D_Teeth.morphTargetInfluences[
	// 	// 					nodes.Wolf3D_Teeth.morphTargetDictionary[
	// 	// 						corresponding[mouthCue.value]
	// 	// 					]
	// 	// 				],
	// 	// 				1,
	// 	// 				morphTargetSmoothing
	// 	// 			);
	// 	// 		}

	// 	// 		break;
	// 	// 	}
	// 	// }

	// 	// Check if the avatar has finished speaking
	// 	// const isSpeaking = props.lipSync.mouthCues.some(
	// 	// 	(mouthCue) =>
	// 	// 		currentAudioTime >= mouthCue.start &&
	// 	// 		currentAudioTime <= mouthCue.end
	// 	// );

	// 	// If the avatar is not speaking, reset the lips and teeth to their default state
	// 	// if (!isSpeaking) {
	// 	// 	Object.values(corresponding).forEach((value) => {
	// 	// 		nodes.Wolf3D_Head.morphTargetInfluences[
	// 	// 			nodes.Wolf3D_Head.morphTargetDictionary[value]
	// 	// 		] = 0;
	// 	// 		nodes.Wolf3D_Teeth.morphTargetInfluences[
	// 	// 			nodes.Wolf3D_Teeth.morphTargetDictionary[value]
	// 	// 		] = 0;
	// 	// 	});
	// 	// }
	// });

	useFrame(() => {
		if (!props.audioState) {
			// console.log("No audio or audio is not playing");
			// Reset morph targets when no audio is playing
			Object.values(corresponding).forEach((value) => {
				nodes.Wolf3D_Head.morphTargetInfluences[nodes.Wolf3D_Head.morphTargetDictionary[value]] =
					THREE.MathUtils.lerp(
						nodes.Wolf3D_Head.morphTargetInfluences[
							nodes.Wolf3D_Head.morphTargetDictionary[value]
						],
						0,
						morphTargetSmoothing,
					);

				nodes.Wolf3D_Teeth.morphTargetInfluences[nodes.Wolf3D_Teeth.morphTargetDictionary[value]] =
					THREE.MathUtils.lerp(
						nodes.Wolf3D_Teeth.morphTargetInfluences[
							nodes.Wolf3D_Teeth.morphTargetDictionary[value]
						],
						0,
						morphTargetSmoothing,
					);
			});
			return;
		}
		// console.log("Audio is playing");
		const currentTime = props.audioState?.currentTime;
		const duration = metadata.duration;
		const timeInCycle = currentTime % duration; // Calculate the current time within the repeating cue cycle

		// Determine the active mouth cue based on the current time within the cycle
		const activeCue = mouthCues.find((cue) => timeInCycle >= cue.start && timeInCycle <= cue.end);

		if (activeCue) {
			// console.log("Active cue: ", activeCue);
			// Apply the morph target based on the active cue
			Object.values(corresponding).forEach((value) => {
				nodes.Wolf3D_Head.morphTargetInfluences[nodes.Wolf3D_Head.morphTargetDictionary[value]] =
					THREE.MathUtils.lerp(
						nodes.Wolf3D_Head.morphTargetInfluences[
							nodes.Wolf3D_Head.morphTargetDictionary[value]
						],
						0,
						morphTargetSmoothing,
					);

				nodes.Wolf3D_Teeth.morphTargetInfluences[nodes.Wolf3D_Teeth.morphTargetDictionary[value]] =
					THREE.MathUtils.lerp(
						nodes.Wolf3D_Teeth.morphTargetInfluences[
							nodes.Wolf3D_Teeth.morphTargetDictionary[value]
						],
						0,
						morphTargetSmoothing,
					);
			});

			const morphTargetValue = corresponding[activeCue.value];
			nodes.Wolf3D_Head.morphTargetInfluences[
				nodes.Wolf3D_Head.morphTargetDictionary[morphTargetValue]
			] = THREE.MathUtils.lerp(
				nodes.Wolf3D_Head.morphTargetInfluences[
					nodes.Wolf3D_Head.morphTargetDictionary[morphTargetValue]
				],
				1,
				morphTargetSmoothing,
			);
			nodes.Wolf3D_Teeth.morphTargetInfluences[
				nodes.Wolf3D_Teeth.morphTargetDictionary[morphTargetValue]
			] = THREE.MathUtils.lerp(
				nodes.Wolf3D_Teeth.morphTargetInfluences[
					nodes.Wolf3D_Teeth.morphTargetDictionary[morphTargetValue]
				],
				1,
				morphTargetSmoothing,
			);
		} else {
			// console.log("No active cue");
			// Reset all morph targets if no cue is active
			Object.values(corresponding).forEach((value) => {
				nodes.Wolf3D_Head.morphTargetInfluences[nodes.Wolf3D_Head.morphTargetDictionary[value]] =
					THREE.MathUtils.lerp(
						nodes.Wolf3D_Head.morphTargetInfluences[
							nodes.Wolf3D_Head.morphTargetDictionary[value]
						],
						0,
						morphTargetSmoothing,
					);

				nodes.Wolf3D_Teeth.morphTargetInfluences[nodes.Wolf3D_Teeth.morphTargetDictionary[value]] =
					THREE.MathUtils.lerp(
						nodes.Wolf3D_Teeth.morphTargetInfluences[
							nodes.Wolf3D_Teeth.morphTargetDictionary[value]
						],
						0,
						morphTargetSmoothing,
					);
			});
		}
	});

	const { camera } = useThree();
	const groupRef = useRef();
	// useFrame(() => {
	// 	if (groupRef.current) {
	// 		const headBone = groupRef.current.getObjectByName('Head');
	// 		if (headBone) {
	// 			headBone.lookAt(camera.position);
	// 		}
	// 	}
	// });

	// useFrame(() => {
	// 	if (groupRef.current) {
	// 		const headBone = groupRef.current.getObjectByName('Head');
	// 		if (headBone) {
	// 			// The position to look at (we'll adjust this)
	// 			const targetPosition = new THREE.Vector3();

	// 			// Get the camera's forward direction
	// 			const cameraDirection = new THREE.Vector3();
	// 			camera.getWorldDirection(cameraDirection);

	// 			// Position the target in front of the camera by a certain depth.
	// 			// Adjust the 0.5 value to bring the target closer or further away
	// 			const depth = -0.5; // Negative because Three.js uses a right-handed coordinate system

	// 			// Adjust the height offset to match the user's eye level (you may need to tweak this)
	// 			const heightOffset = -0.2;

	// 			// Calculate the target position
	// 			targetPosition.x =
	// 				camera.position.x + cameraDirection.x * depth;
	// 			targetPosition.y = camera.position.y + heightOffset;
	// 			targetPosition.z =
	// 				camera.position.z + cameraDirection.z * depth;

	// 			// Now make the head bone look at this target position
	// 			headBone.lookAt(targetPosition);
	// 		}
	// 	}
	// });

	// const hasRenderedRef = useRef(false);

	useEffect(() => {
		// console.log("useEffect");
		if (hasRenderedOnce && props.avatarRendered && !props.loading && props.audioState) {
			// console.log("Avatar has rendered. AVATAR.JSX");
			// props.clientLogger("Avatar has rendered.");
			// console.log(`props.loading: ${props.loading}`);
			// console.log(`props.audioState: ${props.audioState}`);
			// console.log("Executing actions based on props changes after initial render");
			props.audioState?.play?.();
			props.clientLogger && props.clientLogger("Playing question for the candidate to listen to.");
		}
	}, [hasRenderedOnce, props.avatarRendered, props.loading, props.audioState]);

	useFrame(() => {
		if (groupRef.current) {
			if (!hasRenderedOnce) {
				setHasRenderedOnce(true);
				// console.log("Avatar has been rendered for the first time");
				// Optionally set props.avatarRendered to true here if needed
				props.setAvatarRendered && props.setAvatarRendered(true);
			}
			const headBone = groupRef.current.getObjectByName("Head");
			if (headBone) {
				// The position to look at (we'll adjust this)
				const targetPosition = new THREE.Vector3();

				// Get the camera's forward direction
				const cameraDirection = new THREE.Vector3();
				camera.getWorldDirection(cameraDirection);

				// Position the target in front of the camera by a certain depth.
				const depth = -0.5; // Negative because Three.js uses a right-handed coordinate system

				// Adjust the height offset to match the user's eye level
				const heightOffset = -0.2;

				// Adjust the horizontal offset to correct the leftward gaze
				const horizontalOffset = 0.03; // Positive to move gaze to the right, adjust as needed

				// Calculate the target position
				targetPosition.x = camera.position.x + cameraDirection.x * depth + horizontalOffset;
				targetPosition.y = camera.position.y + heightOffset;
				targetPosition.z = camera.position.z + cameraDirection.z * depth;

				// Now make the head bone look at this target position
				headBone.lookAt(targetPosition);
			}
		}
	});

	// useEffect(() => {
	// 	if (props.audioState) {
	// 		setAnimation('Talking');
	// 	} else {
	// 		setAnimation('Breathing');
	// 	}
	// 	return () => {
	// 		setAnimation('Breathing');
	// 	};
	// }, [props.audioState]);

	const { nodes, materials } = useGLTF(
		"https://procturemeet.s3.ap-southeast-1.amazonaws.com/3jsModel/default.glb",
	);
	// const { nodes, materials } = useGLTF('/models/defaultHalfBody.glb');
	// const { animations: idleAnimation } = useFBX('/animations/Idle.fbx');
	// const { animations: talkingAnimation } = useFBX('/animations/Talking.fbx');
	// const { animations: breathingAnimation } = useFBX(
	// 	'/animations/BreathingIdle.fbx'
	// );

	// idleAnimation[0].name = 'Idle';
	// talkingAnimation[0].name = 'Talking';
	// breathingAnimation[0].name = 'Breathing';

	// Replace 'mixamorig' with an empty string in each track name
	// idleAnimation[0].tracks.forEach((track) => {
	// 	track.name = track.name.replace('mixamorig', '');
	// });

	// talkingAnimation[0].tracks.forEach((track) => {
	// 	track.name = track.name.replace('mixamorig', '');
	// });

	// breathingAnimation[0].tracks.forEach((track) => {
	// 	track.name = track.name.replace('mixamorig', '');
	// });

	// const [animation, setAnimation] = useState('');

	// const group = useRef();
	// const { actions } = useAnimations(
	// 	[idleAnimation[0], talkingAnimation[0], breathingAnimation[0]],
	// 	group
	// );

	// useEffect(() => {
	// 	if (animation && actions[animation]) {
	// 		actions[animation].reset().fadeIn(0.5).play();
	// 	}

	// 	return () => {
	// 		if (animation && actions[animation]) {
	// 			actions[animation].fadeOut(0.5);
	// 		}
	// 	};
	// }, [animation]);

	// ref={group}

	return (
		<group {...props} dispose={null} ref={groupRef}>
			<primitive object={nodes.Hips} />
			<skinnedMesh
				name="EyeLeft"
				geometry={nodes.EyeLeft.geometry}
				material={materials.Wolf3D_Eye}
				skeleton={nodes.EyeLeft.skeleton}
				morphTargetDictionary={nodes.EyeLeft.morphTargetDictionary}
				morphTargetInfluences={nodes.EyeLeft.morphTargetInfluences}
			/>
			<skinnedMesh
				name="EyeRight"
				geometry={nodes.EyeRight.geometry}
				material={materials.Wolf3D_Eye}
				skeleton={nodes.EyeRight.skeleton}
				morphTargetDictionary={nodes.EyeRight.morphTargetDictionary}
				morphTargetInfluences={nodes.EyeRight.morphTargetInfluences}
			/>
			<skinnedMesh
				name="Wolf3D_Head"
				geometry={nodes.Wolf3D_Head.geometry}
				material={materials.Wolf3D_Skin}
				skeleton={nodes.Wolf3D_Head.skeleton}
				morphTargetDictionary={nodes.Wolf3D_Head.morphTargetDictionary}
				morphTargetInfluences={nodes.Wolf3D_Head.morphTargetInfluences}
			/>
			<skinnedMesh
				name="Wolf3D_Glasses"
				geometry={nodes.Wolf3D_Glasses.geometry}
				material={materials.Wolf3D_Glasses}
				skeleton={nodes.Wolf3D_Glasses.skeleton}
			/>
			<skinnedMesh
				name="Wolf3D_Teeth"
				geometry={nodes.Wolf3D_Teeth.geometry}
				material={materials.Wolf3D_Teeth}
				skeleton={nodes.Wolf3D_Teeth.skeleton}
				morphTargetDictionary={nodes.Wolf3D_Teeth.morphTargetDictionary}
				morphTargetInfluences={nodes.Wolf3D_Teeth.morphTargetInfluences}
			/>
			<skinnedMesh
				name="Wolf3D_Hair"
				geometry={nodes.Wolf3D_Hair.geometry}
				material={materials.Wolf3D_Hair}
				skeleton={nodes.Wolf3D_Hair.skeleton}
			/>
			<skinnedMesh
				name="Wolf3D_Body"
				geometry={nodes.Wolf3D_Body.geometry}
				material={materials.Wolf3D_Body}
				skeleton={nodes.Wolf3D_Body.skeleton}
			/>
			<skinnedMesh
				name="Wolf3D_Outfit_Bottom"
				geometry={nodes.Wolf3D_Outfit_Bottom.geometry}
				material={materials.Wolf3D_Outfit_Bottom}
				skeleton={nodes.Wolf3D_Outfit_Bottom.skeleton}
			/>
			<skinnedMesh
				name="Wolf3D_Outfit_Footwear"
				geometry={nodes.Wolf3D_Outfit_Footwear.geometry}
				material={materials.Wolf3D_Outfit_Footwear}
				skeleton={nodes.Wolf3D_Outfit_Footwear.skeleton}
			/>
			<skinnedMesh
				name="Wolf3D_Outfit_Top"
				geometry={nodes.Wolf3D_Outfit_Top.geometry}
				material={materials.Wolf3D_Outfit_Top}
				skeleton={nodes.Wolf3D_Outfit_Top.skeleton}
			/>
		</group>
	);
}

// useGLTF.preload("/models/default.glb");
