import axios from "./axiosInstance";

const getUploadCandidateSampleDownload = async (body) => {
	try {
		const response = await axios({
			method: "post",
			url: `/api/import/sample-candidates`,
			responseType: "blob",
		});
		return response;
	} catch (e) {
		const errorMessage = e?.response?.data;
		const prasedMessage = JSON.parse(await errorMessage.text());
		throw new Error(prasedMessage.message);
	}
};

const uploadCandidates = async (formData) => {
	let response;
	try {
		response = await axios.post(`/api/import/candidates`, formData, {
			headers: {
				"Content-Type": "multipart/form-data",
			},
		});
		if (response.status === 201) {
			return response?.data;
		}
	} catch (e) {
		if (e.response) {
			if (e.response.data) {
				const { message } = e.response.data;
				return { failed: true, message };
			}
		} else {
			console.log(e.message);
			return { failed: true, message: e.message };
		}
	}
};

const getImportFiles = async () => {
	try {
		const response = await axios({
			method: "post",
			url: `/api/import/`,
		});
		return response;
	} catch (error) {
		const message = error.response?.data?.message || error.message;
		throw new Error(message);
	}
};

const getUploadCandidateErrorDownload = async (importId) => {
	try {
		const response = await axios({
			method: "post",
			url: `/api/import/error-candidates`,
			data: { importId },
			responseType: "blob",
		});
		return response;
	} catch (e) {
		const errorMessage = e?.response?.data;
		const prasedMessage = JSON.parse(await errorMessage.text());
		throw new Error(prasedMessage.message);
	}
};

export {
	getUploadCandidateSampleDownload,
	uploadCandidates,
	getImportFiles,
	getUploadCandidateErrorDownload,
};
