import axios from "axios";
const fetchFolderNames = async function (data = {}) {
	try {
		const res = await axios.post(`/api/folders/getFolderNames`, data);
		return res?.data || [];
	} catch (e) {
		console.error(e);
		const message = e?.response?.data?.message || e?.message;
		throw new Error(message);
	}
};

const addFolderName = async function (data = {}) {
	try {
		const res = await axios.patch(`/api/folders/addFolderName`, data);
		return res?.data || [];
	} catch (e) {
		console.error(e);
		const message = e?.response?.data?.message || e?.message;
		throw new Error(message);
	}
};

const deleteFolderName = async function (data = {}) {
	try {
		const res = await axios.patch(`/api/folders/deleteFolderName`, data);
		return res?.data || [];
	} catch (e) {
		console.error(e);
		const message = e?.response?.data?.message || e?.message;
		throw new Error(message);
	}
};

const updateFolderName = async function (data = {}) {
	try {
		const res = await axios.patch(`/api/folders/editFolderName`, data);
		return res?.data || [];
	} catch (e) {
		console.error(e);
		const message = e?.response?.data?.message || e?.message;
		throw new Error(message);
	}
};

export { fetchFolderNames, addFolderName, deleteFolderName, updateFolderName };
