import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const apiSlice = createApi({
	reducerPath: "api",
	baseQuery: fetchBaseQuery({
		baseUrl: "/api",
	}),
	tagTypes: ["Question-Bank", "Categories", "Cheating-Scores"],
	endpoints: (builder) => ({
		getCategories: builder.query({
			query: () => "/question-bank/categories",
			providesTags: ["Categories"],
		}),
		addNewCategory: builder.mutation({
			query: (data) => {
				return {
					url: "/question-bank/new-category",
					method: "POST",
					body: data,
				};
			},
			invalidatesTags: ["Categories"],
		}),
		getQuestionBank: builder.query({
			query: () => ({ url: "/question-bank/all", method: "GET" }),
			providesTags: ["Question-Bank"],
		}),
		addNewQuestion: builder.mutation({
			query: (data) => {
				return {
					method: "POST",
					url: "/question-bank/new-question",
					body: data,
				};
			},
			invalidatesTags: ["Question-Bank"],
		}),
		updateQuestion: builder.mutation({
			query: (data) => {
				return {
					method: "PUT",
					url: "/question-bank/question",
					body: data,
				};
			},
			invalidatesTags: ["Question-Bank"],
		}),
		deleteQuestion: builder.mutation({
			query: (data) => {
				return {
					method: "DELETE",
					url: `/question-bank/category/${data?.cid}/question/${data?.qid}`,
				};
			},
			invalidatesTags: ["Question-Bank"],
		}),
		deleteCategory: builder.mutation({
			query: (categoryId) => {
				return {
					method: "DELETE",
					url: `/question-bank/category/${categoryId}`,
				};
			},
			invalidatesTags: ["Categories"],
		}),
		getCheatingScoresForOpening: builder.query({
			query: (openingId) => ({
				url: `/openings/cheating-scores/${openingId}?outputFormat=map`,
				method: "GET",
			}),
			providesTags: ["Cheating-Scores"],
		}),
		getCheatingScoreForCandidate: builder.query({
			query: (interviewReportId) => ({
				url: `/openings/cheating-score-for-candidate/${interviewReportId}`,
				method: "GET",
			}),
			providesTags: ["Cheating-Scores"],
		}),
		createNewCheatingScore: builder.mutation({
			query: (data) => {
				return {
					method: "POST",
					url: `/openings/cheating-score-for-candidate/${data.interviewReportId}`,
					body: {
						prompt: data.prompt,
					},
				};
			},
			invalidatesTags: ["Cheating-Scores"],
		}),
	}),
});

export const {
	useGetCategoriesQuery,
	useGetQuestionBankQuery,
	useAddNewCategoryMutation,
	useAddNewQuestionMutation,
	useUpdateQuestionMutation,
	useDeleteQuestionMutation,
	useDeleteCategoryMutation,
	useGetCheatingScoresForOpeningQuery,
	useGetCheatingScoreForCandidateQuery,
	useCreateNewCheatingScoreMutation,
} = apiSlice;
