import { Tooltip } from "@mui/material";
import React from "react";

const CandidateFitScoreRenderer = ({ params }) => {
	const interviewReport = params.row;
	const candidateFitScore = interviewReport?.candidateFitScore;
	const candidateFitScoreJustification = interviewReport?.candidateFitScoreJustification;
	const [colorBlock, label] = getDataOnHover(candidateFitScore, candidateFitScoreJustification);

	return (
		<div className="flex items-center justify-center w-full h-full ">
			<div className="cursor-pointer">
				<Tooltip title={label}>{colorBlock}</Tooltip>
			</div>
		</div>
	);
};

export default CandidateFitScoreRenderer;

const getDataOnHover = (candidateFitScore, candidateFitScoreJustification) => {
	let colorBlock = "";
	let label = "";
	switch (candidateFitScore) {
		case "LOW": {
			colorBlock = "🟥";
			label = (
				<JustificationForCandidateScore
					colorBlock={colorBlock}
					baseNote={"LOW FIT - Resume does not align well with the JD."}
					candidateFitScoreJustification={candidateFitScoreJustification}
				/>
			);
			break;
		}
		case "MEDIUM": {
			colorBlock = "🟨";
			label = (
				<JustificationForCandidateScore
					colorBlock={colorBlock}
					baseNote={"MEDIUM FIT - Some relevant experience, but gaps exist."}
					candidateFitScoreJustification={candidateFitScoreJustification}
				/>
			);
			break;
		}
		case "HIGH": {
			colorBlock = "🟩";
			label = (
				<JustificationForCandidateScore
					colorBlock={colorBlock}
					baseNote={"HIGH FIT - Strong alignment between JD and resume."}
					candidateFitScoreJustification={candidateFitScoreJustification}
				/>
			);
			break;
		}
		default: {
			colorBlock = "";
			label = "";
		}
	}
	return [colorBlock, label];
};

const JustificationForCandidateScore = ({ colorBlock, candidateFitScoreJustification = "", baseNote }) => {
	const bulletedPoints = candidateFitScoreJustification.split("- ");
	return (
		<div className="p-2">
			<p className="text-xs">
				{colorBlock} {baseNote}
			</p>
			<br />
			<ul className="pl-2">
				{bulletedPoints.map((point) => {
					if (!point) {
						return null;
					}
					return <li key={point}>{point}</li>;
				})}
			</ul>
		</div>
	);
};
