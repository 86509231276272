import CheatingScoreRenderer from "./columnRenderer/CheatingScoreRenderer";

export const antiCheatingScoreColumnDef = {
	field: "cheatingScore",
	headerName: "AI-Cheating Score",
	headerClassName: "bg-[#F3F4F6] border-left text-xs font-medium uppercase text-gray-500",
	width: 150,
	headerAlign: "center",
	renderCell: (params) => <CheatingScoreRenderer params={params} />,
	filterable: false,
	sortable: false,
	valueGetter: (_, row) => {
		return row?.cheatingScore?.likelihood_of_cheating ?? 0;
	},
};
