import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	showCompletedInterviews: false,
	showActiveOpenings: false,
	organizationId: "",
	selectedMonth: new Date().toISOString().slice(0, 7),
	selectedOpening: null,
	flag: null,
};

const organizationInterviewSlice = createSlice({
	name: "organization",
	initialState,
	reducers: {
		updateState: (state, action) => {
			const { field, value } = action.payload;
			state[field] = value;
		},
	},
});

const organizationInterviewReducer = organizationInterviewSlice.reducer;
export default organizationInterviewReducer;

export const { updateState } = organizationInterviewSlice.actions;
