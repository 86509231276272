import { FormControl, Select, MenuItem } from "@mui/material";
export const DeviceSelector = ({ className, disabled, deviceList, selectedDevice, onDeviceChange, type }) => {
	return (
		<FormControl
			size="small"
			sx={{
				width: "auto",
				maxWidth: 200,
			}}
			className={className}
		>
			<Select
				disabled={disabled}
				className="text-sm truncate md:max-w-full max-w-[100px]"
				sx={{
					overflow: "hidden",
					textOverflow: "ellipsis",
					whiteSpace: "nowrap",
				}}
				labelId={`${type}DeviceLabel`}
				id={`${type}DeviceSelect`}
				value={selectedDevice}
				onChange={onDeviceChange}
			>
				{deviceList.map((device) => (
					<MenuItem
						sx={{
							overflow: "hidden",
							textOverflow: "ellipsis",
							whiteSpace: "nowrap",
							fontSize: "0.875rem",
							lineHeight: "1.25rem",
						}}
						key={device.deviceId}
						value={device.deviceId}
					>
						{device.label || `${type} ${device.deviceId}`}
					</MenuItem>
				))}
			</Select>
		</FormControl>
	);
};
