import React, { useEffect, useState } from "react";
import {
	Button,
	Chip,
	CircularProgress,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Typography,
	IconButton,
	Tooltip,
} from "@mui/material";

import { getImportFiles, getUploadCandidateErrorDownload } from "../utilities/import";
import { formatLabel } from "../components/Pricing/formatLabel";
import { toast } from "react-toastify";
import { CloudDownload, Download } from "@mui/icons-material";
import { useSelector } from "react-redux";

const ImportDashboard = ({ organizationId }) => {
	const user = useSelector((state) => state.auth?.user);
	const [isLoading, setIsLoading] = useState(false);
	const [files, setFiles] = useState([]);
	const [selectedFile, setSelectedFile] = useState(null);
	const [isModalOpen, setIsModalOpen] = useState(false);

	const getFiles = async () => {
		try {
			setIsLoading(true);
			const res = await getImportFiles({ organizationId });
			setFiles(res.data.data);
			console.log(res.data.data)
			setIsLoading(false);
		} catch (error) {
			console.log(error.message);
			setIsLoading(false);
		}
	};

	const handleOpenModal = (file) => {
		setSelectedFile(file);
		setIsModalOpen(true);
	};

	const handleCloseModal = () => {
		setIsModalOpen(false);
		setSelectedFile(null);
	};

	const handleDownloadErrors = async () => {
		try {
			if (!selectedFile) return;
			const response = await getUploadCandidateErrorDownload(selectedFile._id);
			if (response.data) {
				const url = window.URL.createObjectURL(new Blob([response.data]));
				const link = document.createElement("a");
				link.href = url;
				link.setAttribute("download", `zinterview_upload_error_candidates.xlsx`);
				document.body.appendChild(link);
				link.click();
				link.remove();
			}
		} catch (error) {
			toast.error(error.message);
		}
	};

	useEffect(() => {
		getFiles();
	}, [organizationId]);

	return (
		<div className="w-full mx-auto p-2">
			{isLoading ? (
				<div className="text-center">
					<CircularProgress />
				</div>
			) : (
				<TableContainer component={Paper}>
					<Table>
						<TableHead>
							<TableRow className="bg-gray-100 text-sm">
								<TableCell className="text-center text-gray-500 uppercase tracking-wide text-xs font-medium">
									Date
								</TableCell>
								{user?.role == '1096' ? 
									<TableCell className="text-center text-gray-500 uppercase tracking-wide text-xs font-medium">
									Organization
									</TableCell>
									: 
								null}
								
								<TableCell className="text-center text-gray-500 uppercase tracking-wide text-xs font-medium">
									Opening
								</TableCell>
								<TableCell className="text-center text-gray-500 uppercase tracking-wide text-xs font-medium">
									Type
								</TableCell>
								<TableCell className="text-center text-gray-500 uppercase tracking-wide text-xs font-medium">
									Status
								</TableCell>
								<TableCell className="text-center text-gray-500 uppercase tracking-wide text-xs font-medium">
									Error Count
								</TableCell>
								<TableCell className="text-center text-gray-500 uppercase tracking-wide text-xs font-medium">
									Actions
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{files.length > 0 ? (
								files.map((row) => (
									<TableRow key={row._id}>
										<TableCell className="text-center">
											{new Date(row.createdAt).toLocaleString("en-US", {
												year: "numeric",
												month: "short",
												day: "numeric",
												hour: "2-digit",
												minute: "2-digit",
												second: "2-digit",
												hour12: true,
											})}
										</TableCell>
										{user?.role == '1096' ? 
										<TableCell className="text-center">
											{row.organizationId.organizationName}
										</TableCell>
										: null }
										<TableCell className="text-center">{row?.openingId?.title || 'No Title'}</TableCell>
										<TableCell className="text-center">
											<Chip label={formatLabel(row?.type)} color="secondary" />
										</TableCell>
										<TableCell className="text-center">
											<Chip
												label={row.status}
												color={row.status === "Completed" ? "success" : "default"}
											/>
										</TableCell>
										<TableCell className="text-center">
											<Tooltip
												title={`${row.errors?.length || 0} Error count while uploading the file`}
											>
												{row.errors?.length || 0}
											</Tooltip>
										</TableCell>
										<TableCell className="text-center">
											{row.status === "Completed" && row.errors?.length > 0 && (
												<Button
													variant="outlined"
													color="primary"
													onClick={() => handleOpenModal(row)}
												>
													View Errors
												</Button>
											)}
										</TableCell>
									</TableRow>
								))
							) : (
								<TableRow>
									<TableCell colSpan={6} className="text-center">
										No Files till now
									</TableCell>
								</TableRow>
							)}
						</TableBody>
					</Table>
				</TableContainer>
			)}

			{/* Error Modal */}
			<Dialog open={isModalOpen} onClose={handleCloseModal} fullWidth>
				<DialogTitle>
					Error Details
					<IconButton onClick={handleDownloadErrors} color="primary">
						<Download />
					</IconButton>
				</DialogTitle>

				<DialogContent>
					{selectedFile?.errors?.length > 0 ? (
						selectedFile.errors.map((error, index) => (
							<div key={index} className="mb-4">
								<Typography variant="subtitle2">Record {index + 1}:</Typography>
								<Typography variant="body2">Error: {error.error}</Typography>
								<pre className="bg-gray-100 p-2 rounded">
									{JSON.stringify(error.record, null, 2)}
								</pre>
							</div>
						))
					) : (
						<Typography>No errors to display.</Typography>
					)}
				</DialogContent>
				<DialogActions>
					<Button onClick={handleCloseModal} color="primary">
						Close
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
};

export default ImportDashboard;