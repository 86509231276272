import React, { useMemo } from "react";
import FlagIcon from "@mui/icons-material/Flag";
import { Tooltip } from "@mui/material";
import clsx from "clsx";
const CheatingScoreRenderer = ({ params }) => {
	const { row: report } = params;
	const score = report?.cheatingScore ?? {};
	const cheatingScoreManualOverrideStatus = report?.cheatingScoreManualOverrideStatus;
	return (
		<div className="flex justify-center items-center h-full">
			<FlagComponent
				score={score?.likelihood_of_cheating ?? "-"}
				verdict={score?.verdict}
				cheatingScoreManualOverrideStatus={cheatingScoreManualOverrideStatus}
			/>
		</div>
	);
};

export const FlagComponent = ({ score, verdict = "", cheatingScoreManualOverrideStatus = "auto" }) => {
	const [color, finalSummary] = useMemo(() => {
		return getFinalSummaryTextForACS(score, verdict, cheatingScoreManualOverrideStatus);
	}, [score, verdict, cheatingScoreManualOverrideStatus]);
	return (
		<Tooltip title={score === "-" ? "Cheating Score Unavailable" : <> {finalSummary}</>}>
			<FlagIcon className={clsx(color)} />
		</Tooltip>
	);
};

export default CheatingScoreRenderer;

export const getFinalSummaryTextForACS = (score, verdict, cheatingScoreManualOverrideStatus = "auto") => {
	let color = "text-gray-400";
	let note = "";
	let likelihoodMeter = "";
	if (cheatingScoreManualOverrideStatus === "auto") {
		if (score < 40) {
			color = "text-green-400";
			note = "Note: Review manually to rule out false negatives.";
			likelihoodMeter = "LOW";
		} else if (score >= 40 && score < 60) {
			color = "text-orange-400";
			note = "Note: Review manually to rule out false positives.";
			likelihoodMeter = "MEDIUM";
		} else if (score >= 60) {
			color = "text-red-600";
			note = "Note: Review manually to rule out false positives.";
			likelihoodMeter = "HIGH";
		}
	} else {
		if (cheatingScoreManualOverrideStatus === "non_cheating") {
			color = "text-green-400";
			note = 'Manually overridden to "Not Cheating" upon inspection';
		} else if (cheatingScoreManualOverrideStatus === "cheating") {
			color = "text-red-600";
			note = 'Manually overridden to "Cheating" upon inspection';
		}
	}
	const finalSummary = (
		<>
			{cheatingScoreManualOverrideStatus === "auto" && (
				<h6 className="text-sm font-medium mb-1">
					Cheating Likelihood: {verdict ? verdict : likelihoodMeter}{" "}
					<span className="font-light">({score / 10})</span>
				</h6>
			)}
			<p className="text-xs font-semibold">{note}</p>
		</>
	);
	return [color, finalSummary];
};
