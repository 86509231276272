import { useEffect, useRef } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { useGridApi } from "../../app/contexts/GridApiProvider";
import getColumnsAndRenderersOrganization from "./getColumnsAndRenderersOrganization";

const ReportsDataGridOrganization = ({ candidates }) => {
	const ref = useRef(null);
	const { setGridApi } = useGridApi();
	const columns = getColumnsAndRenderersOrganization();

	useEffect(() => {
		if (ref.current) {
			setGridApi(ref.current);
		}
	}, [ref, setGridApi]);

	return (
		<div style={{ height: "85vh", width: "100%" }}>
			<DataGrid
				apiRef={ref}
				rows={candidates}
				getRowId={(row) => row._id}
				columns={columns}
				// checkboxSelection
				disableRowSelectionOnClick
				rowHeight={70}
				pageSizeOptions={[100]}
				initialState={{
					pagination: {
						paginationModel: { pageSize: 100, page: 0 },
					},
				}}
				getRowClassName={() => "hoverRow"}
				sx={{
					"& .MuiDataGrid-cell:focus": {
						outline: "none",
					},
					"& .MuiDataGrid-columnHeader:focus": {
						outline: "none",
					},
				}}
				slotProps={{
					toolbar: {
						csvOptions: {
							fields: [
								"createdAt",
								"firstName",
								"score",
								"trustScore",
								"interviewStatus",
								"resumeFileNameInS3",
								"concatenationId",
							],
						},
					},
					filterPanel: {
						filterFormProps: {
							logicOperatorInputProps: {
								variant: "outlined",
								size: "small",
							},
							columnInputProps: {
								variant: "outlined",
								size: "small",
								sx: { mt: "auto" },
							},
							operatorInputProps: {
								variant: "outlined",
								size: "small",
								sx: { mt: "auto" },
							},
							valueInputProps: {
								InputComponentProps: {
									variant: "outlined",
									size: "small",
								},
							},
							deleteIconProps: {
								sx: {
									"& .MuiSvgIcon-root": { color: "#d32f2f" },
								},
							},
						},
						sx: {
							"& .MuiDataGrid-filterForm": { p: 2 },
							"& .MuiDataGrid-filterForm:nth-child(even)": {
								backgroundColor: (theme) =>
									theme.palette.mode === "dark" ? "#444" : "#f5f5f5",
							},
							"& .MuiDataGrid-filterFormLogicOperatorInput": { mr: 2 },
							"& .MuiDataGrid-filterFormColumnInput": { mr: 2, width: 150 },
							"& .MuiDataGrid-filterFormOperatorInput": { mr: 2 },
							"& .MuiDataGrid-filterFormValueInput": { width: 200 },
						},
					},
				}}
			/>
		</div>
	);
};

export default ReportsDataGridOrganization;
