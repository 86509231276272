import React, { useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import { toast } from "react-toastify";
import { Modal, Tooltip } from "@mui/material";
import Divider from "@mui/material/Divider";
import { zinterviewImageUrl } from "../../utilities/constants";
import { countWord } from "../../utilities/validation";
import { getFormattedDateTime4 } from "../../utilities/utilityFunctions";

const EmailTemplateEditor = ({
	emailBody,
	toggleEmailBodyVisibility,
	setEmailBody,
	joinEarly,
	joinOption,
	timeWindow,
	selectedDate,
	selectedTimeZone,
}) => {
	const [orgName, setOrgName] = useState(emailBody?.orgName || "");
	const [openingTitle, setOpeningTitle] = useState(emailBody?.openingTitle || "");
	const [requirementsIntro, setRequirementsIntro] = useState(emailBody?.requirementsIntro || "");
	const [requirementArray, setRequirementArray] = useState([...emailBody?.requirementArray] || []);
	const interviewUrl = "https://zinterview.ai/interview/aaaa/start/bbbb/cccc";

	function addRequirement() {
		let totalRequirementLength = requirementArray.length;
		if (totalRequirementLength >= 12) {
			toast.warning("Maximum limit exceeded.");
			return;
		}
		requirementArray.push("");
		setRequirementArray([...requirementArray]);
	}

	function applyEmailTemplateData(
		orgName,
		openingTitle,
		requirementsIntro,
		requirementArray,
	) {
		setEmailBody((prevState) => ({
			...prevState,
			orgName: orgName,
			openingTitle: openingTitle,
			requirementsIntro: requirementsIntro,
			requirementArray: requirementArray,
		}));
	}

	function saveChanges() {
		if (orgName.trim().length < 2 || orgName.trim().length > 50) {
			toast.warning("The organization name must be between 2 and 50 characters.");
			return;
		}
		if (openingTitle.trim().length < 2 || openingTitle.trim().length > 50) {
			toast.warning("The opening title must be between 2 and 50 characters long.");
			return;
		}
		if (
			requirementArray?.length != 0 &&
			(requirementsIntro.trim().length < 2 || requirementsIntro.trim().length > 50)
		) {
			toast.warning("Requirements Introduction must be between 2 and 50 characters long.");
			return;
		}
		for (let i = 0; i < requirementArray?.length; i++) {
			const requirement = requirementArray[i];
			const words = countWord(requirement);
			if (words < 3 || words > 80) {
				toast.warning(`Requirement ${i + 1} must contain between 3 and 80 words.`);
				return;
			}
		}
		applyEmailTemplateData(orgName, openingTitle, requirementsIntro, requirementArray);
		toggleEmailBodyVisibility();
		toast.success("Email body successfully saved.");
	}

	function cancelChanges() {
		toggleEmailBodyVisibility();
	}

	return (
		<Modal open={true}>
			<div className="flex  h-screen bg-gray-100 overflow-hidden">
				<div className="flex-1 p-5 border-gray-300 overflow-hidden">
					<div className="relative">
						<div className="flex justify-between items-center mb-4">
							<h2 className="text-2xl font-semibold mx-auto">Edit Template</h2>
							<button
								className="px-2 mr-2 py-1 border-none bg-red-700 text-white rounded-md shadow hover:bg-red-600 text-sm cursor-pointer"
								onClick={() => cancelChanges()}
							>
								<i className="fas fa-times mr-2"></i>Cancel Changes
							</button>
						</div>
					</div>

					<div
						className="overflow-y-auto max-h-[calc(100vh-100px)] rounded-lg"
						style={{
							boxShadow:
								"4px 4px 6px 6px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)",
						}}
					>
						<div className="max-w-3xl mx-auto bg-white p-8 shadow-md rounded-lg">
							<div className="mb-4">
								<label className="block font-semibold mb-2">Organization Name</label>
								<input
									type="text"
									value={orgName}
									onChange={(e) => setOrgName(e.target.value)}
									className="w-full p-2 border border-gray-300 rounded-md"
								/>
							</div>
							<div className="mb-4">
								<label className="block font-semibold mb-2">Opening Title</label>
								<input
									type="text"
									value={openingTitle}
									onChange={(e) => setOpeningTitle(e.target.value)}
									className="w-full p-2 border border-gray-300 rounded-md"
								/>
							</div>
						</div>
						<div
							className="max-w-3xl mx-auto bg-white p-8 shadow-md rounded-lg mt-5"
							style={{
								boxShadow:
									"4px 4px 6px 6px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)",
							}}
						>
							<div className="mb-4">
								<div className="flex justify-between font-semibold mb-2">
									<label>Requirements Introduction</label>
									<button
										className="px-2 py-1 border-none bg-purple-700 text-white rounded-md shadow hover:bg-purple-600 text-sm cursor-pointer"
										onClick={() => addRequirement()}
									>
										<i className="fas fa-plus mr-1"></i> Add
									</button>
								</div>
								<textarea
									value={requirementsIntro}
									onChange={(e) => setRequirementsIntro(e.target.value)}
									className="w-full p-2 border border-gray-300 rounded-md"
								/>
							</div>
							{requirementArray.map((data, index) => (
								<div className="mb-4" key={index}>
									<div className="flex justify-between">
										<label className="block font-semibold mb-2">
											Requirement {index + 1}
										</label>
										<Tooltip title="delete">
											<DeleteIcon
												className="text-red-600 text-2xl cursor-pointer"
												onClick={() => {
													requirementArray.splice(index, 1);
													setRequirementArray([...requirementArray]);
												}}
											/>
										</Tooltip>
									</div>
									<textarea
										value={data}
										onChange={(e) => {
											const newArray = [...requirementArray];
											newArray[index] = e.target.value;
											setRequirementArray(newArray);
										}}
										placeholder={`Enter Requirement`}
										className="w-full p-2 border border-gray-300 rounded-md"
									/>
								</div>
							))}
						</div>
					</div>
				</div>
				<Divider orientation="vertical" variant="middle" flexItem />

				<div className="flex-1 p-5 border-gray-300 overflow-hidden">
					<div className="relative">
						<div className="flex justify-between items-center mb-4">
							<h2 className="text-2xl font-semibold mx-auto">Template Preview</h2>
							<button
								className="px-2 mr-2 py-1 border-none bg-green-700 text-white rounded-md shadow hover:bg-green-600 text-sm cursor-pointer"
								onClick={() => saveChanges()}
							>
								<i className="fas fa-save mr-2"></i> Save Changes
							</button>
						</div>
					</div>
					<div
						className="overflow-y-auto max-h-[calc(100vh-100px)] rounded-lg"
						style={{
							boxShadow:
								"4px 4px 6px 6px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)",
						}}
					>
						<div className="max-w-3xl mx-auto bg-white p-8">
							<div className="text-center mb-8">
								<img src={zinterviewImageUrl} alt="logo" className="mx-auto mb-10 w-60" />
							</div>
							<p className="text-lg text-gray-700 mb-6">
								Your interview for the {openingTitle} at {orgName} has been scheduled as
								follows:
							</p>
							<p className="text-lg mb-2 underline-none">
								📅 Scheduled Date & Time:{" "}
								<span className="text-gray-600 text-[17px]">
									{getFormattedDateTime4(selectedDate)} ({selectedTimeZone})
								</span>
							</p>
							<p className="text-lg mb-3 underline-none">
								🔗 Interview Link: <a href={interviewUrl}>{interviewUrl}</a>
							</p>
							<p className="font-bold text-gray-500 mb-4">
								{joinOption === "anytime"
									? "*You may take the interview at the scheduled time or at a time that works best for you. Simply click the link whenever you're ready."
									: joinOption === "onTime"
										? joinEarly
											? "*You can join the interview at the scheduled time. If needed, you may join up to 10 minutes late or up to 10 minutes early."
											: "*You can join the interview at the scheduled time. If needed, you may join up to 10 minutes late."
										: joinOption === "timeWindow"
											? joinEarly
												? `*You can join the interview at the scheduled time.${
														timeWindow * 60
															? ` If needed, you may join up to ${timeWindow * 60} minutes late`
															: ""
													}${timeWindow > 0 ? " or" : " If needed, you may"} join up to 10 minutes early.`
												: `*You can join the interview at the scheduled time. ${timeWindow * 60 ? `If needed, you may join up to ${timeWindow * 60} minutes late.` : ""}`
											: ""}
							</p>
							{requirementArray.length ? (
								<p className="text-lg text-gray-700 mb-3">{requirementsIntro}</p>
							) : (
								""
							)}
							<ul className="list-disc pl-8 text-lg text-gray-700 mb-12">
								{requirementArray?.map((data, index) => (
									<li key={index}>{data}</li>
								))}
							</ul>
						</div>
					</div>
				</div>
			</div>
		</Modal>
	);
};

export default EmailTemplateEditor;
