import { TextButton } from "./Button";
import { UPDATEQUESTION } from "../../pages/QuestionBank";
import { toast } from "react-toastify";
import clsx from "clsx";
import { useDeleteQuestionMutation } from "../../features/api/apiSlice";
import { useMemo } from "react";
import { difficultyLevels } from "../../utilities/constants";

const QuestionBankTable = ({
	questionBank = [],
	filter = "All",
	setModalType,
	setToUpdateDoc,
	isFetching,
}) => {
	let filteredQuestionBank = useMemo(() => {
		return filter === "All" ? questionBank : questionBank.filter((category) => category._id === filter);
	}, [questionBank, filter]);

	return (
		<div className="overflow-x-auto">
			<table className="min-w-full divide-y divide-gray-200 border-collapse">
				<thead className="bg-gray-50 border-b-[1px] border-x-0 border-t-0 border-solid border-gray-200">
					<tr>
						<th
							scope="col"
							className="px-6 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
						>
							Category
						</th>
						<th
							scope="col"
							className="px-6 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
						>
							Question
						</th>
						<th
							scope="col"
							className="px-6 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
						>
							Difficulty
						</th>
						<th
							scope="col"
							className="px-6 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
						>
							Actions
						</th>
					</tr>
				</thead>
				<tbody className="bg-white divide-y divide-gray-200">
					{filteredQuestionBank.map((questionObj) => {
						const {
							questions: { _id: qid },
						} = questionObj;
						return (
							<SingleRow
								key={qid}
								questionObj={questionObj}
								isFetching={isFetching}
								setModalType={setModalType}
								setToUpdateDoc={setToUpdateDoc}
							/>
						);
					})}
				</tbody>
			</table>
		</div>
	);
};

const SingleRow = ({ questionObj, isFetching, setModalType, setToUpdateDoc }) => {
	const {
		_id: cid,
		categoryName,
		questions: { _id: qid, question, difficulty },
	} = questionObj;

	const [deleteQuestion, { isLoading: isDeletingQuestion }] = useDeleteQuestionMutation();

	const handleUpdateQuestion = () => {
		setModalType(UPDATEQUESTION);
		setToUpdateDoc({
			qid,
			category: cid,
			difficulty,
			question,
		});
	};
	const handleDeleteQuestion = async (qid, cid) => {
		try {
			await deleteQuestion({ cid, qid }).unwrap();
		} catch (error) {
			const status = error?.status;
			const message = error?.data?.message || error.message;
			if (status === 400) {
				toast.warn(message);
			}
		}
	};

	return (
		<tr className={clsx("border-b border-solid border-t-0 border-x-0", isFetching ? "opacity-50" : "")}>
			<td className="px-6  py-4 whitespace-nowrap">{categoryName}</td>
			<td className="px-6 py-4 overflow-ellipsis whitespace-nowrap overflow-hidden max-w-[60ch]">
				{question}
			</td>
			<td className="px-6  py-4 whitespace-nowrap">{difficultyLevels[difficulty]}</td>
			<td className="px-6 py-4 whitespace-nowrap">
				<div className="flex flex-row gap-2">
					<TextButton
						disabled={isFetching || isDeletingQuestion}
						colorVariant={"text-blue"}
						onClick={() => handleUpdateQuestion()}
					>
						Edit
					</TextButton>
					<TextButton
						disabled={isFetching || isDeletingQuestion}
						colorVariant={"text-red"}
						onClick={() => handleDeleteQuestion(qid, cid)}
					>
						Delete
					</TextButton>
				</div>
			</td>
		</tr>
	);
};

export default QuestionBankTable;
