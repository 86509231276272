import { useEffect, useMemo, useState } from "react";
import { io } from "socket.io-client";
import { useDispatch, useSelector } from "react-redux";
import { addDropReports, removeDropReports } from "../../features/dropReports/dropReportsSlice";
import { addNotification, removeNotification } from "../../features/notifications/NotificationsSlice";
import zinterviewImage from "../../assets/zinterview-logo-white.png";
import { useNavigate } from "react-router-dom";
import { addOnGoingReport, removeOnGoingReport } from "../../features/api/onGoingReportSlice";
import {
	createNotificationMessage,
	createNotificationObject,
	InterviewStatus,
} from "../../utilities/realTimeNotifications";
let socket = null;
const RealTimeNotificationSocket = () => {
	const { user } = useSelector((state) => state.auth);
	const isNotificationSupported = typeof Notification !== "undefined";
	let permission = isNotificationSupported ? Notification.permission : "default";
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const protocol = window.location.protocol === "https:" ? "wss:" : "ws:";
	const host = window.location.hostname === "localhost" ? "localhost:5002" : window.location.host;
	const path = "/ws/realtime-notifications";
	const wsUrl = `${protocol}//${host}${path}`;
	const recentNotifications = new Set();

	socket = useMemo(
		() =>
			io(wsUrl, {
				transports: ["websocket"],
				reconnectionAttempts: 5,
				reconnectionDelay: 5000,
			}),
		[wsUrl],
	);

	useEffect(() => {
		const takePermission = async () => {
			if (isNotificationSupported && permission !== "granted") {
				permission = await Notification.requestPermission();
			}
		};
		takePermission();
	}, []);

	useEffect(() => {
		const handleBeforeUnload = (event) => {
			disconnectUserFromRoom(user?.organizationId, socket);
		};
		window.addEventListener("beforeunload", handleBeforeUnload);
		return () => {
			window.removeEventListener("beforeunload", handleBeforeUnload);
		};
	}, [user?.organizationId, socket, user]);

	const triggerNotification = (message, destinationUrl, status, interviewReportId) => {
		const notificationKey = `${status}_${interviewReportId}`;
		if (recentNotifications.has(notificationKey)) return;
		if (isNotificationSupported && permission === "granted") {
			const notification = new Notification(status, {
				body: message,
				icon: zinterviewImage,
				image: zinterviewImage,
			});
			notification.onclick = (event) => {
				event.preventDefault();
				if (document.visibilityState === "hidden") {
					window.focus();
				}
				navigate(destinationUrl);
			};
			recentNotifications.add(notificationKey);
			setTimeout(() => {
				recentNotifications.delete(notificationKey);
			}, 10000);
		} else {
			console.log("Notifications not supported or permission not granted.");
		}
	};

	const removeInactiveNotifications = (id) => {
		dispatch(removeNotification({ interviewReportId: id, status: 0 }));
		dispatch(removeNotification({ interviewReportId: id, status: 1 }));
	};

	const saveDropData = (data) => {
		dispatch(addDropReports(data));
		dispatch(removeOnGoingReport(data?._id));
		// dispatch(removeNotification({ interviewReportId: data?._id, status: 0 }));
		const message = createNotificationMessage(data, "drop");
		const status = 1;
		let destinationUrl = "/superadmin/dropInterviews";
		let notificationObject = createNotificationObject(message, data, destinationUrl, status);
		dispatch(addNotification(notificationObject));
		triggerNotification(message, destinationUrl, InterviewStatus[status], data?._id);
	};

	const saveOnGoingData = (data) => {
		dispatch(addOnGoingReport(data));
		// dispatch(removeDropReports(data?._id));
		// dispatch(removeNotification({ interviewReportId: data?._id, status: 1 }));
		const message = createNotificationMessage(data, "start");
		const status = 0;
		let destinationUrl = "/superadmin/onGoing";
		let notificationObject = createNotificationObject(message, data, destinationUrl, status);
		dispatch(addNotification(notificationObject));
		triggerNotification(message, destinationUrl, InterviewStatus[status], data?._id);
	};

	const saveInterviewCompletedData = (data) => {
		dispatch(removeOnGoingReport(data?._id));
		dispatch(removeDropReports(data?._id));
		removeInactiveNotifications(data?._id);
	};

	const updateMeeting = (data) => {
		dispatch(addOnGoingReport(data));
	};

	const updatePreferredSkills = (data) => {
		dispatch(addOnGoingReport(data));
	};

	const updateTrustScore = (data) => {
		dispatch(addOnGoingReport(data));
	};

	useEffect(() => {
		if (user?.organizationId) {
			socket.on("connect", () => {
				socket.emit("joinOrganizationRoom", {
					organizationId: user.organizationId,
					message: "Successfully sent organization id",
				});
				console.log("Connected:", socket.id);
			});

			//When the candidate drops or leaves the interview.
			socket.on("drop-message", (data) => {
				saveDropData(data);
			});

			// When the candidate starts the interview.
			socket.on("onGoing-message", (data) => {
				saveOnGoingData(data);
			});

			// When the candidate completes the interview or clicks the 'End Interview' button on the interview page.
			socket.on("interviewComplete-message", (data) => {
				saveInterviewCompletedData(data);
			});

			//When meeting ID is updated
			socket.on("updateMeeting-message", (data) => {
				updateMeeting(data);
			});

			//When 'Preferred Skills updated.
			socket.on("updatePreferredSkills-message", (data) => {
				updatePreferredSkills(data);
			});

			// When 'Trust Score' is updated
			socket.on("updateTrustScore-message", (data) => {
				updateTrustScore(data);
			});

			socket.on("connect_error", (error) => {
				console.error("Connection error:", error);
			});

			socket.on("disconnect", (reason) => {
				console.log("Disconnected:", reason);
			});

			socket.on("reconnect_attempt", (attempt) => {
				console.log("Reconnection attempt:", attempt);
			});
		} else {
			if (socket.connected) {
				socket.disconnect();
			}
		}

		return () => {
			socket.off("drop-message");
			socket.off("onGoing-message");
			socket.off("interviewComplete-message");
			socket.off("updateMeeting-message");
			socket.off("updatePreferredSkills-message");
			socket.off("updateTrustScore-message");
			socket.off("connect_error");
			socket.off("disconnect");
			socket.off("reconnect_attempt");
			socket.disconnect();
		};
	}, [socket, user, dispatch]);

	return null;
};

export const disconnectUserFromRoom = (organizationId) => {
	if (organizationId && socket?.connected) {
		console.log("Page closing, emitting customDisconnect");
		socket.emit("customDisconnect", {
			organizationId: organizationId,
			reason: "Page is closing",
		});
	}
};

export default RealTimeNotificationSocket;
