import React, { useEffect, useState } from "react";
import { IconButton, Checkbox, FormControlLabel, Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import axios from "../../utilities/axiosInstance";
import { useSelector } from "react-redux";

const InterviewReportModal = ({ isOpen, setIsModalOpen, setConfigData }) => {
	const user = useSelector((state) => state.auth.user);

	// Local state for checkboxes
	const [selectedFields, setSelectedFields] = useState({
		name: false,
		email: false,
		phone: false,
		trustScore: false,
		interviewDate: false,
		interviewEndDate: false,
		candidatePhoto: false,
		questionsAndEvaluation: false,
		cheatingScore: false,
	});

	// State for uploaded image
	const [image, setImage] = useState(null);

	useEffect(() => {
		const fetchConfig = async () => {
			try {
				// Fetch configuration
				const findConfig = await axios.get(`/api/evaluationReports/config/${user.organizationId}`);
				const configData = findConfig?.data?.data;

				if (configData) {
					setSelectedFields((prev) => ({
						...prev, // Retain any fields not fetched
						...configData, // Update state with the fetched configuration
					}));
				}
			} catch (error) {
				console.error("Error fetching config data:", error);
			}
		};

		fetchConfig();
	}, [user.organizationId]);

	// Handle checkbox toggle
	const handleCheckboxChange = (field) => {
		setSelectedFields((prev) => ({
			...prev,
			[field]: !prev[field],
		}));
	};

	// Handle image file selection
	const handleImageChange = (event) => {
		const file = event.target.files[0];
		if (file) {
			const reader = new FileReader();
			reader.onloadend = () => {
				setImage(reader.result); // Set the image preview
			};
			reader.readAsDataURL(file); // Read the image as base64
		}
	};

	// Handle removing the image
	const handleRemoveImage = () => {
		setImage(null); // Clear the image state
	};

	// Handle Save Button
	const handleSave = async () => {
		try {
			const response = await axios.post(
				"/api/evaluationReports/hide-report-fields/" + user.organizationId,
				{
					config: selectedFields,
				},
			);

			if (response.status === 200 || response.status === 201) {
				// Handle Image Upload
				if (image) {
					// Extract content type and base64 data
					const base64Data = image.split(",")[1]; // Remove "data:image/avif;base64,"
					const contentType = image.split(",")[0].split(":")[1].split(";")[0]; // Extract "image/avif"

					// Convert base64 to Blob
					const imageBlob = base64ToBlob(base64Data, contentType);

					// Prepare FormData
					const formData = new FormData();
					formData.append("image", imageBlob, "uploaded-image.avif");

					// API to upload the image
					const responseImageUpload = await axios.post(
						`/api/evaluationReports/upload-logo/${user.organizationId}`,
						formData,
						{
							headers: {
								"Content-Type": "multipart/form-data",
							},
						},
					);
					console.log("responseImageUpload", responseImageUpload);
					setConfigData(responseImageUpload?.data?.data);

					if (responseImageUpload.status !== 200) {
						throw new Error("Image Upload Failed");
					}
				} else setConfigData(response?.data?.data);
			}

			handleClose();
		} catch (error) {
			console.error("Error saving checkbox states or image:", error);
		}
	};
	function base64ToBlob(base64Data, contentType = "") {
		const byteCharacters = atob(base64Data);
		const byteArrays = [];

		for (let offset = 0; offset < byteCharacters.length; offset += 512) {
			const slice = byteCharacters.slice(offset, offset + 512);
			const byteNumbers = new Array(slice.length);
			for (let i = 0; i < slice.length; i++) {
				byteNumbers[i] = slice.charCodeAt(i);
			}
			const byteArray = new Uint8Array(byteNumbers);
			byteArrays.push(byteArray);
		}

		return new Blob(byteArrays, { type: contentType });
	}

	// Close Modal
	const handleClose = () => {
		setIsModalOpen(false);
	};

	if (!isOpen) {
		return null; // Conditional rendering without affecting hooks
	}

	return (
		<div className="fixed inset-0 bg-black bg-opacity-60 flex items-center justify-center z-50">
			<div className="bg-white rounded-2xl w-[40rem] p-10 relative shadow-2xl">
				{/* Close Button */}
				<IconButton
					onClick={handleClose}
					aria-label="Close modal"
					className="absolute top-4 right-4"
					sx={{
						color: "gray",
						"&:hover": { color: "black" },
						transition: "color 0.3s",
					}}
				>
					<CloseIcon />
				</IconButton>

				{/* Modal Content */}
				<div>
					<h2 className="text-xl font-semibold mb-4 text-center">
						Select the fields to hide in the report
					</h2>

					{/* Layout: Left (Checkboxes) and Right (Upload Logo) */}
					<div className="flex gap-6">
						{/* Left: Checkbox List */}
						<div className="flex flex-col space-y-2 w-1/2">
							<FormControlLabel
								control={
									<Checkbox
										checked={selectedFields.name}
										onChange={() => handleCheckboxChange("name")}
									/>
								}
								label="Name"
							/>
							<FormControlLabel
								control={
									<Checkbox
										checked={selectedFields.email}
										onChange={() => handleCheckboxChange("email")}
									/>
								}
								label="Email"
							/>
							<FormControlLabel
								control={
									<Checkbox
										checked={selectedFields.phone}
										onChange={() => handleCheckboxChange("phone")}
									/>
								}
								label="Phone"
							/>
							<FormControlLabel
								control={
									<Checkbox
										checked={selectedFields.trustScore}
										onChange={() => handleCheckboxChange("trustScore")}
									/>
								}
								label="Trust Score"
							/>
							<FormControlLabel
								control={
									<Checkbox
										checked={selectedFields.interviewDate}
										onChange={() => handleCheckboxChange("interviewDate")}
									/>
								}
								label="Interview Date"
							/>
							<FormControlLabel
								control={
									<Checkbox
										checked={selectedFields.interviewEndDate}
										onChange={() => handleCheckboxChange("interviewEndDate")}
									/>
								}
								label="Interview End Date"
							/>
							<FormControlLabel
								control={
									<Checkbox
										checked={selectedFields.candidatePhoto}
										onChange={() => handleCheckboxChange("candidatePhoto")}
									/>
								}
								label="Candidate Photo"
							/>
							<FormControlLabel
								control={
									<Checkbox
										checked={selectedFields.questionsAndEvaluation}
										onChange={() => handleCheckboxChange("questionsAndEvaluation")}
									/>
								}
								label="Questions and Evaluation"
							/>
							<FormControlLabel
								control={
									<Checkbox
										checked={selectedFields.cheatingScore}
										onChange={() => handleCheckboxChange("cheatingScore")}
									/>
								}
								label="Cheating Score"
							/>
						</div>

						{/* Right: Upload Logo */}
						<div className="flex flex-col items-center w-1/2 border-dashed border-2 border-gray-300 rounded-lg p-10">
							<p className="text-gray-500 text-sm text-center">Upload Logo</p>
							<div className="h-28 w-24 bg-gray-100 flex items-center justify-center rounded-md mt-2 relative">
								{/* Display uploaded image preview */}
								{image ? (
									<div className="relative">
										<img
											src={image}
											alt="Uploaded Logo"
											className="h-full w-full object-cover rounded-md"
										/>
										{/* Remove Image Button */}
										<IconButton
											onClick={handleRemoveImage}
											aria-label="Remove image"
											className="absolute top-0 right-0 p-1 bg-white rounded-full"
											sx={{
												color: "red",
												"&:hover": { color: "black" },
											}}
										>
											<CloseIcon />
										</IconButton>
									</div>
								) : (
									<span className="text-gray-400 text-xs">Logo</span>
								)}
							</div>

							{/* File input for image upload */}
							<input
								type="file"
								accept="image/*"
								onChange={handleImageChange}
								className="mt-4"
								style={{ display: "none" }}
								id="upload-logo"
							/>
							{/* Ensure clicking this button triggers the file input */}
							<label htmlFor="upload-logo">
								<Button
									variant="outlined"
									color="primary"
									className="mt-4"
									size="small"
									component="span" // Ensures the input is triggered
								>
									Upload
								</Button>
							</label>
						</div>
					</div>

					{/* Save Button */}
					<Button
						onClick={handleSave}
						variant="contained"
						color="primary"
						fullWidth
						className="mt-6"
					>
						Save
					</Button>
				</div>
			</div>
		</div>
	);
};

export default InterviewReportModal;
