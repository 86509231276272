import React from "react";
import { Button, Tooltip } from "@mui/material";

const EmailInviteControls = ({
	isCCInviteActive,
	setIsCCInviteActive,
	isEmailBodyVisible,
	toggleEmailBodyVisibility,
}) => {
	function toggleCcInvite() {
		setIsCCInviteActive((prevState) => !prevState);
	}

	return (
		<div className="mt-4 flex">
			<Tooltip
				arrow
				title="Add additional email recipients to receive the invite along with the primary recipient."
			>
				<Button
					variant="contained"
					className="bg-green-500 hover:bg-green-600"
					style={{ textTransform: "none" }}
					onClick={toggleCcInvite}
				>
					{!isCCInviteActive ? (
						<i className="fas fa-plus mr-1"></i>
					) : (
						<i className="fas fa-minus mr-1"></i>
					)}{" "}
					CC Invite To
				</Button>
			</Tooltip>
			<Tooltip arrow title="Toggle the visibility of the email body editor.">
				<Button
					className="mx-5 bg-green-500 hover:bg-green-600"
					variant="contained"
					style={{ textTransform: "none" }}
					onClick={toggleEmailBodyVisibility}
				>
					{!isEmailBodyVisible ? (
						<i className="fas fa-plus mr-1"></i>
					) : (
						<i className="fas fa-minus mr-1"></i>
					)}{" "}
					Edit Email Body
				</Button>
			</Tooltip>
		</div>
	);
};

export default EmailInviteControls;
