import { Tooltip } from "@mui/material";
import { useFormContext } from "react-hook-form";

export const TextAreaFormInput = ({ id, label, tooltipText, rows = 6 }) => {
	const { register } = useFormContext();
	return (
		<div className="mb-6">
			<label htmlFor={id} className="info-icon block text-sm font-medium text-gray-600">
				{label} &nbsp;
				<Tooltip title={tooltipText} arrow={true}>
					<i className="fas fa-info-circle"></i>
				</Tooltip>
			</label>
			<textarea
				id={id}
				rows={rows}
				required
				{...register(id)}
				className="mt-1 w-full resize-none resize-y whitespace-pre-line rounded-md border p-2 text-sm"
			></textarea>
		</div>
	);
};
