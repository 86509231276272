export const getCandidateIds = (candidates, selectedCandidate) => {
	try {
		const candidatesIds = new Set(selectedCandidate);
		candidates.forEach((report) => {
			if (candidatesIds.has(report._id) && report.interviewCompleted) {
				candidatesIds.delete(report._id);
			}
		});
		return Array.from(candidatesIds);
	} catch (error) {
		console.log("Error in getCandidateIds:", error);
		return [];
	}
};

export const getEmailBody = (openingData, organizationName) => {
	try {
		const proctoringRequirement1 =
			"Ensure that your device has a functioning webcam, as video capabilities are mandatory for the interview.";
		const proctoringRequirement2 =
			"Screen sharing functionality must be available and enabled for the duration of the interview.";
		let newRequirement = [];
		if (openingData?.proctoring) {
			newRequirement.push(proctoringRequirement1);
			newRequirement.push(proctoringRequirement2);
		}
		return {
			orgName: organizationName,
			openingTitle: openingData.title,
			requirementsIntro: "Requirements: ",
			requirementArray: [
				"Please use the Google Chrome browser.",
				"If you encounter any difficulties in completing the necessary steps, please email support@zinterview.ai",
				"If, for any reason, your browser hangs or crashes, simply refresh the browser or reopen the link provided above to resume from where you left off.",
				"Make sure you have good internet connection.",
				...newRequirement,
			],
		};
	} catch (error) {
		console.log("Error in getEmailBody: ", error);
		return {};
	}
};

