import { useEffect, useState } from "react";
import { availableLanguagesMap, pages, translations } from "../../utilities/constants";
import { changeCandidateInterivewLanguage } from "../../utilities/interviewReportsApi";
import { toast } from "react-toastify";

const InterviewLanguageSelection = ({
	openingData,
	interviewReporData,
	setCurrentPage,
	setInterviewReportData,
}) => {
	const [interviewLanguage, setInterviewLanguage] = useState("en");
	const [isLoading, setIsLoading] = useState(false);
	const nativeLanguageText = translations[interviewLanguage];
	const candidateChosenInterviewLanguage = interviewReporData?.candidateChosenInterviewLanguage;
	useEffect(() => {
		if (candidateChosenInterviewLanguage) {
			setInterviewLanguage(candidateChosenInterviewLanguage);
		}
	}, [candidateChosenInterviewLanguage]);
	const handleClick = async () => {
		try {
			setIsLoading(true);
			await changeCandidateInterivewLanguage(interviewReporData._id, interviewLanguage);
			setInterviewReportData((prev) => {
				return { ...prev, candidateChosenInterviewLanguage: interviewLanguage };
			});
			setIsLoading(false);
			setCurrentPage(pages.INTERVIEW);
		} catch (error) {
			console.log(error);
			toast.warn(error?.message);
			setIsLoading(false);
		}
	};

	return (
		<div className="bg-gray-50 min-h-screen flex items-center justify-center p-6">
			<div className="bg-white p-6 rounded-2xl shadow-md w-full max-w-md">
				<h1 className="text-2xl font-semibold text-gray-800 mb-4">Interview Language Preference</h1>

				<div className="mb-6">
					<label className="block text-gray-700 font-medium mb-2" htmlFor="language-preference">
						{nativeLanguageText ? `${nativeLanguageText} / ` : ""} Interview Language
					</label>
					<select
						id="language-preference"
						className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-1 focus:ring-purple-500"
						value={interviewLanguage}
						onChange={(e) => setInterviewLanguage(e.target.value)}
					>
						{Object.entries(availableLanguagesMap).map(([key, value]) => {
							return (
								<option key={key} value={key}>
									{value}&nbsp;
									{key !== "en" ? " / English" : ""}
								</option>
							);
						})}
					</select>
				</div>

				<button
					onClick={handleClick}
					disabled={isLoading}
					className="w-full cursor-pointer outline-none disabled:opacity-50 border-none bg-purple-600 text-white font-semibold py-3 rounded-lg hover:bg-purple-700 transition"
				>
					Continue
				</button>
			</div>
		</div>
	);
};

export default InterviewLanguageSelection;
