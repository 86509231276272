import {
	FormControl,
	IconButton,
	InputLabel,
	MenuItem,
	Tooltip,
	Select,
	Modal,
	TextField,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import FolderIcon from "@mui/icons-material/Folder";
import { toast } from "react-toastify";
import CloseIcon from "@mui/icons-material/Close";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { addFolderName, deleteFolderName, updateFolderName } from "../../utilities/folderApi";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
	removeFolderName,
	addFolderName as addFolderNameIntoState,
	editFolderName,
} from "../../features/auth/authSlice";
import { renderConfirmationDialog } from "../../components/confirmation/ConfirmationDialog";
import { z } from "zod";

const FolderSelector = ({ folderNames, setFolderNames, selectedFolder, setSelectedFolder }) => {
	const [openAddEditFolderNameModal, setOpenAddEditFolderNameModal] = useState(false);
	const closeAddEditFolderNameModalHandler = () => {
		setOpenAddEditFolderNameModal(false);
	};
	return (
		<Box className="rounded-lg mx-auto overflow-hidden mt-10 relative flex flex-col mb-5 bg-white p-4 shadow-md">
			<div className="flex justify-between items-center flex-wrap mt-1">
				<h3 className="text-lg font-semibold">Organize</h3>
				<FormControl size="small" className="w-1/3">
					<InputLabel id="demo-simple-select-label">Choose Folder</InputLabel>
					<Select
						value={selectedFolder?._id || ""}
						onChange={(e) => {
							const selected = folderNames.find((folder) => folder?._id === e.target.value);
							setSelectedFolder(selected || null);
						}}
						labelId="demo-simple-select-label"
						id="demo-simple-select"
						label="Choose folder"
					>
						{folderNames?.map((folder, index) => (
							<MenuItem key={index} value={folder?._id}>
								<div className="flex">
									<FolderIcon
										fontSize="extra-small"
										className="mr-1 mt-0.5 text-indigo-500"
									/>
									<p className="text-sm">{folder?.name}</p>
								</div>
							</MenuItem>
						))}
					</Select>
				</FormControl>
				<div className="flex justify-end">
					<Tooltip title={<span className="text-sm font-medium">Action</span>} arrow>
						<IconButton
							onClick={() => setOpenAddEditFolderNameModal(true)}
							className="bg-indigo-500 hover:bg-indigo-600 text-white transform transition-transform hover:scale-105"
						>
							<MoreHorizIcon fontSize="small" />
						</IconButton>
					</Tooltip>
				</div>
			</div>
			{openAddEditFolderNameModal && (
				<RenderActionModal
					onClose={closeAddEditFolderNameModalHandler}
					folderNames={folderNames}
					setFolderNames={setFolderNames}
				/>
			)}
		</Box>
	);
};

export const RenderActionModal = ({
	onClose,
	folderNames,
	setFolderNames,
	saveDirectlyToBackend = false,
}) => {
	const [newFolderName, setNewFolderName] = useState("");
	const [editFolderIndex, setEditFolderIndex] = useState(null);
	const [editId, setEditId] = useState(null);
	const [loading, setLoading] = useState(false);
	const { organizationId } = useParams();
	const { user } = useSelector((state) => state.auth);
	const dispatch = useDispatch();
	let idIndex = 1;

	const saveNewFolder = async (e) => {
		e.preventDefault();
		const name = newFolderName.trim();
		if (name.length < 3 || name.length > 30) {
			toast.warn("The folder name must be at least 3 characters long and no more than 30 characters.");
		} else if (folderNames.find((ele) => ele.name === name)) {
			toast.warn("The folder name already exists.");
		} else {
			if (saveDirectlyToBackend) {
				try {
					setLoading(true);
					const body = {
						folderName: name,
						organizationId: organizationId ? organizationId : user?.organizationId,
					};
					let data = await addFolderName(body);
					data = data.data;
					setFolderNames([data, ...folderNames]);
					if (
						(!organizationId && user?.organizationId) ||
						user?.organizationId === organizationId
					) {
						dispatch(addFolderNameIntoState(data));
					}
					setNewFolderName("");
					setEditFolderIndex(null);
					toast.success("Folder created successfully. You can now assign it to your opening.");
				} catch (error) {
					toast.error(error?.message || "Something went wrong");
				} finally {
					setLoading(false);
				}
			} else {
				setFolderNames([{ name, _id: idIndex }, ...folderNames]);
				idIndex++;
				setNewFolderName("");
				setEditFolderIndex(null);
			}
		}
	};

	const handleEditField = async () => {
		try {
			setLoading(true);
			const body = {
				folderName: newFolderName.trim(),
				organizationId: organizationId ? organizationId : user?.organizationId,
				folderId: editId,
			};
			await updateFolderName(body);
			if ((!organizationId && user?.organizationId) || user?.organizationId === organizationId) {
				dispatch(
					editFolderName({
						name: newFolderName.trim(),
						_id: editId,
					}),
				);
			}
			performUpdateOperation();
			toast.success("Folder name updated successfully.");
		} catch (error) {
			toast.error(error?.message || "Something went wrong");
		} finally {
			setLoading(false);
		}
	};

	const performUpdateOperation = () => {
		const updatedFolders = [...folderNames];
		updatedFolders[editFolderIndex] = { ...updatedFolders[editFolderIndex], name: newFolderName.trim() };
		setFolderNames(updatedFolders);
		setEditFolderIndex(null);
		setNewFolderName("");
	};

	const updateFolder = async (e) => {
		e.preventDefault();
		const name = newFolderName.trim();
		if (name.length < 3 || name.length > 20) {
			toast.warn("Folder name must be between 3 and 20 characters.");
		} else {
			let count = 0;
			for (let index = 0; index < folderNames.length; index++) {
				if (folderNames[index].name === name && index !== editFolderIndex) {
					count++;
				}
			}
			if (count > 0) {
				toast.warn("Folder name already exists.");
				return;
			}
			if (saveDirectlyToBackend) {
				handleEditField();
			} else {
				performUpdateOperation();
			}
		}
	};

	const handleDeleteField = async (index, id) => {
		try {
			setLoading(true);
			const body = {
				organizationId: organizationId ? organizationId : user?.organizationId,
				folderId: id,
			};
			await deleteFolderName(body);
			performDeleteOperation(index);
			if ((!organizationId && user?.organizationId) || user?.organizationId === organizationId) {
				dispatch(removeFolderName(id));
			}
			toast.success("Folder deleted successfully.");
		} catch (error) {
			toast.error(error?.message || "Something went wrong");
		} finally {
			setLoading(false);
		}
	};

	const performDeleteOperation = (index) => {
		const updatedFolders = folderNames.filter((_, i) => i !== index);
		setFolderNames(updatedFolders);
		if (index === editFolderIndex) {
			setEditFolderIndex(null);
			setNewFolderName("");
		}
	};

	const deleteFolder = async (index, _id) => {
		if (saveDirectlyToBackend) {
			renderConfirmationDialog(
				"Are you sure you want to delete this folder?",
				() => null,
				() => handleDeleteField(index, _id),
				true,
			);
		} else {
			performDeleteOperation(index);
		}
	};

	return (
		<Modal open={true} onClose={onClose}>
			<Box className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white p-6 rounded-lg shadow-lg w-96">
				<div className="flex items-center justify-between">
					<h4 className="flex-1 text-center font-semibold text-gray-700 mb-4">Manage Folders</h4>
					<Tooltip title="close" className="mb-4">
						<IconButton
							className="bg-gray-100 hover:bg-gray-200 cusor-pointer"
							disabled={loading}
							onClick={onClose}
						>
							<CloseIcon className="text-sm" />
						</IconButton>
					</Tooltip>
				</div>
				<form onSubmit={editFolderIndex !== null ? updateFolder : saveNewFolder}>
					<div className="flex justify-center items-center">
						<TextField
							type="text"
							value={newFolderName}
							onChange={(e) => setNewFolderName(e.target.value)}
							className="w-4/5 p-2 border rounded-lg focus:ring focus:ring-blue-300 border-none"
							placeholder="Enter folder name"
							size="small"
							autoFocus
							autoComplete="off"
							disabled={loading}
						/>
						<button
							onClick={editFolderIndex !== null ? updateFolder : saveNewFolder}
							className={`${loading ? "bg-blue-400" : "bg-blue-600"} text-white h-10 px-4 py-2 rounded-md ${loading ? "hover:bg-blue-500" : "hover:bg-blue-700"} border-none cursor-pointer`}
							disabled={loading}
						>
							{editFolderIndex !== null ? "Update" : "Add"}
						</button>
					</div>
				</form>
				<div
					style={{
						borderTop: "1px solid #E5E7EB",
					}}
				>
					{folderNames?.length > 0 ? (
						<ul className="max-h-60 overflow-y-auto mt-2 p-2">
							{folderNames?.map((folder, index) => (
								<li key={index} className="flex justify-between items-center border-b py-2">
									<div className="flex items-center">
										<FolderIcon className="mr-2 text-indigo-500" />
										<p className="text-sm">{folder?.name}</p>
									</div>
									<div>
										<Tooltip title="Edit">
											<IconButton
												onClick={() => {
													setEditFolderIndex(index);
													setNewFolderName(folder.name);
													setEditId(folder?._id);
												}}
												className="text-blue-500 hover:text-blue-700"
												disabled={loading}
											>
												<EditIcon className="text-sm" />
											</IconButton>
										</Tooltip>
										<Tooltip title="Delete">
											<IconButton
												onClick={() => deleteFolder(index, folder?._id)}
												className="text-red-500 hover:text-red-700"
												disabled={loading}
											>
												<DeleteIcon className="text-sm" />
											</IconButton>
										</Tooltip>
									</div>
								</li>
							))}
						</ul>
					) : (
						<p className="text-center text-gray-500 mt-5 text-xs">No folders available!</p>
					)}
				</div>
			</Box>
		</Modal>
	);
};

export const getSelectedFolderFromLocalStorage = (folderNames, defaultFolder) => {
	try {
		let selectedFolder = localStorage.getItem("selectedFolder");
		selectedFolder = selectedFolder ? JSON.parse(selectedFolder) : {};
		const length = Object.keys(selectedFolder).length;
		if (length === 0) {
			return defaultFolder;
		} else if (
			length === 2 &&
			selectedFolder.hasOwnProperty("name") &&
			selectedFolder.hasOwnProperty("_id") &&
			folderNames.find((item) => item?._id === selectedFolder?._id)
		) {
			return selectedFolder;
		} else {
			return defaultFolder;
		}
	} catch {
		return defaultFolder;
	}
};

export const calculateAddFolderData = (actualData, updatedData) => {
	try {
		let ans = [];
		for (let index = 0; index < updatedData.length; index++) {
			const element = updatedData[index];
			const updatedElement = actualData.find((item) => item?._id === element?._id);
			if (!updatedElement) {
				ans.push(element.name);
			}
		}
		return ans;
	} catch {
		return [];
	}
};

export const calculateEditFolderData = (actualData, updatedData) => {
	try {
		let ans = [];
		for (let index = 0; index < actualData.length; index++) {
			const element = actualData[index];
			const updatedElement = updatedData.find((item) => item?._id === element?._id);
			if (updatedElement && updatedElement?.name !== element?.name) {
				ans.push(updatedElement);
			}
		}
		return ans;
	} catch {
		return [];
	}
};

export const calculateDeleteFolderData = (actualData, updatedData) => {
	try {
		let ans = [];
		for (let index = 0; index < actualData.length; index++) {
			const element = actualData[index];
			const updatedElement = updatedData.find((item) => item?._id === element?._id);
			if (!updatedElement) {
				ans.push(element._id);
			}
		}
		return ans;
	} catch {
		return [];
	}
};

export const folderSchema = z.object({
	name: z.string(),
	_id: z.optional(),
});

export default FolderSelector;
