import React, { useState, useEffect, useRef, useMemo } from "react";
import { Manager } from "socket.io-client";
import poster from "./../assets/zinterview-logo-black.png";
import { isMobile, osName } from "react-device-detect";
import CryptoJS from "crypto-js";
import ChatGpt from "../ChatGpt";
import CodeEditor from "../components/CodeEditor";
import "./InterviewStyles.css";
import { pages } from "../utilities/constants";
import {
	Toolbar,
	Typography,
	Box,
	Button,
	CssBaseline,
	Divider,
	Snackbar,
	CircularProgress,
	LinearProgress,
	ThemeProvider,
	IconButton,
	Stack,
	Paper,
	MenuItem,
	TextField,
	Checkbox,
	FormControlLabel,
	Link,
	Tooltip,
} from "@mui/material";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { fetchOpeningByIdForInterview } from "../utilities/openingsApi";
import {
	fetchInterviewReportByIdForInterview,
	endInterviewWithGivenId,
	updateActiveSession,
	updateSecondDevice,
	updateCandidatePostInterview,
	verifyToken,
} from "../utilities/interviewReportsApi";
import { useTheme } from "@emotion/react";
import CloseIcon from "@mui/icons-material/Close";
import WaveSurfer from "wavesurfer.js";
import RecordPlugin from "wavesurfer.js/dist/plugins/record.esm.js";
import MicRecorder from "mic-recorder-to-mp3";
import ErrorIcon from "@mui/icons-material/Error";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { updateUserSkillsForInterview } from "../utilities/interviewReportsApi";
import { QRCodeSVG } from "qrcode.react";
import VideoTiles from "../components/videoTiles/VideoTiles";
import { getOrganizationName } from "../features/organization/organizationSlice";
import { useSelector, useDispatch } from "react-redux";
import { differenceInMinutes, formatISO, parseISO } from "date-fns";
import { getFormattedDateTime4 } from "../utilities/utilityFunctions";
import NetworkLost from "../components/Interview/NetworkLost";
import DifferentDevice from "../components/Interview/DifferentDevice";
import MultipleTabs from "../components/Interview/MultipleTabs";
import InvalidResumeToken from "../components/Interview/InvalidResumeToken";
import ResumePrompt from "../components/Interview/ResumePrompt";
import JoinedOnMobile from "../components/Interview/JoinedOnMobile";
import { clientLogger as clientLoggerApi } from "../utilities/loggingApi";
import CorrectionForm from "../components/Interview/CorrectionForm";
import Welcome from "../components/Interview/Welcome";
import Loading from "../components/Interview/Loading";
import MobileNotAllowed from "../components/Interview/MobileNotAllowed";
import Cancelled from "../components/Interview/Cancelled";
import MicModal from "../components/Interview/MediaModals/Mic";
import CamModal from "../components/Interview/MediaModals/Cam";
import ScreenModal from "../components/Interview/MediaModals/Screen";
import CanLeave from "../components/Interview/CanLeave";
import { checkTranscript, handleUpload } from "../Common";
import { CandidatePhotoCapture } from "./CandidatePhotoCapture";
import { DeviceSelector } from "../components/Interview/DeviceSelector";
import Modal403 from "../components/Interview/Modal403";
import { fetchDefaultSupNum } from "../utilities/globalVarsApi";
import InterviewTimeout from "../components/Interview/InterviewTimeout";
import IdentityVerification from "../components/Interview/IdentityVerification";
import { LogRocketInterviewSessionController } from "../analytics/logrocket-interview-session-controller";
import CheatingWarning from "../components/Interview/CheatingWarning";
import InterviewLanguageSelection from "../components/Interview/InterviewLanguage";

const SECRET_KEY = "s7erHR7ehS";

const transcriptionTexts = [
	"Honesty is the best policy",
	"An apple a day keeps the doctor away",
	"The early bird catches the worm",
	"Every cloud has a silver lining",
];
const randomTranscriptionTextIdx = Math.floor(Math.random() * transcriptionTexts.length);

function Interview() {
	const theme = useTheme();
	const params = useParams();
	const navigate = useNavigate();
	const location = useLocation();
	const alreadyHasAnActiveTab = localStorage.getItem("alreadyHasAnActiveTab");
	const disableAvatar = localStorage.getItem("disableAvatar");
	const [currentPage, setCurrentPage] = useState(pages.LOADING);
	const SocketIORef = useRef(null);
	const [openingData, setOpeningData] = useState(null);
	const [interviewReportData, setInterviewReportData] = useState(null);
	const [interviewLoading, setInterviewLoading] = useState(true);
	const [loading, setLoading] = useState(false);
	const editorRef = useRef(null);
	const monacoRef = useRef(null);
	const [snackbarOpen, setSnackbarOpen] = useState(false);
	const [codeExample, setCodeExample] = useState("");
	const [interviewEnded, setInterviewEnded] = useState(false);
	const [showIDE, setShowIDE] = useState(false);
	const [text2SpeechStatus, setText2SpeechStatus] = useState(false);
	const [questionIndex, setQuestionIndex] = useState(0);
	const [currentQ, setCurrentQ] = useState(0);
	const [recentQIndex, setRecentQIndex] = useState(0);
	const [micGranted, setMicGranted] = useState(null);
	const [cameraGranted, setCameraGranted] = useState(null);
	const [screenShared, setScreenShared] = useState(null);
	const [micStream, setMicStream] = useState(null);
	const [videoStream, setVideoStream] = useState(null);
	const [screenStream, setScreenStream] = useState(null);
	const [snackbarMessage, setSnackbarMessage] = useState("");
	const [isRecording, setIsRecording] = useState(false);
	const [recorder, setRecorder] = useState(new MicRecorder({ bitRate: 128 }));
	const [player, setPlayer] = useState(null);
	const [audioDevices, setAudioDevices] = useState([]);
	const [videoDevices, setVideoDevices] = useState([]);
	const [selectedAudioDevice, setSelectedAudioDevice] = useState("");
	const [selectedVideoDevice, setSelectedVideoDevice] = useState("");
	const [skillsGroup, setSkillsGroup] = useState([]);
	const [animate, setAnimate] = useState(false);
	const [isFetchingOpeningData, setIsFetchingOpeningData] = useState(false);
	const [isUpdatingSkills, setIsUpdatingSkills] = useState(false);
	const [isActiveSession, setIsActiveSession] = useState(false);
	const [userEmail, setUserEmail] = useState("");
	const [savingUserDeets, setSavingUserDeets] = useState(false);
	const [showQrCode, setShowQrCode] = useState(false);
	const [secondDeviceAdded, setSecondDeviceAdded] = useState(false);
	const [deviceLimitReached, setDeviceLimitReached] = useState(false);
	const [qrUrl, setQrUrl] = useState("");
	const [orgName, setOrgName] = useState("");
	const [orgLogoUrl, setOrgLogoUrl] = useState("");
	const dispatch = useDispatch();
	const { organization } = useSelector((state) => state.organization);
	const [eventName, setEventName] = useState("");
	const [isMobileInterviewAllowed, setIsMobileInterviewAllowed] = useState("false");
	const isRecordingEnabledRef = useRef(false);
	const [chimeMeetingStartedForSecondDevice, setChimeMeetingStartedForSecondDevice] = useState(false);
	const [isPositionAccurate, setIsPositionAccurate] = useState(false);
	const [meetingEndedForSecondDevice, setMeetingEndedForSecondDevice] = useState(false);
	const [avatarMode, setAvatarMode] = useState(false);
	const [audioState, setAudioState] = useState(null);
	const audioStateRef = useRef(null);
	const [lipSync, setLipSync] = useState(null);
	const [networkLost, setNetworkLost] = useState(false);
	const skipEPreventDefaultRef = useRef(false);
	const [loadingForLost, setLoadingForLost] = useState(false);
	const [retryCountdown, setRetryCountdown] = useState(15);
	const [screenRetryCountdown, setScreenRetryCountdown] = useState(15);
	const [autoRetried, setAutoRetried] = useState(false);
	const activeSessionRef = useRef(false);
	const screenShareRef = useRef(null);
	const micStreamRef = useRef(null);
	const videoStreamRef = useRef(null);
	const [openingStatus, setOpeningStatus] = useState(true);
	const [scheduledTime, setScheduledTime] = useState("");
	const [userLocalTime, setUserLocalTime] = useState("");
	const [timeTillInterview, setTimeTillInterview] = useState("");
	const isResumeUrl = params.resumeToken;
	const [chimeStarted, setChimeStarted] = useState(false);
	const [currentAudioDeviceId, setCurrentAudioDeviceId] = useState("");
	const [temporaryReportData, setTemporaryReportData] = useState(null);
	const [temporaryOpeningData, setTemporaryOpeningData] = useState(null);
	const [isResumeRequired, setIsResumeRequired] = useState(false);
	const createdByAdmin = useRef(false);
	const [firstOpenOnWeb, setFirstOpenOnWeb] = useState(false);
	const [language, setLanguage] = useState("js");
	const [showMicModal, setShowMicModal] = useState(false);
	const [showCamModal, setShowCamModal] = useState(false);
	const [showModal403, setShowModal403] = useState(false);
	const [showScreenModal, setShowScreenModal] = useState(false);
	const [showDropdown, setShowDropdown] = useState(false);
	const dropdownRef = useRef(null);
	const [canClickContinue2, setCanClickContinue2] = useState(false);
	const [canPassDeviceTest, setCanPassDeviceTest] = useState(false);
	const [transcriptionTestLoader, setTranscriptionTestLoader] = useState(false);
	const [testTranscriptionText, setTestTranscriptionText] = useState(
		"Your transcribed text will appear here...",
	);
	const [retryTranscriptionTest, setRetryTranscriptionTest] = useState(false);
	const [transcriptionTestFirstAttempt, setTranscriptionTestFirstAttempt] = useState(true);
	const testMp3FileRef = useRef(null);
	const [moveToSecondDeviceSetup, setMoveToSecondDeviceSetup] = useState(false);
	const [showAnimForTranscript, setShowAnimForTranscript] = useState(true);
	const [failedTranscriptionTestCount, setFailedTranscriptionTestCount] = useState(0);
	const [fallbackDeviceTest, setFallbackDeviceTest] = useState(false);
	const [showDefaultSupportNumber, setShowDefaultSupportNumber] = useState(false);
	const [failedFaceDetectCount, setFailedFaceDetectCount] = useState(0);
	const autoSkipQTimeoutRef = useRef();
	const autoSkipQIntervalRef = useRef();
	const [remainingSecondsForAutoSkipQ, setRemainingSecondsForAutoSkipQ] = useState(0);
	const languages = [
		"javascript",
		"typescript",
		"html",
		"css",
		"sql",
		"python",
		"java",
		"cpp",
		"csharp",
		"c",
		"others",
	];
	const extentions = {
		javascript: "js",
		typescript: "ts",
		html: "html",
		css: "css",
		sql: "sql",
		python: "py",
		java: "java",
		cpp: "cpp",
		csharp: "cs",
		c: "c",
		others: "txt",
	};

	const criteriaMap = {
		1: "All skills are required from this group",
		2: "At least one skill is mandatory from this group",
		3: "No skill is mandatory from this group, but they're good to have.",
	};
	const errorMap = {
		1: "All the mentioned skills are required",
		2: "Please pick at least one skill from this group",
		3: "No skills from this are required",
	};

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
				setShowDropdown(false);
			}
		};

		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [dropdownRef]);

	const action = (
		<React.Fragment>
			<IconButton size="small" aria-label="close" color="inherit" onClick={handleSnackbarClose}>
				<CloseIcon fontSize="small" />
			</IconButton>
		</React.Fragment>
	);

	useEffect(() => {
		audioStateRef.current =
			audioState instanceof Audio && typeof audioState?.pause === "function" ? audioState : null;
	}, [audioState]);

	const clearAutoSkipTimers = () => {
		if (autoSkipQIntervalRef.current) {
			clearInterval(autoSkipQIntervalRef.current);
			autoSkipQIntervalRef.current = null;
		}
		if (autoSkipQTimeoutRef.current) {
			clearTimeout(autoSkipQTimeoutRef.current);
			autoSkipQTimeoutRef.current = null;
		}
	};

	const SocketIO = () => {
		const sendClientData = (isReconnection) => {
			const clientData = {
				interviewReportId: params.interviewReportId,
				eventName: "Socket.IO",
				device: "primary",
			};
			isReconnection
				? SocketIORef.current.emit("reconnection", clientData)
				: SocketIORef.current.emit("normal", clientData);
		};

		const connectSocket = () => {
			const protocol = window.location.protocol === "https:" ? "wss:" : "ws:";
			const host = window.location.hostname === "localhost" ? "localhost:5002" : window.location.host;
			const path = window.location.hostname === "localhost" ? "" : "ws";
			const socketUrl = `${protocol}//${host}/${path}`;
			clientLogger(`Socket.IO Client: URL: ${socketUrl}`);

			const manager = new Manager(socketUrl, {
				transports: ["websocket"],
			});

			const managerEvents = [
				"error",
				"ping",
				"reconnect",
				"reconnect_attempt",
				"reconnect_error",
				"reconnect_failed",
			];
			managerEvents.forEach((eventName) => {
				manager.on(eventName, (...args) => {
					clientLogger(`Socket.IO Manager: ${eventName} event`, { args });
					if (eventName === "reconnect") {
						sendClientData(true);
					}
				});
			});

			SocketIORef.current = manager.socket("/");

			const socketEvents = [
				"connect",
				"connect_error",
				"disconnect",
				"dataStatus",
				"sd_status",
				"sd_position_status",
			];

			socketEvents.forEach((eventName) => {
				SocketIORef.current.on(eventName, async (...args) => {
					clientLogger(`Socket.IO Client: ${eventName} event`, { args });
					if (eventName === "connect") {
						sendClientData(false);
					} else if (eventName === "sd_status") {
						const value = args[0]?.status ?? false;
						setSecondDeviceAdded(value);
						await updateSecondDevice(value.toString(), "sd", params.interviewReportId);
						if (!value) {
							setIsPositionAccurate(false);
							await updateSecondDevice("false", "pos", params.interviewReportId);
							audioStateRef.current?.pause?.();
							clearAutoSkipTimers();
							setRemainingSecondsForAutoSkipQ(0);
						}
					} else if (eventName === "sd_position_status") {
						const value = args[0]?.status ?? false;
						setIsPositionAccurate(value);
						await updateSecondDevice("true", "pos", params.interviewReportId);
						if (value) {
							if (!secondDeviceAdded) {
								setSecondDeviceAdded(true);
								await updateSecondDevice("true", "sd", params.interviewReportId);
							}
							if (currentPage !== pages.INTERVIEW) {
								setSnackbarMessage("Successfully detected position. Please continue.");
								setSnackbarOpen(true);
							}
						}
					} else if (eventName === "disconnect") {
						const [reason, details] = args;
						clientLogger(`Socket.IO Client: Disconnected. Reason: ${reason}`, { details });
						if (reason === "io server disconnect") {
							clientLogger("Socket.IO Client: io server disconnect, connecting manually");
							SocketIORef.current.connect();
						}
					}
				});
			});
		};
		connectSocket();
	};

	const SecondDeviceSocketIO = () => {
		const connectSocket = () => {
			const protocol = window.location.protocol === "https:" ? "wss:" : "ws:";
			const host = window.location.hostname === "localhost" ? "localhost:5002" : window.location.host;
			const path = window.location.hostname === "localhost" ? "" : "ws";
			const socketUrl = `${protocol}//${host}/${path}`;
			clientLogger(`SecondDeviceSocketIOClient: URL: ${socketUrl}`);

			const manager = new Manager(socketUrl, {
				transports: ["websocket"],
			});

			const managerEvents = [
				"error",
				"ping",
				"reconnect",
				"reconnect_attempt",
				"reconnect_error",
				"reconnect_failed",
			];
			managerEvents.forEach((eventName) => {
				manager.on(eventName, (...args) => {
					clientLogger(`SecondDeviceSocketIO Manager: ${eventName} event`, { args });
					if (eventName === "reconnect") {
						SocketIORef.current.emit("reconnection_sd", {
							interviewReportId: params.interviewReportId,
							device: "secondary",
						});
						setTimeout(() => {
							window.location.reload();
						}, 1500);
					}
				});
			});

			SocketIORef.current = manager.socket("/");

			const socketEvents = [
				"connect",
				"connect_error",
				"disconnect",
				"dataStatus",
				"sd_status",
				"sd_position_status",
			];

			socketEvents.forEach((eventName) => {
				SocketIORef.current.on(eventName, (...args) => {
					clientLogger(`SecondDeviceSocketIO Client: ${eventName} event`, { args });

					if (eventName === "connect") {
						SocketIORef.current.emit("normal_sd", {
							interviewReportId: params.interviewReportId,
							device: "secondary",
						});
					} else if (eventName === "disconnect") {
						const [reason, details] = args;
						clientLogger(`SecondDeviceSocketIO Client: Disconnected. Reason: ${reason}`, {
							details,
						});
						if (reason === "io server disconnect") {
							clientLogger(
								"SecondDeviceSocketIO Client: io server disconnect, connecting manually",
							);
							SocketIORef.current.connect();
						}
					}
				});
			});
		};
		connectSocket();
	};

	useEffect(() => {
		if (isMobile && isPositionAccurate && SocketIORef.current) {
			SocketIORef.current.emit("position_sd", {
				interviewReportId: params.interviewReportId,
				device: "secondary",
			});
		}
	}, [isPositionAccurate]);

	const interviewReportId = params.interviewReportId;

	useEffect(() => {
		currentAudioDeviceId &&
			setRecorder(new MicRecorder({ bitRate: 128, deviceId: currentAudioDeviceId }));
	}, [currentAudioDeviceId]);

	useEffect(() => {
		function clientLogger(message, data = {}) {
			const clientLoggerData = {
				logStreamName: interviewReportId,
				...data,
			};
			clientLoggerApi(message, clientLoggerData);
		}

		function userSystemDetails() {
			try {
				const systemDetails = {};
				if (window && window.navigator) {
					systemDetails["device-memory"] = navigator.deviceMemory;
					systemDetails["userAgent"] = navigator.userAgent;
				}
				let connection =
					navigator.connection || navigator.mozConnection || navigator.webkitConnection;
				systemDetails["downlink-speed"] = connection?.downlink ? `${connection?.downlink}MB/s` : "";
				systemDetails["effectiveType"] = connection?.effectiveType;
				clientLogger("User system details", systemDetails);
			} catch (e) {
				clientLogger("Error while fetching system details");
			}
		}
		userSystemDetails();
	}, [interviewReportId]);

	useEffect(() => {
		function clientLogger(message, data = {}) {
			const clientLoggerData = {
				logStreamName: interviewReportId,
				...data,
			};
			clientLoggerApi(message, clientLoggerData);
		}

		function refreshEventHandler(e) {
			if ((e.ctrlKey || e.metaKey) && e.key?.toLowerCase() === "r") {
				clientLogger("Ctrl+R key combination is pressed. User tried to refresh the page.");
			}
		}

		function changeTabEventHandler(e) {
			if (document.visibilityState === "hidden") {
				clientLogger("Interview tab is not in focus. Candidate moved to a different tab.");
			} else {
				clientLogger("Interview tab is in focus. Candidate came back to the zinterview page.");
			}
		}

		window.addEventListener("keydown", refreshEventHandler);
		document.addEventListener("visibilitychange", changeTabEventHandler);
		return () => {
			window.removeEventListener("keydown", refreshEventHandler);
			document.removeEventListener("visibilitychange", changeTabEventHandler);
		};
	}, [interviewReportId]);

	function clientLogger(message, data = {}) {
		const clientLoggerData = {
			logStreamName: params.interviewReportId,
			...data,
		};
		clientLoggerApi(message, clientLoggerData);
	}

	useEffect(() => {
		activeSessionRef.current = isActiveSession;
	}, [isActiveSession]);

	// more readable
	const perms = async (openingData, interviewReportData) => {
		const shouldCheckPermissions = () => {
			const isProctoring = openingData?.proctoring;
			const needsSecondaryCamera = openingData?.isSecondaryCameraRequired;
			const isSessionActive = interviewReportData?.activeSession;
			const hasSecondDevice = interviewReportData?.secondDevice;

			if (isProctoring && needsSecondaryCamera) {
				return !isSessionActive || !hasSecondDevice;
			} else if (isProctoring) {
				return !isSessionActive;
			} else if (needsSecondaryCamera) {
				return isMobile ? !hasSecondDevice : !isSessionActive;
			} else {
				return !isSessionActive;
			}
		};

		if (shouldCheckPermissions()) {
			if (!micStream) {
				await requestMicPermission();
			}
			if (
				(openingData?.proctoring
					? openingData?.proctoring
					: isMobile
						? openingData?.isSecondaryCameraRequired
						: false) &&
				!videoStream
			) {
				await requestCameraPermission();
			}
			if (!isMobile && openingData?.proctoring) {
				if (!screenStream) {
					await requestScreenPermission();
				}
			}
		}

		setCanClickContinue2(true);
	};

	const updateUrl = () => {
		const currentUrl = window.location.href;
		const newUrl = currentUrl.replace(`/${params.resumeToken}`, "");
		window.history.replaceState(null, "", newUrl);
	};

	const tokenVerification = async () => {
		const resp = await verifyToken(params.resumeToken, params.interviewReportId);
		if (resp.message === "verified") {
			clientLogger("Valid resume URL used. Token verified.");
			updateUrl();
			return true;
		} else {
			clientLogger("Expired resume URL used. Invalid token.");
			setCurrentPage(pages.INVALID_TOKEN);
			return false;
		}
	};

	const openDatabase = (finalBeaconCallDB) => {
		return new Promise((resolve, reject) => {
			const request = indexedDB.open(finalBeaconCallDB ?? "canDB", 1);

			request.onerror = function (event) {
				reject("Database error: " + event.target.errorCode);
			};

			request.onsuccess = function (event) {
				resolve(event.target.result);
			};

			// Create the object store if needed
			request.onupgradeneeded = function (event) {
				let db = event.target.result;
				db.createObjectStore(finalBeaconCallDB ?? "canDB", {
					keyPath: "id",
					// autoIncrement: true,
				});
			};
		});
	};

	const storeOrUpdateActiveSessionInfo = async () => {
		const db = await openDatabase();
		const transaction = db.transaction(["canDB"], "readwrite");
		const objectStore = transaction.objectStore("canDB");

		const activeSessionInfo = {
			id: 1,
			openingId: params.openingId,
			interviewReportId: params.interviewReportId,
		};

		// Since we're setting the id explicitly, put will either update or insert
		const request = objectStore.put(activeSessionInfo);
		request.onsuccess = function (event) {
			localStorage.setItem("alreadyHasAnActiveTab", "true");
		};
		request.onerror = function (event) {
			clientLogger("Error upserting active session info:", { errorCode: event.target.errorCode });
		};
	};

	const checkIfSessionExists = async (openingData, interviewReportData) => {
		const db = await openDatabase();
		const transaction = db.transaction(["canDB"], "readonly");
		const objectStore = transaction.objectStore("canDB");
		const request = objectStore.get(1);

		request.onsuccess = async function (event) {
			const activeSessionInfo = event.target.result;
			if (activeSessionInfo) {
				if (alreadyHasAnActiveTab === "true") {
					if (isResumeUrl) {
						const resp = await tokenVerification();
						if (resp) {
							clientLogger(
								"No prior alreadyHasAnActiveTab found in LocalDB, resume URL used to set new data.",
							);
							setIsActiveSession(true);
							SocketIO();
							await storeOrUpdateActiveSessionInfo();
							await updateActiveSession(
								"true",
								params.interviewReportId,
								openingData.title,
								orgName,
								`${window.origin}/admin/proctor/${params.interviewReportId}`,
								openingData.emailRecipients,
								isMobile,
								osName,
							);
							setCurrentPage(pages.CHECK_PERMISSIONS);
							await perms(openingData, interviewReportData);
						}
						return;
					}
					setCurrentPage(pages.MULTIPLE_TABS);
					return;
				} else {
					setIsActiveSession(true);
					SocketIO();
					if (isResumeUrl) {
						updateUrl();
					}
					await updateActiveSession(
						"true",
						params.interviewReportId,
						openingData.title,
						orgName,
						`${window.origin}/admin/proctor/${params.interviewReportId}`,
						openingData.emailRecipients,
						isMobile,
						osName,
					);
					await storeOrUpdateActiveSessionInfo();
					await perms(openingData, interviewReportData);
				}
				// }
			} else {
				if (isResumeUrl) {
					const resp = await tokenVerification();
					if (resp) {
						clientLogger("No prior data found in LocalDB, resume URL used to set new data.");
						setIsActiveSession(true);
						SocketIO();
						await storeOrUpdateActiveSessionInfo();
						await updateActiveSession(
							"true",
							params.interviewReportId,
							openingData.title,
							orgName,
							`${window.origin}/admin/proctor/${params.interviewReportId}`,
							openingData.emailRecipients,
							isMobile,
							osName,
						);
						currentPage !== pages.CHECK_PERMISSIONS && setCurrentPage(pages.CHECK_PERMISSIONS);
						await perms(openingData, interviewReportData);
					}
				} else {
					clientLogger(
						"No session found in local storage. Redirecting to 'use previous device' page.",
					);
					setCurrentPage(pages.USE_PREVIOUS_DEVICE);
				}
			}
		};
		request.onerror = async function (event) {
			clientLogger("Error during localDB transaction inside checkIfSessionExists", {
				errorCode: event?.target?.errorCode,
			});
		};
	};

	const clearSessionStorage = async () => {
		const db = await openDatabase(); // Assuming openDatabase() opens the correct IndexedDB database
		const transaction = db.transaction(["canDB"], "readwrite");
		const objectStore = transaction.objectStore("canDB");
		const clearRequest = objectStore.clear(); // Clears all entries in the 'canDB' object store

		clearRequest.onsuccess = function () {
			addInterviewCompletedFlag();
		};
		clearRequest.onerror = function (event) {
			console.error("Error clearing session storage:", event.target.errorCode);
		};
	};

	const addInterviewCompletedFlag = async () => {
		const db = await openDatabase();
		const transaction = db.transaction(["canDB"], "readwrite");
		const objectStore = transaction.objectStore("canDB");

		const activeSessionInfo = {
			id: 1,
			interviewCompleted: true,
		};

		// Since we're setting the id explicitly, put will either update or insert
		const request = objectStore.put(activeSessionInfo);
		request.onsuccess = function (event) {
			console.log("Active session info upserted successfully", event.target.result);
		};
		request.onerror = function (event) {
			console.error("Error upserting active session info:", event.target.errorCode);
		};
	};

	useEffect(() => {
		networkLost && setCurrentPage(pages.NETWORK_LOST);
		if (currentPage === pages.NETWORK_LOST) {
			skipEPreventDefaultRef.current = true;
			!networkLost && window.location.reload();
		}
	}, [networkLost, currentPage]);

	const ping = async (finalBeaconCall) => {
		// const response = await fetch('https://www.google.com');
		try {
			// Use a website that supports CORS and is designed for testing
			// const response = await fetch("https://httpbin.org/get");
			const response = await fetch("/api/utility/ping");
			if (response.ok) {
				!finalBeaconCall && setNetworkLost(false);
				return false;
			} else {
				console.log(`Status: ${response.status} - Issue detected`);
				!finalBeaconCall && setNetworkLost(true);
				return true;
			}
		} catch (error) {
			// If there's a network error or other fetch issues, log the error
			console.log(`Fetch error: ${error.message} - Offline or CORS issue`);
			!finalBeaconCall && setNetworkLost(true);
			return true;
		}
	};

	useEffect(() => {
		let retryTimeout;
		let countdownInterval;

		if (autoRetried) {
			clearTimeout(retryTimeout);
			clearInterval(countdownInterval);
		}

		if (networkLost && !autoRetried) {
			// Start countdown
			countdownInterval = setInterval(() => {
				setRetryCountdown((prevCountdown) => {
					if (prevCountdown > 1) {
						return prevCountdown - 1;
					} else {
						clearInterval(countdownInterval);
						return 15; // Reset countdown for the next round
					}
				});
			}, 1000);

			// Set a timeout to retry ping after 15 seconds
			retryTimeout = setTimeout(async () => {
				setLoadingForLost(true);
				setAutoRetried(true);
				const isNetworkLost = await ping();
				setNetworkLost(isNetworkLost);
				if (!isNetworkLost) {
					clearInterval(countdownInterval); // Clear countdown if network is back
				}
				isNetworkLost && setLoadingForLost(false);
			}, 15000);
		}

		// Cleanup timeout and interval on component unmount or network found
		return () => {
			clearTimeout(retryTimeout);
			clearInterval(countdownInterval);
		};
	}, [networkLost, autoRetried]);

	function handleSnackbarClose() {
		setSnackbarOpen(false);
	}

	useMemo(() => {
		if (organization?.organizationName) {
			const { organizationName = "" } = organization;
			if (organizationName) {
				setOrgLogoUrl(organization?.orgLogoUrl);
				setOrgName(organizationName);
				clientLogger(`Organization of the current opening is ${organizationName}`);
			}
		}
	}, [organization]);

	useEffect(() => {
		if (chimeMeetingStartedForSecondDevice) {
			const resp = updateSecondDevice("true", "sd", params.interviewReportId);
			setSecondDeviceAdded(resp);
			SecondDeviceSocketIO();
		}
	}, [chimeMeetingStartedForSecondDevice]);

	useEffect(() => {
		const fetchDefSupNum = async () => {
			try {
				const res = await fetchDefaultSupNum();
				setShowDefaultSupportNumber(res?.value);
			} catch (error) {
				console.error("Error fetching support number:", error.message);
				setShowDefaultSupportNumber(false);
				clientLogger("Error fetching support number", { error: error.message });
			}
		};

		fetchDefSupNum();
	}, []);

	useEffect(() => {
		const getInterviewReportById = async () => {
			if (params.interviewReportId) {
				const interviewReportData = await fetchInterviewReportByIdForInterview(
					params.interviewReportId,
				);
				setIsPositionAccurate(interviewReportData?.isPositionAccurate);
				setTemporaryReportData(interviewReportData);
				createdByAdmin.current = interviewReportData?.createdByAdmin;
				if (interviewReportData?.interviewCompleted) {
					setInterviewEnded(true);
					clearSessionStorage();
					setCurrentPage(pages.CHECK_PERMISSIONS);
					return;
				} else if (interviewReportData?.cancelled) {
					clearSessionStorage();
					setCurrentPage(pages.CANCELLED);
					return;
				} else {
					const openingData = await fetchOpeningByIdForInterview(params.openingId);
					if (openingData?.organizationId && !orgName) {
						try {
							dispatch(getOrganizationName(openingData?.organizationId));
						} catch (e) {
							console.log(e);
						}
					}
					setTemporaryOpeningData(openingData);
					setIsResumeRequired(openingData?.questionsBasedOnResume);
					setIsMobileInterviewAllowed(openingData?.isMobileInterviewAllowed);
					if (isMobile && !params.sd && !openingData?.isMobileInterviewAllowed) {
						setCurrentPage(pages.MOBILE_NOT_ALLOWED);
						return;
					}
					interviewReportData?.schedule && setScheduledTime(interviewReportData.schedule);
					// Assuming interviewReportData.schedule is in ISO format with offset, e.g., "2024-03-01T14:30:00+05:30"
					const scheduledTimeDate = parseISO(interviewReportData.schedule);

					// Get the user's current time in UTC and convert it to the same timezone as the scheduledTime for comparison
					const now = new Date();
					setUserLocalTime(now.toISOString());

					// Calculate the difference in minutes
					const diffMinutes = differenceInMinutes(scheduledTimeDate, now);

					// Update time till interview in HH:MM format
					const hours = Math.floor(diffMinutes / 60);
					const minutes = diffMinutes % 60;
					setTimeTillInterview(`${hours > 0 ? `${hours}h ` : ""}${minutes}m`);

					if (interviewReportData.joinOption === "onTime") {
						if (interviewReportData.joinEarly && diffMinutes > 10) {
							setCurrentPage(pages.JOINED_EARLY);
							return;
						} else if (!interviewReportData.joinEarly && diffMinutes > 0) {
							setCurrentPage(pages.JOINED_EARLY);
							return;
						} else if (diffMinutes < -10) {
							setCurrentPage(pages.TIMEOUT);
							return;
						}
					}
					if (interviewReportData.joinOption === "timeWindow") {
						if (interviewReportData.joinEarly && diffMinutes > 10) {
							setCurrentPage(pages.JOINED_EARLY);
							return;
						} else if (!interviewReportData.joinEarly && diffMinutes > 0) {
							setCurrentPage(pages.JOINED_EARLY);
							return;
						} else if (diffMinutes < -(interviewReportData.timeWindow * 60)) {
							setCurrentPage(pages.TIMEOUT);
							return;
						}
					}
					if (!interviewReportData?.welcomePageShown) {
						setCurrentPage(pages.WELCOME);
						return;
					}
					if (!interviewReportData?.cheatingWarningAccepted) {
						setCurrentPage(pages.CHEATING_WARNING);
						return;
					}
					if (createdByAdmin.current && !interviewReportData?.correctionFormShown) {
						setCurrentPage(pages.CORRECTION_FORM);
						return;
					}
					setCurrentPage(pages.CHECK_PERMISSIONS);
					setOpeningData(openingData);
					if (orgName) {
						if (openingData.skillsGroup && openingData.skillsGroup.length > 0) {
							const { skillsGroup } = openingData;
							let tempArr = skillsGroup.map((group, i) => {
								const { skills, skillGroupName, criteria } = group;
								if (skills && skills.length > 0) {
									let arrayOfSkillsForSingleGroup = skills.map((skill) => ({
										skill,
										selected: criteria == 1 ? true : false,
									}));

									return {
										skillGroupName,
										criteria,
										skills: arrayOfSkillsForSingleGroup,
										errorMessage: "",
									};
								}
							});
							setSkillsGroup(tempArr);
						}
						setInterviewReportData(interviewReportData);

						if (openingData?.status && openingData?.canConductInterview) {
							if (disableAvatar === "true") {
								setAvatarMode(false);
								localStorage.removeItem("disableAvatar");
							} else {
								setAvatarMode(openingData?.avatarMode);
							}
							isRecordingEnabledRef.current =
								openingData.proctoring || openingData?.isSecondaryCameraRequired;
							// isRecordingEnabledRef.current = false;
							setShowQrCode(openingData?.isSecondaryCameraRequired);
							if (openingData?.isSecondaryCameraRequired) {
								if (params.sd) {
									if (interviewReportData?.secondDevice) {
										setSecondDeviceAdded(true);
										setDeviceLimitReached(true);
										setCurrentPage(pages.CONFIRM_EMAIL);
									} else {
										if (interviewReportData?.activeSession) {
											const base64DecryptedEmail = atob(decodeURIComponent(params.sd));
											const decryptedBytes = CryptoJS.AES.decrypt(
												base64DecryptedEmail,
												SECRET_KEY,
											);
											const decryptedEmail = decryptedBytes.toString(CryptoJS.enc.Utf8);
											if (decryptedEmail === interviewReportData?.email) {
												setCurrentPage(pages.SD_CONTINUE);
											} else {
												setCurrentPage(pages.CONFIRM_EMAIL);
											}
										} else setFirstOpenOnWeb(true);
									}
								} else {
									if (interviewReportData?.activeSession) {
										setSecondDeviceAdded(true);
										setDeviceLimitReached(true);
										setCurrentPage(pages.CONFIRM_EMAIL);
									} else {
										if (interviewReportData?.secondDevice) {
											setSecondDeviceAdded(true);
											setDeviceLimitReached(true);
										}
										const encryptedEmail = CryptoJS.AES.encrypt(
											interviewReportData?.email,
											SECRET_KEY,
										).toString();
										const base64EncryptedEmail = btoa(encryptedEmail);
										const encodedEncryptedEmail =
											encodeURIComponent(base64EncryptedEmail);
										let currentUrl = window.location.href;
										if (params.resumeToken) {
											currentUrl = currentUrl.replace(`/${params.resumeToken}`, "");
										}
										currentUrl += `/sd/${encodedEncryptedEmail}`;
										setQrUrl(currentUrl);
									}
								}
							}
						} else {
							setOpeningStatus(false);
							setCurrentPage(pages.CONFIRM_EMAIL);
							return;
						}

						openingData?.isMobileInterviewAllowed
							? await checkIfSessionExists(openingData, interviewReportData)
							: !isMobile && (await checkIfSessionExists(openingData, interviewReportData));
					}
				}
			}
		};
		async function getInterviewReady() {
			setInterviewLoading(true);
			await getInterviewReportById();
			setInterviewLoading(false);
		}
		getInterviewReady();
	}, [orgName]);

	useEffect(() => {
		if (interviewReportData && !interviewReportData?.interviewCompleted && interviewEnded) {
			clearSessionStorage();
			if (micStream) stopStreamTracks(micStream);
			if (videoStream) stopStreamTracks(videoStream);
			if (screenStream) stopStreamTracks(screenStream);

			const currentDate = new Date();
			const isoString = formatISO(currentDate); // ISO string with 'Z'
			const formattedString = isoString.replace("Z", "+00:00"); // Replace 'Z' with '+00:00'
			!eventName && setEventName("interviewEnded");
			updateCandidatePostInterview(
				params.interviewReportId,
				formattedString,
				eventName === "endInterviewButton" ? true : false,
			);
			setIsActiveSession(false);
			setSecondDeviceAdded(false);
			setChimeMeetingStartedForSecondDevice(false);
		}
	}, [interviewEnded, interviewReportData]);

	useEffect(() => {
		const handleVisibilityChange = async () => {
			if (activeSessionRef.current && document.hidden) {
				try {
					const response = await fetch("/api/interviewReports/updateTrustScore", {
						method: "POST",
						headers: {
							"Content-Type": "application/json",
						},
						body: JSON.stringify({
							interviewReportId: params.interviewReportId,
							trustScore: -1,
						}),
					});

					if (response.status === 200) {
						const responseBody = await response.json();
					} else {
						console.log("Trust score not updated, status code:", response.status);
					}
				} catch (error) {
					console.error("Error updating trust score:", error);
				}
			}
		};

		if (currentPage === pages.INTERVIEW) {
			document.addEventListener("visibilitychange", handleVisibilityChange);
		}

		return () => {
			document.removeEventListener("visibilitychange", handleVisibilityChange);
		};
	}, [currentPage]);

	const stopStreamTracks = (stream) => {
		if (stream) {
			const tracks = stream.getTracks();
			tracks.forEach((track) => {
				if (track.readyState !== "ended") {
					track.stop();
				} else {
					console.log(`Track already stopped: ${track.kind}, State: ${track.readyState}`);
				}
			});
		}
	};

	// for permissionChecker
	useEffect(() => {
		return () => {
			if (micStream) {
				stopStreamTracks(micStream);
			}
		};
	}, [micGranted]);

	useEffect(() => {
		return () => {
			if (videoStream) {
				stopStreamTracks(videoStream);
			}
		};
	}, [cameraGranted]);

	useEffect(() => {
		return () => {
			if (screenStream) {
				stopStreamTracks(screenStream);
			}
		};
	}, [screenShared]);

	const requestMicPermission = async (deviceId = null) => {
		try {
			const constraints = deviceId
				? {
						audio: { deviceId: { exact: deviceId } },
					}
				: { audio: true };

			const stream = await navigator.mediaDevices.getUserMedia(constraints);
			// Simulate inactive stream - READ-ONLY
			// stream.active = false;

			// Simulate no live video tracks - CAN TEST
			// stream.removeTrack(stream.getAudioTracks()[0]);

			// Simulate video track not live - READ-ONLY
			// stream.getAudioTracks()[0].readyState = "ended";

			// Simulate video track disabled - CAN TEST
			// stream.getAudioTracks()[0].enabled = false;

			// Verifies the stream is active, there is at least one audio track, the track is live, and the track is enabled.
			if (!stream.active) {
				// setShowMicModal(true);
				clientLogger("Microphone permission granted but the stream is inactive");
				throw new Error("Microphone stream is inactive");
			} else if (stream.getAudioTracks().length === 0) {
				// setShowMicModal(true);
				clientLogger("Microphone permission granted but no audio tracks found");
				throw new Error("No audio tracks found in the microphone stream");
			} else if (stream.getAudioTracks()[0].readyState !== "live") {
				// setShowMicModal(true);
				clientLogger("Microphone permission granted but the audio track is not live");
				throw new Error("Audio track in the microphone stream is not live");
			} else if (!stream.getAudioTracks()[0].enabled) {
				// setShowMicModal(true);
				clientLogger("Microphone permission granted but the audio track is disabled");
				throw new Error("Audio track in the microphone stream is disabled");
			}
			setMicStream(stream);
			setMicGranted(true);
			return true;
		} catch (error) {
			setMicGranted(false);
			handleMediaStreamError(error, "Microphone");
			return false;
		}
	};

	const requestCameraPermission = async () => {
		try {
			const stream = await navigator.mediaDevices.getUserMedia({
				video: { facingMode: "user" },
			});
			// Simulate inactive stream - READ-ONLY
			// stream.active = false;

			// Simulate no live video tracks - CAN TEST
			// stream.removeTrack(stream.getVideoTracks()[0]);

			// Simulate video track not live - READ-ONLY
			// stream.getVideoTracks()[0].readyState = "ended";

			// Simulate video track disabled - CAN TEST
			// stream.getVideoTracks()[0].enabled = false;

			// Verifies the stream is active, there is at least one video track, the track is live, and the track is enabled.
			if (!stream.active) {
				// setShowCamModal(true);
				clientLogger("Camera permission granted but the stream is inactive");
				throw new Error("Camera stream is inactive");
			} else if (stream.getVideoTracks().length === 0) {
				// setShowCamModal(true);
				clientLogger("Camera permission granted but no live video tracks found");
				throw new Error("No live video tracks found");
			} else if (stream.getVideoTracks()[0].readyState !== "live") {
				// setShowCamModal(true);
				clientLogger("Camera permission granted but the video track is not live");
				throw new Error("Video track is not live");
			} else if (!stream.getVideoTracks()[0].enabled) {
				// setShowCamModal(true);
				clientLogger("Camera permission granted but the video track is disabled");
				throw new Error("Video track is disabled");
			}
			setVideoStream(stream);
			setCameraGranted(true);
			return true;
		} catch (error) {
			setCameraGranted(false);
			handleMediaStreamError(error, "Camera");
			return false;
		}
	};

	const requestScreenPermission = async () => {
		try {
			if (!("getDisplayMedia" in navigator.mediaDevices)) {
				setSnackbarMessage(
					"Screen sharing is not supported by your browser. Please use a desktop browser.",
				);
				setSnackbarOpen(true);
				setScreenShared(false);
				return false;
			} else {
				const stream = await navigator.mediaDevices.getDisplayMedia({
					video: {
						displaySurface: "monitor", // prefer to share the monitor (entire screen)
						logicalSurface: true, // share the logical surface (all virtual desktops)
						cursor: "always", // include the cursor in the screen capture
					},
					// audio: true,
				});
				const videoTrack = stream.getVideoTracks()[0];
				const settings = videoTrack.getSettings();

				// Check if the stream is active, the display surface is set to 'monitor', and the video track's state is 'live'.
				if (settings.displaySurface !== "monitor") {
					videoTrack.stop();
					clientLogger(
						"Screen sharing permission granted but the display surface is not set to 'monitor'.",
					);
					setSnackbarMessage(
						"Screen sharing failed. Please reload and ensure you are sharing the entire screen.",
					);
					setSnackbarOpen(true);
					setScreenShared(false);
					return false;
				} else if (!stream.active) {
					// setShowScreenModal(true);
					videoTrack.stop();
					clientLogger("Screen sharing permission granted but the stream is inactive.");
					// setSnackbarMessage(
					// 	"Screen sharing failed. Please reload and ensure you are sharing the entire screen.",
					// );
					// setSnackbarOpen(true);
					throw new Error("Screen sharing stream is inactive");
				} else if (videoTrack.readyState !== "live") {
					videoTrack.stop();
					clientLogger("Screen sharing permission granted but the video track is not live.");
					throw new Error("Screen sharing video track is not live");
				}
				setScreenStream(stream);
				setScreenShared(true);
				return true;
			}
		} catch (error) {
			setScreenShared(false);
			handleMediaStreamError(error, "Screen");
			return false;
		}
	};

	const handleMediaStreamError = (error, deviceType) => {
		// Logs specific errors for different device types and sets appropriate user notifications.
		if (error.name === "NotAllowedError" || error.name === "PermissionDeniedError") {
			clientLogger(`${deviceType} permission denied or not allowed`);
			setSnackbarMessage(
				`${deviceType} permission denied. Please enable ${deviceType.toLowerCase()} access and reload the page.`,
			);
			setSnackbarOpen(true);
		} else {
			console.log(`${deviceType} error: ${error}`);
			if (deviceType === "Microphone") {
				setShowMicModal(true);
			} else if (deviceType === "Camera") {
				setShowCamModal(true);
			} else if (deviceType === "Screen") {
				setShowScreenModal(true);
			}
		}
	};

	useEffect(() => {
		const handleScreenShareEnd = async () => {
			if (!interviewEnded) {
				localStorage.setItem("alreadyHasAnActiveTab", "false");
				audioStateRef.current?.pause?.();
				if (currentPage !== pages.INTERVIEW) setCurrentPage(pages.INTERVIEW);
				clientLogger(
					"Screen sharing ended from the candidate's end. Updating local tab status and flags in database.",
				);
				setScreenShared(false);
				screenShareRef.current = true;
				const data = JSON.stringify({
					activeSession: false,
					interviewReportId: params.interviewReportId,
					candidateName: interviewReportData?.firstName + " " + interviewReportData?.lastName,
					preferredName: interviewReportData?.preferredName
						? interviewReportData?.preferredName
						: "noPreferredName",
					openingTitle: openingData?.title ? openingData.title : "noOpeningTitle",
					eventName: "beforeunload",
					clickedLeaveInterview: false,
				});
				const beaconSent = navigator.sendBeacon(
					"/api/interviewReports/finalBeaconCall",
					new Blob([data], {
						type: "application/json",
					}),
				);
				if (!beaconSent) {
					console.log(">>>>>> Beacon could not be sent");
				} else {
					setEventName("screenShareEnded");
					setIsActiveSession(false);
					skipEPreventDefaultRef.current = true;
				}
				let countdownInterval;
				countdownInterval = setInterval(() => {
					setScreenRetryCountdown((prevCountdown) => {
						if (prevCountdown > 1) {
							return prevCountdown - 1;
						} else {
							clearInterval(countdownInterval);
							return 0;
						}
					});
				}, 1000);
				setTimeout(() => {
					window.location.reload();
				}, 15000);
			}
		};

		if (screenStream) {
			const videoTrack = screenStream.getVideoTracks()[0];

			if (videoTrack) {
				videoTrack.addEventListener("ended", handleScreenShareEnd);

				return () => {
					videoTrack.removeEventListener("ended", handleScreenShareEnd);
				};
			}
		}
	}, [screenStream]);

	useEffect(() => {
		const handleMicStreamEnd = async () => {
			if (!interviewEnded) {
				localStorage.setItem("alreadyHasAnActiveTab", "false");
				audioStateRef.current?.pause?.();
				if (currentPage !== pages.INTERVIEW) setCurrentPage(pages.INTERVIEW);
				clientLogger(
					"Mic stream ended from the candidate's end. Updating local tab status and flags in database.",
				);
				setMicGranted(false);
				micStreamRef.current = true;
				const data = JSON.stringify({
					activeSession: false,
					interviewReportId: params.interviewReportId,
					candidateName: interviewReportData?.firstName + " " + interviewReportData?.lastName,
					preferredName: interviewReportData?.preferredName
						? interviewReportData?.preferredName
						: "noPreferredName",
					openingTitle: openingData?.title ? openingData.title : "noOpeningTitle",
					eventName: "beforeunload",
					clickedLeaveInterview: false,
					isSourceMicStreamEnd: true,
				});
				const beaconSent = navigator.sendBeacon(
					"/api/interviewReports/finalBeaconCall",
					new Blob([data], {
						type: "application/json",
					}),
				);
				if (!beaconSent) {
					console.log(">>>>>> Beacon could not be sent");
				} else {
					setEventName("micStreamEnded");
					setIsActiveSession(false);
					skipEPreventDefaultRef.current = true;
				}
				let countdownInterval;
				countdownInterval = setInterval(() => {
					setScreenRetryCountdown((prevCountdown) => {
						if (prevCountdown > 1) {
							return prevCountdown - 1;
						} else {
							clearInterval(countdownInterval);
							return 0;
						}
					});
				}, 1000);
				setTimeout(() => {
					window.location.reload();
				}, 15000);
			}
		};

		if (micStream) {
			const audioTrack = micStream.getAudioTracks()[0];
			if (audioTrack) {
				audioTrack.addEventListener("ended", handleMicStreamEnd);

				return () => {
					audioTrack.removeEventListener("ended", handleMicStreamEnd);
				};
			}
		}
	}, [micStream]);

	useEffect(() => {
		const handleVideoStreamEnd = async () => {
			if (!interviewEnded) {
				localStorage.setItem("alreadyHasAnActiveTab", "false");
				audioStateRef.current?.pause?.();
				if (currentPage !== pages.INTERVIEW) setCurrentPage(pages.INTERVIEW);
				clientLogger(
					"Video stream ended from the candidate's end. Updating local tab status and flags in database.",
				);
				setCameraGranted(false);
				videoStreamRef.current = true;
				const data = JSON.stringify({
					activeSession: false,
					interviewReportId: params.interviewReportId,
					candidateName: interviewReportData?.firstName + " " + interviewReportData?.lastName,
					preferredName: interviewReportData?.preferredName
						? interviewReportData?.preferredName
						: "noPreferredName",
					openingTitle: openingData?.title ? openingData.title : "noOpeningTitle",
					eventName: "beforeunload",
					clickedLeaveInterview: false,
				});
				const beaconSent = navigator.sendBeacon(
					"/api/interviewReports/finalBeaconCall",
					new Blob([data], {
						type: "application/json",
					}),
				);
				if (!beaconSent) {
					console.log(">>>>>> Beacon could not be sent");
				} else {
					setEventName("vidStreamEnded");
					setIsActiveSession(false);
					skipEPreventDefaultRef.current = true;
				}
				let countdownInterval;
				countdownInterval = setInterval(() => {
					setScreenRetryCountdown((prevCountdown) => {
						if (prevCountdown > 1) {
							return prevCountdown - 1;
						} else {
							clearInterval(countdownInterval);
							return 0;
						}
					});
				}, 1000);
				setTimeout(() => {
					window.location.reload();
				}, 15000);
			}
		};

		if (videoStream) {
			const videoTrack = videoStream.getVideoTracks()[0];

			if (videoTrack) {
				videoTrack.addEventListener("ended", handleVideoStreamEnd);

				return () => {
					videoTrack.removeEventListener("ended", handleVideoStreamEnd);
				};
			}
		}
	}, [videoStream]);

	useEffect(() => {
		if (!interviewEnded && failedFaceDetectCount > 9) {
			localStorage.setItem("alreadyHasAnActiveTab", "false");
			audioStateRef.current?.pause?.();
			if (currentPage !== pages.INTERVIEW) setCurrentPage(pages.INTERVIEW);
			clientLogger("Face detection failed. Asking candidate to refresh the page.");
			const data = JSON.stringify({
				activeSession: false,
				interviewReportId: params.interviewReportId,
				candidateName: interviewReportData?.firstName + " " + interviewReportData?.lastName,
				preferredName: interviewReportData?.preferredName
					? interviewReportData?.preferredName
					: "noPreferredName",
				openingTitle: openingData?.title ? openingData.title : "noOpeningTitle",
				eventName: "beforeunload",
				clickedLeaveInterview: false,
			});
			const beaconSent = navigator.sendBeacon(
				"/api/interviewReports/finalBeaconCall",
				new Blob([data], {
					type: "application/json",
				}),
			);
			if (!beaconSent) {
				console.log(">>>>>> Beacon could not be sent");
			} else {
				setEventName("faceDetectionFailed10Times");
				setIsActiveSession(false);
				skipEPreventDefaultRef.current = true;
			}
			let countdownInterval;
			countdownInterval = setInterval(() => {
				setScreenRetryCountdown((prevCountdown) => {
					if (prevCountdown > 1) {
						return prevCountdown - 1;
					} else {
						clearInterval(countdownInterval);
						return 0;
					}
				});
			}, 1000);
			setTimeout(() => {
				window.location.reload();
			}, 15000);
		}
	}, [failedFaceDetectCount]);

	const handleContinue2Click = async () => {
		const micOnly = !openingData?.proctoring && !openingData?.isSecondaryCameraRequired;
		let message = "Please grant";
		let missingPermissions = false;

		if (!micGranted) {
			message += " mic";
			missingPermissions = true;
		}
		if (
			!cameraGranted &&
			!micOnly &&
			(openingData?.proctoring ? true : openingData?.isSecondaryCameraRequired && isMobile)
		) {
			message += missingPermissions ? " and camera" : " camera";
			missingPermissions = true;
		}
		if (!isMobile && !interviewReportData?.activeSession && !micOnly && openingData?.proctoring) {
			if (!screenShared) {
				message += missingPermissions ? ", and entire screen share" : " entire screen share";
				missingPermissions = true;
			}
		}

		if (
			!interviewReportData?.activeSession &&
			showQrCode &&
			moveToSecondDeviceSetup &&
			!secondDeviceAdded
		) {
			message += missingPermissions ? ", and second device" : " second device";
			missingPermissions = true;
		}

		if (missingPermissions) {
			message += " permissions before clicking continue.";
			clientLogger(message);
			setSnackbarMessage(message);
			setSnackbarOpen(true);

			if (!micGranted) {
				await requestMicPermission();
			}
			if (
				!cameraGranted &&
				!micOnly &&
				(openingData?.proctoring ? true : openingData?.isSecondaryCameraRequired && isMobile)
			) {
				await requestCameraPermission();
			}
			if (!isMobile && !interviewReportData?.activeSession && !micOnly && openingData?.proctoring) {
				if (!screenShared) {
					await requestScreenPermission();
				}
			}
		} else if (
			(openingData?.proctoring ? true : openingData?.isSecondaryCameraRequired && isMobile) &&
			!chimeStarted
		) {
			clientLogger("Candidate clicked continue before chime meeting started.");
			setSnackbarMessage("Please wait for the meeting to start.");
			setSnackbarOpen(true);
		} else if (openingData?.isSecondaryCameraRequired && moveToSecondDeviceSetup && !isPositionAccurate) {
			clientLogger("Candidate clicked continue before accurately placing the mobile device.");
			setSnackbarMessage("Please make sure your mobile position is accurate before continuing.");
			setSnackbarOpen(true);
		} else {
			if (!interviewReportData?.activeSession && showQrCode && !moveToSecondDeviceSetup) {
				setMoveToSecondDeviceSetup(true);
			} else {
				clientLogger("All permissions are given, heading to candidate photo capture page");
				window.scrollTo(0, 0);
				if (temporaryReportData?.candidatePhotoFileNameInS3) {
					if (
						temporaryOpeningData.isFaceMatchRequired &&
						temporaryReportData?.identityVerificationStatus
					)
						setCurrentPage(pages.DEVICE_TEST);
					else if (temporaryOpeningData.isFaceMatchRequired)
						setCurrentPage(pages.IDENTITY_VERIFICATION);
					else setCurrentPage(pages.DEVICE_TEST);
				} else {
					setCurrentPage(pages.CANDIDATE_PHOTO_CAPTURE);
				}
			}
		}
	};

	// for deviceTest
	useEffect(() => {
		const getAudioDevices = async () => {
			const devices = await navigator.mediaDevices.enumerateDevices();
			const audioInputDevices = devices.filter((device) => device.kind === "audioinput");
			setAudioDevices(audioInputDevices);
			if (audioInputDevices.length > 0) {
				setSelectedAudioDevice(audioInputDevices[0].deviceId);
			}
		};

		const getVideoDevices = async () => {
			await requestCameraPermission();
			const devices = await navigator.mediaDevices.enumerateDevices();
			const videoInputDevices = devices.filter((device) => device.kind === "videoinput");
			setVideoDevices(videoInputDevices);
			if (!selectedVideoDevice && videoInputDevices.length > 0) {
				setSelectedVideoDevice(videoInputDevices[0].deviceId);
				await updateCameraStream(videoInputDevices[0].deviceId);
			}
		};

		if (currentPage === pages.DEVICE_TEST) {
			getAudioDevices();
			getVideoDevices();
		}
		if (currentPage === pages.CANDIDATE_PHOTO_CAPTURE) {
			getVideoDevices();
		}
		if (currentPage === pages.IDENTITY_VERIFICATION) {
			getVideoDevices();
		}
	}, [currentPage]);

	const handleAudioDeviceChange = async (e) => {
		const newMicDeviceId = e.target.value;
		setSelectedAudioDevice(newMicDeviceId);
		clientLogger("Audio Device Changed by user", {
			newMicDeviceId,
		});
		setCurrentAudioDeviceId(newMicDeviceId);
		await updateMicStream(newMicDeviceId);
	};

	const handleVideoDeviceChange = async (e) => {
		const newCamDeviceId = e.target.value;
		setSelectedVideoDevice(newCamDeviceId);
		clientLogger("Video Device Changed by user", {
			newCamDeviceId,
		});
		await updateCameraStream(newCamDeviceId);
	};

	const updateMicStream = async (deviceId) => {
		// console.log(`>>>>> inside updateMicStream with deviceId: ${deviceId}`);
		let newStream = "";
		try {
			if (micStream) {
				micStream.getTracks().forEach((track) => track.stop());
			}
			newStream = await navigator.mediaDevices.getUserMedia({
				audio: {
					deviceId: {
						exact: deviceId,
					},
				},
			});
			if (
				!newStream.active ||
				newStream.getAudioTracks().length === 0 ||
				!newStream.getAudioTracks()[0].enabled
			) {
				throw new Error("Microphone stream is inactive or no audio tracks found");
			}
			setMicStream(newStream);
		} catch (error) {
			if (error.name === "NotAllowedError" || error.name === "PermissionDeniedError") {
				setSnackbarMessage("Mic permission denied. Please enable mic access and reload the page.");
				setSnackbarOpen(true);
			} else {
				console.log(`Microphone error: ${error}`);
				setSnackbarMessage(
					"Failed to access microphone. Please check device connections and settings. Make sure your drivers are up to date.",
				);
				setSnackbarOpen(true);
			}
			return false;
		}
	};

	const updateCameraStream = async (deviceId) => {
		let newStream = "";
		try {
			if (videoStream) {
				videoStream.getTracks().forEach((track) => track.stop());
			}
			newStream = await navigator.mediaDevices.getUserMedia({
				video: {
					deviceId: { exact: deviceId },
					// ...(deviceId ? { deviceId: { exact: deviceId } } : { facingMode: "user" }),
				},
			});
			if (
				!newStream.active ||
				newStream.getVideoTracks().length === 0 ||
				!newStream.getVideoTracks()[0].enabled
			) {
				throw new Error("Camera stream is inactive or no video tracks found");
			}

			setVideoStream(newStream);
		} catch (error) {
			if (error.name === "NotAllowedError" || error.name === "PermissionDeniedError") {
				setSnackbarMessage(
					"Camera permission denied. Please enable camera access and reload the page.",
				);
				setSnackbarOpen(true);
			} else {
				console.log(`Camera error: ${error}`);
				setSnackbarMessage(
					"Failed to access camera. Please check device connections and settings. Make sure your drivers are up to date.",
				);
				setSnackbarOpen(true);
			}
			return false;
		}
	};

	// function serializeMediaStream(stream) {
	// 	console.log("serializeMediaStream called");
	// 	const streamInfo = {
	// 		id: stream.id,
	// 		active: stream.active,
	// 		tracks: stream.getTracks().map((track) => ({
	// 			id: track.id,
	// 			kind: track.kind,
	// 			label: track.label,
	// 			enabled: track.enabled,
	// 			readyState: track.readyState,
	// 		})),
	// 	};
	// 	// console.log(
	// 	// 	`JSON.stringify(streamInfo, null, 2): ${JSON.stringify(
	// 	// 		streamInfo,
	// 	// 		null,
	// 	// 		2
	// 	// 	)}`
	// 	// );
	// 	return JSON.stringify(streamInfo, null, 2);
	// }

	const processTestAudio = async () => {
		handleUpload(testMp3FileRef.current, "en", params.interviewReportId, 0)
			.then((res) => {
				let content = res.data.message;
				setTestTranscriptionText(content);
				clientLogger(
					"Client received transcription for test audio, sending candidate's transcription to openai to compare.",
					{
						methodCalled: "checkTranscript",
						transcriptionReceived: content,
					},
				);
				checkTranscript(content, params.interviewReportId, randomTranscriptionTextIdx)
					.then((res) => {
						if (res.data.message.toLowerCase() === "true") {
							setTranscriptionTestLoader(false);
							transcriptionTestFirstAttempt && setTranscriptionTestFirstAttempt(false);
							clientLogger("Transcription test passed");
							setRetryTranscriptionTest(false);
							setCanPassDeviceTest(true);
						} else if (res.data.message.toLowerCase() === "false") {
							setTranscriptionTestLoader(false);
							transcriptionTestFirstAttempt && setTranscriptionTestFirstAttempt(false);
							clientLogger("Transcription test failed");
							setRetryTranscriptionTest(true);
							if (failedTranscriptionTestCount < 5) {
								setFailedTranscriptionTestCount((prevCount) => prevCount + 1);
							}
						} else {
							throw new Error("Error in checkTranscript");
						}
					})
					.catch((e) => {
						clientLogger("Error in checkTranscript", {
							level: "error",
							errorMessage: e?.response?.data?.message || e.message,
						});
						setTranscriptionTestLoader(false);
						transcriptionTestFirstAttempt && setTranscriptionTestFirstAttempt(false);
					});
			})
			.catch((e) => {
				setRetryTranscriptionTest(true);
				e?.response?.status === 403 && setShowModal403(true);
				clientLogger("Error in handleUpload (transcription)", {
					level: "error",
					errorMessage: e?.response?.data?.message || e.message,
				});

				console.log(e);
				setTranscriptionTestLoader(false);
				transcriptionTestFirstAttempt && setTranscriptionTestFirstAttempt(false);
			});
	};

	const start = () => {
		recorder
			.start()
			.then(() => {
				setIsRecording(true);
				setPlayer(null);
			})
			.catch((e) => console.error(`error starting recording: ${e}`));
	};

	const stop = () => {
		recorder
			.stop()
			.getMp3()
			.then(([buffer, blob]) => {
				setIsRecording(false);
				if (currentPage === pages.DEVICE_TEST && !fallbackDeviceTest) {
					setTranscriptionTestLoader(true);
					const file = new File([blob], `inhand-${interviewReportData._id}.mp3`, {
						type: "audio/mpeg",
					});
					testMp3FileRef.current = file;
					processTestAudio();
				}
				setPlayer(new Audio(URL.createObjectURL(blob)));
			})
			.catch((e) => clientLogger(`error stopping recording: ${e}`));
	};

	const handleContinue3Click = async () => {
		try {
			if (isRecording) {
				stop();
			}
			if (player) {
				if (!player.paused) {
					clientLogger("Stopping playback before moving to next page.");
					player.pause(); // Stop the playback
				}
				setPlayer(null);
			}
		} catch (error) {
			clientLogger("Error stopping playback before moving to next page.", {
				level: "error",
				error: error,
			});
		}

		const isPreferredSkillsEmpty =
			!interviewReportData?.preferredSkills || interviewReportData.preferredSkills.length === 0;
		clientLogger(
			`Device Test Page continue button clicked, heading to ${isPreferredSkillsEmpty ? "Skills Picker" : "Interview"} page`,
		);
		const isTechRole = openingData?.isTechnical;
		const skillsGroups = openingData?.skillsGroup;
		const coreSkills = openingData?.coreSkills;
		const jobRequirementsAndResponsibilities = openingData?.jobRequirementsAndResponsibilities;
		if (!isTechRole) {
			// skipping the skill picker page for non tech
			if (coreSkills?.length > 0 && jobRequirementsAndResponsibilities.length > 0) {
				setCurrentPage(pages.INTERVIEW_LANGUAGE_OPTION);
			} else if (skillsGroups?.length > 0) {
				// for old openings, which dont have coreskills
				if (isPreferredSkillsEmpty) {
					// since they have to choose the preferred skills
					setCurrentPage(pages.TOPICS_PICKER);
				} else {
					// if the skills are already chosen
					setCurrentPage(pages.INTERVIEW_LANGUAGE_OPTION);
				}
			}
		} else if (isTechRole) {
			if (isPreferredSkillsEmpty) {
				setCurrentPage(pages.TOPICS_PICKER);
			} else {
				setCurrentPage(pages.INTERVIEW_LANGUAGE_OPTION);
			}
		}
		window.scrollTo(0, 0);
	};

	const wavesurfer = (toDo) => {
		const waveform = document.getElementById("waveform");
		waveform.style.display = "inline-block";
		const wavesurfer = WaveSurfer.create({
			container: "#waveform",
			height: 70,
			waveColor: "violet",
			progressColor: "purple",
			barWidth: 2,
			barGap: 1,
			barRadius: 2,
		});
		const record = wavesurfer.registerPlugin(RecordPlugin.create());
		toDo === "start" ? record.startRecording({ deviceId: selectedAudioDevice }) : record.stopRecording();
	};

	function handleAddSkill(skillIndex, groupIndex) {
		setSkillsGroup((prev) => {
			const tempPrevGroup = structuredClone(prev);
			const prevVal = tempPrevGroup[groupIndex].skills[skillIndex].selected;
			tempPrevGroup[groupIndex].skills[skillIndex].selected = !prevVal;
			return tempPrevGroup;
		});
	}

	async function checkCriteria() {
		const noNetwork = await ping();
		if (noNetwork) return;
		let errorsCount = 0;
		skillsGroup.forEach((group, groupIndex) => {
			const { skills, criteria, skillGroupName } = group;
			let checks;
			if (criteria == 1) {
				checks =
					skills && skills.length > 0 && skills.every((skillObj) => skillObj.selected === true);
			}
			if (criteria == 2) {
				checks = skills && skills.length > 0 && skills.some((skillObj) => skillObj.selected === true);
			}
			if (criteria == 3) {
				// no checks are required
				checks = true;
			}
			if (!checks) {
				++errorsCount;
				clientLogger(`${skillGroupName} -> criteria "${errorMap[criteria]}" is not satisfied.`);
				setSkillsGroup((prev) => {
					const tempPrevGroup = structuredClone(prev);
					tempPrevGroup[groupIndex].errorMessage = errorMap[criteria];
					return tempPrevGroup;
				});

				setAnimate(true);
				setTimeout(() => {
					setAnimate(false);
				}, 500);
			} else {
				setSkillsGroup((prev) => {
					const tempPrevGroup = structuredClone(prev);
					tempPrevGroup[groupIndex].errorMessage = "";
					return tempPrevGroup;
				});
			}
		});
		console.log(`errorsCount is ${errorsCount}`);
		if (errorsCount == 0) {
			const skills = skillsGroup.map((group) => {
				const { skills } = group;
				return skills.filter((skillsObj) => skillsObj.selected).map((skillsObj) => skillsObj.skill);
			});
			clientLogger("All skill groups criteria satisfied, called update userskills for interview.");
			setIsUpdatingSkills(true);
			const res = await updateUserSkillsForInterview([].concat(...skills), params.interviewReportId);
			if (res) {
				if (res) {
					if (res.interviewReporData) {
						const { preferredSkills } = res.interviewReporData;
						setInterviewReportData((prev) => {
							return {
								...prev,
								preferredSkills: preferredSkills,
							};
						});
					}
				}
				setIsUpdatingSkills(false);
				clientLogger("Update skills for user complete, heading to Interview page");
				setCurrentPage(pages.INTERVIEW_LANGUAGE_OPTION);
				window.scrollTo(0, 0);
			}
		}
	}

	const StackOfGroups = skillsGroup && skillsGroup.length > 0 && (
		<Stack flexDirection={"column"} gap={"1rem"}>
			{skillsGroup.map((group, groupIndex) => {
				const { skills, criteria, skillGroupName, errorMessage } = group;
				return (
					<Paper
						elevation={2}
						sx={{
							bgcolor: "rgba(255,255,255,0.4)",
							backdropFilter: "blur(10px)",
							padding: "1rem",
						}}
						key={skillGroupName}
						className={animate && errorMessage ? "error-groups" : ""}
					>
						<Stack
							flexDirection={"column"}
							gap={"1rem"}
							justifyContent={"center"}
							alignItems={"flex-start"}
						>
							<Typography variant="h6" component={"h2"}>
								{skillGroupName}
							</Typography>
							<Typography variant="body1" component={"h4"}>
								{criteriaMap[criteria]}
							</Typography>
							{errorMessage && (
								<Stack
									justifyContent={"center"}
									alignItems={"center"}
									flexDirection={"row"}
									gap={"1rem"}
								>
									<Box
										sx={{
											display: "flex",
											gap: "1rem",
											padding: "10px",
											backgroundColor: "rgb(54, 48, 98, 0.4)",
											borderRadius: "4px",
										}}
									>
										<ErrorIcon />
										<Typography variant="body1" component={"h3"}>
											{errorMessage}
										</Typography>
									</Box>
								</Stack>
							)}
							<Stack
								direction={"column"}
								justifyContent={"center"}
								alignItems={"flex-start"}
								sx={{ ml: "10px" }}
							>
								{skills.map((skillObj, skillIndex) => {
									const { skill, selected } = skillObj;
									return (
										<FormControlLabel
											key={skill}
											className="skill-picker"
											control={
												<Checkbox
													onChange={() => handleAddSkill(skillIndex, groupIndex)}
													sx={{ scale: "1.25" }}
													checked={selected}
													disabled={criteria == 1}
												/>
											}
											label={skill}
										/>
									);
								})}
							</Stack>
						</Stack>
					</Paper>
				);
			})}
		</Stack>
	);

	// for interview
	useEffect(() => {
		function handleVisibilityChange() {
			const newSecondDeviceState = document.visibilityState === "hidden" ? "false" : "true";
			const data = JSON.stringify({
				value: newSecondDeviceState,
				type: "*",
				interviewReportId: params.interviewReportId,
			});

			const beaconSent = navigator.sendBeacon(
				"/api/interviewReports/updateSecondDevice",
				new Blob([data], { type: "application/json" }),
			);

			if (!beaconSent) {
				console.log(">>>>>> Beacon could not be sent");
			} else {
				setSecondDeviceAdded(document.visibilityState !== "hidden");
			}
		}

		// Check if `sd` parameter is present in the URL
		if (params.sd && !deviceLimitReached && chimeMeetingStartedForSecondDevice) {
			document.addEventListener("visibilitychange", handleVisibilityChange);
		}

		// Cleanup function
		return () => {
			document.removeEventListener("visibilitychange", handleVisibilityChange);
		};
	}, [deviceLimitReached, chimeMeetingStartedForSecondDevice]);

	useEffect(() => {
		async function handleBeforeUnload(e) {
			localStorage.setItem("alreadyHasAnActiveTab", "false");
			clientLogger(
				"Tab status set to false in localDB triggered by page refresh/close (beforeunload event). Calling FinalBeaconCall.",
			);
			const data = JSON.stringify({
				activeSession: false,
				interviewReportId: params.interviewReportId,
				candidateName: interviewReportData?.firstName + " " + interviewReportData?.lastName,
				preferredName: interviewReportData?.preferredName
					? interviewReportData?.preferredName
					: "noPreferredName",
				openingTitle: openingData?.title ? openingData.title : "noOpeningTitle",
				eventName: "beforeunload",
				clickedLeaveInterview: false,
			});
			const beaconSent = navigator.sendBeacon(
				"/api/interviewReports/finalBeaconCall",
				new Blob([data], {
					type: "application/json",
				}),
			);
			if (!beaconSent) {
				console.log(">>>>>> Beacon could not be sent");
			} else {
				setEventName("beforeunload");
				setIsActiveSession(false);
				if (currentPage !== pages.CAN_LEAVE && currentPage !== pages.INTERVIEW)
					setCurrentPage(pages.INTERVIEW);
			}
		}

		async function handleVisibilityChange(e) {
			const newActiveSessionState = document.visibilityState === "hidden" ? "false" : "true";
			clientLogger(`New activeSession status: ${newActiveSessionState} due to visibility change.`);
			const data = JSON.stringify({
				activeSession: newActiveSessionState,
				interviewReportId: params.interviewReportId,
				openingTitle: openingData?.title ? openingData.title : "noOpeningTitle",
				orgName: orgName ? orgName : "noOrgName",
				proctorLink: `${window.origin}/admin/proctor/${params.interviewReportId}`,
				emailRecipients: openingData?.emailRecipients,
				isMobile: isMobile,
			});
			const beaconSent = navigator.sendBeacon(
				"/api/interviewReports/updateActiveSession",
				new Blob([data], {
					type: "application/json",
				}),
			);
			if (!beaconSent) {
				console.log(">>>>>> Beacon could not be sent");
			} else {
				!isMobile && setIsActiveSession(document.visibilityState !== "hidden");
			}
		}

		if (openingData && isActiveSession) {
			if (isMobile && openingData.isMobileInterviewAllowed) {
				document.addEventListener("visibilitychange", handleVisibilityChange);
				window.addEventListener("beforeunload", handleBeforeUnload);
			} else {
				window.addEventListener("beforeunload", handleBeforeUnload);
			}
		}

		return () => {
			document.removeEventListener("visibilitychange", handleVisibilityChange);
			window.removeEventListener("beforeunload", handleBeforeUnload);
		};
	}, [isActiveSession, openingData]);

	function handleLanguageChange(e) {
		setLanguage(e.target.value);
	}

	function handleEditorDidMount(editor, monaco) {
		editorRef.current = editor;
		monacoRef.current = monaco;
	}

	function handleEditorChange(value, event) {
		setCodeExample(editorRef.current.getValue());
	}

	const drawerWidth = "40%";

	// for confirmEmail
	const isValidEmail = (email) => {
		// Regex Explanation:
		// ^ asserts position at start of the string
		// [^@]+ matches one or more characters that are not '@'
		// @ matches the '@' character
		// [^@]+ matches one or more characters that are not '@' ensuring no additional '@' is in the domain
		// \. matches the '.' character
		// [^@.]+ matches one or more characters that are not '@' or '.', ensuring no additional '.' or '@' after the domain
		// $ asserts position at the end of the string
		// The part before the '@' symbol does not contain '@'.
		// The part between the '@' and the '.' does not contain another '@' or '.'.
		// The part after the '.' does not contain another '@' or '.'.
		const emailRegex = /^[^@]+@[^@]+\.[^@.]+$/;
		return emailRegex.test(email);
	};

	const handleUserDetailsChange = (e) => {
		const { value } = e.target;
		setUserEmail(value);
	};

	const handleUserEmailSubmit = async (e) => {
		e.preventDefault();
		if (userEmail.trim() !== "") {
			if (!isValidEmail(userEmail)) {
				setSnackbarMessage("Invalid email format! Please try again.");
				setSnackbarOpen(true);
				return;
			}
			setSavingUserDeets(true);
			if (userEmail === interviewReportData?.email) {
				setSavingUserDeets(false);
				window.scrollTo(0, 0);
				await perms(openingData, interviewReportData);
				setCurrentPage(pages.INTERVIEW);
			} else {
				setSavingUserDeets(false);
				setSnackbarMessage("Please enter correct email address!");
				setSnackbarOpen(true);
			}
		} else {
			setSnackbarMessage("Email cannot be empty!");
			setSnackbarOpen(true);
			return;
		}
	};

	const joinedEarly = () => {
		return (
			<Box className="bg-gray-100 p-4 rounded-lg shadow-lg w-3/4 md:w-1/2 lg:w-1/3 mt-10 mx-auto">
				<h2 className="text-center text-2xl font-bold mb-6">You joined early.</h2>
				{temporaryReportData?.joinEarly ? (
					<p className="mb-6 text-center text-lg">
						You can join 10 minutes before the scheduled time.
					</p>
				) : (
					<p className="mb-6 text-center text-lg">You can join at the scheduled time.</p>
				)}
				<p className="text-center text-lg mb-6">Schedule: {getFormattedDateTime4(scheduledTime)}</p>
				<p className="text-center text-lg mb-6">{`Interview starts in ${timeTillInterview}.`}</p>
			</Box>
		);
	};

	const permissionChecker = () => {
		return (
			<Box className="bg-gray-200">
				{!interviewEnded && (
					<Box className="flex justify-center space-x-4 mt-10">
						{createdByAdmin.current && (
							<Box id="step1Bar" className="bg-gray-300 rounded h-2 w-24"></Box>
						)}
						<Box id="step2Bar" className="bg-gray-300 rounded h-2 w-24"></Box>
						<Box id="step3Bar" className="bg-[#10B981] rounded h-2 w-24"></Box>
						<Box id="step4Bar" className="bg-gray-300 rounded h-2 w-24"></Box>
						{temporaryOpeningData?.isFaceMatchRequired && (
							<Box id="step5Bar" className="bg-gray-300 rounded h-2 w-24"></Box>
						)}
					</Box>
				)}
				<Box
					id="panel2"
					className={`bg-white p-6 rounded-lg shadow-lg w-3/4 md:w-1/2 mt-10 mx-auto ${
						showQrCode && moveToSecondDeviceSetup ? "lg:w-2/4" : "lg:w-1/3"
					}`}
				>
					{interviewEnded ? (
						<h2 className="text-center text-2xl font-bold mb-6">Interview has ended.</h2>
					) : isMobile ? (
						openingData ? (
							openingData?.isMobileInterviewAllowed ? (
								<>
									<Box className="mb-6">
										<h3 className="font-medium text-lg mb-2">Permissions Status</h3>

										<Box className="flex items-center mb-3">
											<span className="mr-2">Microphone:</span>
											{micGranted === null ? (
												<i id="" className="fas fa-circle-notch fa-spin"></i>
											) : micGranted ? (
												<i
													id="cameraPermissionStatus"
													className="fas fa-check text-green-500"
												></i>
											) : (
												<i
													id="micPermissionStatus"
													className="fas fa-times text-red-500"
												></i>
											)}
										</Box>

										{openingData?.proctoring && (
											<Box className="flex items-center mb-3">
												<span className="mr-2">Camera:</span>
												{cameraGranted === null ? (
													<i id="" className="fas fa-circle-notch fa-spin"></i>
												) : cameraGranted ? (
													<i
														id="cameraPermissionStatus"
														className="fas fa-check text-green-500"
													></i>
												) : (
													<i
														id="micPermissionStatus"
														className="fas fa-times text-red-500"
													></i>
												)}
											</Box>
										)}
									</Box>

									<Box className="flex justify-end mt-4">
										<Box
											disabled={!canClickContinue2}
											id="continue2"
											className={`bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 focus:outline-none focus:border-none text-xs normal-case ${
												!canClickContinue2
													? "cursor-not-allowed opacity-50"
													: "cursor-pointer"
											}`}
											onClick={canClickContinue2 ? handleContinue2Click : null}
										>
											Continue <i className="fas fa-arrow-right mr-1"></i>
										</Box>
									</Box>
								</>
							) : openingData?.isSecondaryCameraRequired ? (
								firstOpenOnWeb ? (
									<h2 className="text-center text-xl font-bold mb-6">
										Please open the interview link first on your laptop/desktop.
									</h2>
								) : (
									<h2 className="text-center text-2xl font-bold mb-6">
										Please allow camera and microphone permissions to continue.
									</h2>
								)
							) : (
								<h2 className="text-center text-2xl font-bold mb-6">
									Just a heads-up! To participate in this interview, you'll need to switch
									to a desktop browser. This ensures you get the best possible experience
									and can fully engage with the interview process. Thanks for your
									cooperation!
								</h2>
							)
						) : (
							<Box className="flex items-center justify-center">
								<CircularProgress />
							</Box>
						)
					) : (
						<>
							<Box className="mb-5">
								{!moveToSecondDeviceSetup && (
									<h3 className="font-medium text-lg mb-2">Permissions Status</h3>
								)}

								{!moveToSecondDeviceSetup && (
									<Box className="flex items-center mb-3">
										<span className="mr-2">Microphone:</span>
										{micGranted === null ? (
											<i id="" className="fas fa-circle-notch fa-spin"></i>
										) : micGranted ? (
											<i
												id="cameraPermissionStatus"
												className="fas fa-check text-green-500"
											></i>
										) : (
											<i
												id="micPermissionStatus"
												className="fas fa-times text-red-500"
											></i>
										)}
									</Box>
								)}

								{!moveToSecondDeviceSetup && openingData?.proctoring && (
									<Box className="flex items-center mb-3">
										<span className="mr-2">Camera:</span>
										{cameraGranted === null ? (
											<i id="" className="fas fa-circle-notch fa-spin"></i>
										) : cameraGranted ? (
											<i
												id="cameraPermissionStatus"
												className="fas fa-check text-green-500"
											></i>
										) : (
											<i
												id="micPermissionStatus"
												className="fas fa-times text-red-500"
											></i>
										)}
									</Box>
								)}

								{!moveToSecondDeviceSetup &&
									openingData?.proctoring &&
									!interviewReportData?.activeSession &&
									!isMobile && (
										<Box className="flex items-center mb-3">
											<span className="mr-2">Screen Share (entire screen):</span>
											{screenShared === null ? (
												<i className="fas fa-circle-notch fa-spin"></i>
											) : screenShared ? (
												<i className="fas fa-check text-green-500"></i>
											) : (
												<i className="fas fa-times text-red-500"></i>
											)}
										</Box>
									)}
								{!interviewReportData?.activeSession &&
									showQrCode &&
									moveToSecondDeviceSetup && (
										<Box className="mb-3 p-4">
											<Box className="flex flex-col mb-4">
												<span className="text-lg font-bold">
													{secondDeviceAdded
														? "Second Camera is Added"
														: "Activate Your Mobile Device as a Second Camera"}
												</span>
												<p className="mt-2 text-sm">
													To keep the interview process fair and secure, please use
													your mobile device as a second camera. This helps us
													monitor the area behind your laptop and your hands,
													ensuring no unauthorized assistance or materials are used.
													Thank you!
												</p>
											</Box>
											<Box className="flex flex-row justify-between items-center mt-4">
												{secondDeviceAdded ? (
													isPositionAccurate ? (
														<Box className="flex flex-row items-center justify-center w-full">
															<i className="fas fa-check text-green-500 text-2xl"></i>
														</Box>
													) : (
														<Box className="flex flex-col items-center justify-center w-full">
															<Box className="flex flex-col items-center justify-center mb-3">
																<img
																	className="cursor-pointer"
																	src="https://procturemeet.s3.ap-southeast-1.amazonaws.com/assets/new+exam+drawing-01.png"
																	alt="second device setup"
																	style={{
																		width: "225px",
																		height: "225px",
																	}}
																	onClick={() => {
																		window.open(
																			"https://procturemeet.s3.ap-southeast-1.amazonaws.com/assets/new+exam+drawing-01.png",
																			"_blank",
																		);
																	}}
																/>
															</Box>
															<Box className="flex flex-row items-center justify-center w-full text-red-500">
																<i class="fas fa-exclamation-triangle mr-2"></i>
																Please check your mobile device for the
																position check
															</Box>
														</Box>
													)
												) : (
													<Box className="flex flex-row justify-center items-center w-full gap-[4rem]">
														<Box className="flex flex-col items-center justify-center mb-2">
															<img
																className="cursor-pointer"
																src="https://procturemeet.s3.ap-southeast-1.amazonaws.com/assets/new+exam+drawing-01.png"
																alt="second device setup"
																style={{
																	width: "225px",
																	height: "225px",
																}}
																onClick={() => {
																	window.open(
																		"https://procturemeet.s3.ap-southeast-1.amazonaws.com/assets/new+exam+drawing-01.png",
																		"_blank",
																	);
																}}
															/>
														</Box>
														<Box className="flex flex-col items-start bg-gray-100 p-6 rounded shadow-sm">
															<Box className="flex flex-col items-center w-full gap-3">
																<QRCodeSVG
																	value={qrUrl}
																	size={225} // Sets both width and height
																	level="H" // Error correction level (optional)
																/>
																<span className="text-sm text-center flex-grow">
																	Scan the QR code from your mobile device
																</span>
															</Box>
														</Box>
													</Box>
												)}
											</Box>
										</Box>
									)}
							</Box>

							<Box className="flex justify-end mt-4">
								<Box
									disabled={!canClickContinue2}
									id="continue2"
									className={`bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 focus:outline-none focus:border-none text-xs normal-case ${
										!canClickContinue2
											? "cursor-not-allowed opacity-50"
											: "cursor-pointer"
									}`}
									onClick={() => {
										if (canClickContinue2) {
											handleContinue2Click();
										}
									}}
								>
									Continue <i className="fas fa-arrow-right mr-1"></i>
								</Box>
							</Box>
						</>
					)}
				</Box>
			</Box>
		);
	};

	const deviceSelection = () => {
		return (
			<Box className="flex flex-col gap-4 mb-10">
				<Box className="flex items-center justify-between">
					<h3 className="font-medium md:text-lg text-base">Choose Microphone</h3>
					<Tooltip arrow title={isRecording && "Please stop the recording to change the device."}>
						<DeviceSelector
							deviceList={audioDevices}
							selectedDevice={selectedAudioDevice}
							onDeviceChange={handleAudioDeviceChange}
							type="Microphone"
							disabled={isRecording}
						/>
					</Tooltip>
				</Box>
				<Box className="flex items-center justify-between">
					<h3 className="font-medium md:text-lg text-base">Choose Camera</h3>
					<DeviceSelector
						deviceList={videoDevices}
						onDeviceChange={handleVideoDeviceChange}
						selectedDevice={selectedVideoDevice}
						type={"Camera"}
					/>
				</Box>
			</Box>
		);
	};

	const deviceTest = () => {
		return fallbackDeviceTest ? (
			<Box className="bg-gray-200">
				<Box className="flex justify-center space-x-4 mt-10">
					{createdByAdmin.current && (
						<Box id="step1Bar" className="bg-gray-300 rounded h-2 w-24"></Box>
					)}
					<Box id="step2Bar" className="bg-gray-300 rounded h-2 w-24"></Box>
					<Box id="step3Bar" className="bg-gray-300 rounded h-2 w-24"></Box>
					<Box id="step4Bar" className="bg-gray-300 rounded h-2 w-24"></Box>
					<Box id="step5Bar" className="bg-[#10B981] rounded h-2 w-24"></Box>
				</Box>
				<Box
					id="panel3"
					className="bg-white p-6 rounded-lg shadow-lg w-3/4 md:w-1/2 lg:w-1/3 mt-10 mx-auto"
				>
					<>
						<h2 className="text-center text-2xl font-bold mb-6">Device Test</h2>
						{deviceSelection()}
						<Divider className="my-6" />
						<Box className="mb-6">
							<h3 className="font-medium text-lg mb-2">Microphone Test</h3>
							<p className="text-sm mb-4">
								Click the button below to record a short audio clip. Play it back to ensure
								your microphone is working correctly.
							</p>
							<Box
								id="waveform"
								className="mb-4"
								style={{
									display: "none",
									border: "none",
									borderRadius: "4px",
									marginTop: "1rem",
									width: "80%",
								}}
							></Box>
							<Box className="flex items-center justify-start gap-1">
								<Box
									onClick={async () => {
										if (!isRecording) {
											try {
												if (player && !player.paused) {
													clientLogger(
														"Stopping playback before starting recording.",
													);
													player.pause(); // Stop the playback

													const playButton = document.getElementById("playBack");
													if (playButton) {
														// Check if playButton exists
														playButton.innerHTML =
															'<i class="fas fa-play mr-1"></i>Play Back'; // Reset button text
													} else {
														clientLogger("Play button not found.", {
															level: "warning",
														});
													}
												}
											} catch (error) {
												clientLogger("Error stopping playback before recording.", {
													level: "error",
													error,
												});
											}

											if (micGranted) {
												wavesurfer("start");
												clientLogger("Record Audio clicked.");
												start(); // Start recording
											} else {
												clientLogger(
													"Could not record audio in test page as mic permissions are not granted.",
												);
												setSnackbarMessage("Please grant mic permissions first.");
												setSnackbarOpen(true);
												await requestMicPermission();
											}
										}
									}}
									id="startRecord"
									disabled={isRecording}
									className={`bg-blue-500 text-white py-1 px-3 rounded hover:bg-blue-600 focus:outline-none focus:border-none mb-2 text-sm ${
										isRecording ? "cursor-not-allowed opacity-50" : "cursor-pointer"
									}`}
								>
									<i className="fas fa-microphone mr-1"></i>
									Record Audio
								</Box>

								<Box
									onClick={() => {
										if (isRecording) {
											clientLogger("Stop Recording clicked in device test page.");
											stop();
											wavesurfer("stop");
											const waveform = document.getElementById("waveform");
											waveform.replaceChildren();
											waveform.style.display = "none";
										}
									}}
									disabled={!isRecording}
									id="stopRecord"
									className={`bg-blue-500 text-white py-1 px-3 rounded hover:bg-blue-600 focus:outline-none focus:border-none mb-2 text-sm ${
										isRecording ? "cursor-pointer" : "cursor-not-allowed opacity-50"
									}`}
								>
									<i className="fas fa-stop mr-1"></i>Stop Recording
								</Box>
								<Box
									onClick={() => {
										try {
											if (player) {
												const playButton = document.getElementById("playBack");

												if (player.paused) {
													// Play the audio if it’s paused
													clientLogger(
														"Play back button clicked in device test page",
													);

													player.play();

													if (playButton) {
														// Check if playButton exists
														playButton.innerHTML =
															'<i class="fas fa-pause mr-1"></i>Pause';
														playButton.classList.add("cursor-pointer");
														playButton.classList.remove(
															"cursor-not-allowed",
															"opacity-50",
														);

														player.onended = () => {
															// Revert to "Play Back" when audio ends
															if (playButton) {
																// Check if playButton exists
																playButton.innerHTML =
																	'<i class="fas fa-play mr-1"></i>Play Back';
															}
														};
													} else {
														clientLogger("Play button not found.", {
															level: "warning",
														});
													}
												} else {
													// Pause the audio if it’s playing
													clientLogger("Pause button clicked in device test page");

													player.pause();

													if (playButton) {
														// Check if playButton exists
														playButton.innerHTML =
															'<i class="fas fa-play mr-1"></i>Play Back';
													} else {
														clientLogger("Play button not found.", {
															level: "warning",
														});
													}
												}
											}
										} catch (error) {
											clientLogger("Error during using playback button", {
												level: "error",
												error,
											});
										}
									}}
									disabled={!player || isRecording} // Disable if no player or recording is in progress
									id="playBack"
									className={`bg-blue-500 text-white py-1 px-3 rounded hover:bg-blue-600 focus:outline-none focus:border-none mb-2 text-sm ${
										player && !isRecording
											? "cursor-pointer"
											: "cursor-not-allowed opacity-50"
									}`}
								>
									<i className="fas fa-play mr-1"></i>Play Back
								</Box>
							</Box>
						</Box>

						<Box>
							<h3 className="font-medium text-lg mb-2">Speaker Test</h3>
							<p className="text-sm mb-4">
								Click the button below to play a sample sound. If you can hear the sound, your
								speakers are working correctly.
							</p>
							<Box className="flex items-center justify-between">
								<Box
									onClick={() => {
										clientLogger("Playing sample sound");
										const sampleSound = new Audio("/correct.mp3");
										sampleSound.play().catch((error) => {
											clientLogger("Error during sample sound playback", {
												error,
											});
											console.log(`Error during sample sound playback: ${error}`);
										});
									}}
									className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 focus:outline-none focus:border-none text-xs cursor-pointer"
								>
									<i className="fas fa-volume-up mr-1"></i>
									Play Sample Sound
								</Box>
								<Box
									id="continue2"
									className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 focus:outline-none focus:border-none text-xs cursor-pointer"
									onClick={handleContinue3Click}
								>
									Continue <i className="fas fa-arrow-right mr-1"></i>
								</Box>
							</Box>
						</Box>
					</>
				</Box>
			</Box>
		) : (
			<Box className="bg-gray-200 flex flex-col items-center justify-center">
				<Box className="bg-white p-6 rounded-lg shadow-lg w-3/4 md:w-1/2 lg:w-1/3 mx-auto my-10 mb-0">
					<Box className="flex justify-between items-center mb-4">
						<Typography component="h2" className="text-left text-xl font-bold">
							Microphone Test
						</Typography>
					</Box>
					{deviceSelection()}
					<Typography component="h3" className="font-semibold mb-2 text-left">
						Click the mic icon and read the following text aloud:
					</Typography>
					<Box className="relative mb-4 p-4 bg-blue-50 border border-blue-200 rounded-lg">
						<Box className="md:hidden absolute left-0 top-0 bottom-0 w-1 bg-blue-500"></Box>
						<Typography className="md:text-center text-left font-medium text-lg text-gray-800">
							{transcriptionTexts[randomTranscriptionTextIdx]}
						</Typography>
					</Box>

					<Box
						id="waveform"
						className="mb-4"
						style={{
							display: "none",
							border: "none",
							borderRadius: "4px",
							marginTop: "1rem",
							width: "100%",
						}}
					></Box>
					{!isRecording && !transcriptionTestFirstAttempt && (
						<Typography component="h3" className="font-semibold mb-2">
							Transcription Output:
						</Typography>
					)}
					{transcriptionTestLoader ? (
						<Typography component="p" className="p-2 rounded text-gray-500 mb-4">
							<LinearProgress
								sx={{
									backgroundColor: "rgb(147 197 253)",
									"& .MuiLinearProgress-bar": {
										backgroundColor: "rgb(59 130 246)",
									},
								}}
							/>
						</Typography>
					) : (
						testTranscriptionText && (
							<Typography component="p" className="p-2 rounded text-gray-500 mb-4 text-left">
								{testTranscriptionText}
							</Typography>
						)
					)}

					{retryTranscriptionTest && (
						<Typography component="p" className="text-red-500 text-sm mb-4">
							Please try again, the audio doesn't match the text.
							<ul className="list-disc list-inside">
								<li>Ensure your microphone is working correctly.</li>
								<li>Check that you've selected the right microphone.</li>
							</ul>
						</Typography>
					)}

					<Box className="flex justify-center items-center gap-3">
						<Typography
							variant="span"
							className="text-xs"
							sx={
								showAnimForTranscript
									? {
											animation: "flash 2s infinite",
											"@keyframes flash": {
												"0%, 100%": {
													borderColor: "transparent",
													color: "transparent",
												},
												"50%": {
													borderColor: "darkgray",
													color: "black",
												},
											},
										}
									: { display: "none" }
							}
						>
							Click here to record
						</Typography>

						<Box
							className="w-full"
							sx={
								showAnimForTranscript && {
									display: "inline-block",
									padding: "0.25rem",
									border: "4px solid transparent",
									borderRadius: "0.375rem",
									animation: "flash 2s infinite",
									marginTop: "0.25rem",
									"@keyframes flash": {
										"0%, 100%": {
											borderColor: "transparent",
											color: "transparent",
										},
										"50%": {
											borderColor: "darkgray",
											color: "black",
										},
									},
								}
							}
						>
							<Box
								id="continue2"
								className={`
								flex-grow flex items-center justify-center text-white py-2 px-4 rounded focus:outline-none focus:border-none text-sm w-full text-center font-semibold ${
									transcriptionTestLoader
										? "opacity-50 cursor-not-allowed bg-red-500 hover:bg-red-600"
										: canPassDeviceTest
											? "bg-green-500 hover:bg-green-600 cursor-pointer"
											: "bg-red-500 hover:bg-red-600 cursor-pointer"
								}`}
								style={{ height: "40px" }}
								onClick={async () => {
									if (player) {
										const playButton = document.getElementById("playBack");
										if (playButton && player.paused !== undefined) {
											if (!player.paused) {
												player.pause();
												playButton.innerHTML =
													'<i class="fas fa-play mr-1"></i>Play Back';
											}
										}
									}
									if (canPassDeviceTest) {
										handleContinue3Click();
									} else if (!transcriptionTestLoader) {
										if (isRecording) {
											clientLogger("Stop Recording clicked in device test page.");
											stop();
											wavesurfer("stop");
											const waveform = document.getElementById("waveform");
											waveform.replaceChildren();
											waveform.style.display = "none";
										} else {
											showAnimForTranscript && setShowAnimForTranscript(false);
											if (micGranted) {
												setTestTranscriptionText("");
												setRetryTranscriptionTest(false);
												wavesurfer("start");
												clientLogger("Record Audio clicked for device test.");
												start();
											} else {
												clientLogger(
													"Could not record audio in test page as mic permissions are not granted.",
												);
												setSnackbarMessage("Please grant mic permissions first.");
												setSnackbarOpen(true);
												await requestMicPermission();
											}
										}
									}
								}}
							>
								{!canPassDeviceTest &&
									(isRecording ? (
										"Stop Recording"
									) : (
										<i className="fas mr-2 text-[25px] fa-microphone"></i>
									))}
								{canPassDeviceTest && "Mic Verified. Continue."}
							</Box>
						</Box>

						{!canPassDeviceTest && (
							<Tooltip
								arrow
								title={
									isRecording
										? "Stop the recording to play it back."
										: !player && "Record the audio first to play it back."
								}
							>
								<Box
									id="playBack"
									className={`
								flex-shrink-0 flex items-center justify-center text-white py-2 px-4 rounded focus:outline-none focus:border-none text-sm text-center font-semibold ${
									player && !isRecording
										? "bg-blue-500 hover:bg-blue-600 cursor-pointer"
										: "cursor-not-allowed opacity-50 bg-blue-500"
								}`}
									style={{ minWidth: "auto", maxWidth: "140px", height: "40px" }}
									onClick={() => {
										try {
											if (player) {
												const playButton = document.getElementById("playBack");
												if (playButton) {
													if (player.paused) {
														player.play();
														playButton.innerHTML =
															'<i class="fas fa-pause mr-1"></i>Pause';
														player.onended = () => {
															playButton.innerHTML =
																'<i class="fas fa-play mr-1"></i>Play Back';
														};
													} else {
														player.pause();
														playButton.innerHTML =
															'<i class="fas fa-play mr-1"></i>Play Back';
													}
												} else {
													clientLogger("Play button not found.", {
														level: "warning",
													});
												}
											} else
												clientLogger(
													"player not found when playback was clicked in transcription test.",
													{
														level: "warning",
													},
												);
										} catch (error) {
											clientLogger(
												`Error in playing audio during transcription test: ${error}`,
											);
										}
									}}
									disabled={!player || isRecording}
								>
									<i className="fas fa-play mr-1"></i>Play Back
								</Box>
							</Tooltip>
						)}
					</Box>

					{failedTranscriptionTestCount > 4 && (
						<Typography
							onClick={() => {
								if (player) {
									const playButton = document.getElementById("playBack");
									if (playButton && player.paused !== undefined) {
										if (!player.paused) {
											player.pause();
										}
									}
								}
								setFallbackDeviceTest(true);
							}}
							className="text-blue-500 cursor-pointer underline text-sm mt-[16px]"
						>
							Transcription test not working? Click here to try a different option.
						</Typography>
					)}
				</Box>
			</Box>
		);
	};

	const topicOptions = () => {
		return (
			<Box
				className="bg-gray-200"
				sx={{
					minHeight: "100vh",
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
				}}
			>
				<Paper
					elevation={4}
					sx={{
						bgcolor: "#4F709C",
						width: "min(100% - 2rem, 710px)",
						color: "#fff",
						marginInline: "auto",
						height: "fit-content",
						padding: { md: "3rem 2rem", xs: "2rem 10px" },
						borderRadius: "5px",
						display: "flex",
						flexDirection: "column",
						gap: "1rem",
						marginBlock: "3rem",
					}}
				>
					<Box
						sx={{
							position: "sticky",
							top: "0",
							zIndex: "2",
							backgroundColor: "transparent",
							width: "100%",
							backdropFilter: "blur(5px)",
							paddingBlock: "1rem",
						}}
					>
						<Typography variant="h5" component={"h5"} textAlign={"center"}>
							Pick the skills you would like to be interviewed in
						</Typography>
					</Box>
					<Divider sx={{ width: "98%", marginInline: "auto" }} />
					{isFetchingOpeningData ? <CircularProgress /> : StackOfGroups}
					<Button
						onClick={() => {
							checkCriteria();
						}}
						sx={{
							alignSelf: "flex-end",
							marginRight: "1rem",
							backgroundColor: "rgba(1,1,1,0.4)",
							padding: "10px",
						}}
						endIcon={!isUpdatingSkills && <ArrowRightAltIcon sx={{ color: "white" }} />}
						variant="contained"
					>
						{isUpdatingSkills ? (
							<Stack
								justifyContent={"center"}
								alignItems={"center"}
								flexDirection={"row"}
								gap={"1rem"}
							>
								<CircularProgress sx={{ color: "lavender" }} />
								<Typography sx={{ color: "lavender" }}>Getting Ready ..</Typography>
							</Stack>
						) : (
							<Typography sx={{ color: "lavender" }}>LET's GO</Typography>
						)}
					</Button>
				</Paper>
			</Box>
		);
	};

	const SDContinue = () => {
		return (
			<Box className="bg-gray-200 mt-6 flex items-center justify-center">
				<Box
					className={`bg-white p-6 rounded-lg shadow-lg w-3/4 md:w-1/2 lg:w-2/4 mx-auto ${
						showQrCode && moveToSecondDeviceSetup ? "lg:w-2/4" : "lg:w-1/3"
					}`}
				>
					<h3 className="font-medium text-xl mb-3 text-center">Instructions for Second Device</h3>
					<Box className="mb-5">
						<ul className="list-decimal pl-5 space-y-3">
							<li className="text-sm md:text-base">
								Ensure your browser has camera and microphone permissions enabled.
							</li>
							<li className="text-sm md:text-base">
								Once your position is accurate, the primary device will update automatically.
							</li>
							<li className="text-sm md:text-base">
								If disconnected, you'll be notified on the primary device to refresh and
								reconnect.
							</li>
						</ul>
					</Box>
					<Box className="flex justify-end mt-4">
						<Box
							id="sdContinueBtn"
							className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 focus:outline-none text-sm normal-case cursor-pointer"
							onClick={async () => {
								setCurrentPage(pages.INTERVIEW);
								await perms(openingData, interviewReportData);
							}}
						>
							Continue <i className="fas fa-arrow-right ml-2"></i>
						</Box>
					</Box>
				</Box>
			</Box>
		);
	};

	const interview = () => {
		return (
			<Box
				className="bg-gray-100 flex flex-col items-center"
				// className={`bg-gray-100 flex flex-col items-center min-h-screen ${
				// 	avatarMode && `h-screen`
				// }`}
			>
				<CssBaseline />
				<Box
					className={`flex justify-center w-full min-h-[300px] ${
						isMobile
							? openingData?.isMobileInterviewAllowed
								? "pt-4 mt-6"
								: openingData?.isSecondaryCameraRequired
									? "pt-0 mt-2"
									: "pt-14 mt-6"
							: "pt-14 mt-6"
					}`}
				>
					{isActiveSession ? (
						isMobile && openingData?.isMobileInterviewAllowed ? (
							<Box>
								<Box className="flex flex-row items-center justify-between px-5">
									<i
										className={`fas fa-chevron-left text-xl py-2 rounded-l-lg text-gray-800 ${
											!loading && !audioState && questionIndex - 2 >= 0
												? "hover:bg-gray-200 cursor-pointer"
												: "pointer-events-none opacity-30"
										}`}
										onClick={() => {
											if (!loading && !audioState && questionIndex - 2 >= 0) {
												setQuestionIndex(questionIndex - 2);
											}
										}}
									></i>
									<i
										className={`fas fa-chevron-right text-xl py-2 rounded-l-lg text-gray-800 ${
											!loading && !audioState && questionIndex + 2 <= recentQIndex
												? "hover:bg-gray-200 cursor-pointer"
												: "pointer-events-none opacity-30"
										}`}
										onClick={() => {
											if (
												!loading &&
												!audioState &&
												questionIndex + 2 <= recentQIndex
											) {
												setQuestionIndex(questionIndex + 2);
											}
										}}
									></i>
								</Box>

								<Box className="bg-white p-5 rounded-lg shadow-lg my-2 mx-4 min-w-[300px] relative pt-12 min-h-[250px]">
									{text2SpeechStatus
										? !avatarMode && (
												<i
													className="fas fa-volume-up absolute top-4 right-4 cursor-pointer"
													onClick={() => {
														setText2SpeechStatus(!text2SpeechStatus);
													}}
												></i>
											)
										: !avatarMode && (
												<i
													className="fas fa-volume-mute absolute top-4 right-4 cursor-pointer"
													onClick={() => {
														setText2SpeechStatus(!text2SpeechStatus);
													}}
												></i>
											)}
									{interviewLoading ? (
										<Box
											sx={{
												display: "flex",
												alignItems: "center",
												justifyContent: "center",
												paddingBlock: "1.25rem",
												paddingInline: "1.25rem",
												minHeight: "300px",
											}}
										>
											<CircularProgress /> Getting Your Interview Ready ...
										</Box>
									) : (
										<ChatGpt
											loading={loading}
											setLoading={setLoading}
											recentQIndex={recentQIndex}
											setRecentQIndex={setRecentQIndex}
											currentQ={currentQ}
											setCurrentQ={setCurrentQ}
											questionIndex={questionIndex}
											setQuestionIndex={setQuestionIndex}
											text2SpeechStatus={text2SpeechStatus}
											setText2SpeechStatus={setText2SpeechStatus}
											showIDE={showIDE}
											setShowIDE={setShowIDE}
											drawerWidth={drawerWidth}
											codeExample={codeExample}
											setCodeExample={setCodeExample}
											openingData={openingData}
											interviewReportData={interviewReportData}
											interviewEnded={interviewEnded}
											setInterviewEnded={setInterviewEnded}
											avatarMode={avatarMode}
											setAvatarMode={setAvatarMode}
											audioState={audioState}
											setAudioState={setAudioState}
											lipSync={lipSync}
											setLipSync={setLipSync}
											networkLost={networkLost}
											setNetworkLost={setNetworkLost}
											ping={ping}
											clientLogger={clientLogger}
											currentAudioDeviceId={currentAudioDeviceId}
											orgName={orgName}
											secondDeviceAdded={secondDeviceAdded}
											isPositionAccurate={isPositionAccurate}
											qrUrl={qrUrl}
											autoSkipQTimeoutRef={autoSkipQTimeoutRef}
											autoSkipQIntervalRef={autoSkipQIntervalRef}
											clearAutoSkipTimers={clearAutoSkipTimers}
											remainingSecondsForAutoSkipQ={remainingSecondsForAutoSkipQ}
											setRemainingSecondsForAutoSkipQ={setRemainingSecondsForAutoSkipQ}
										/>
									)}
									{showIDE && openingData?.isCodeEditorRequired && (
										<Box className="w-full mt-4 text-white rounded p-3">
											<CodeEditor
												handleEditorDidMount={handleEditorDidMount}
												editorRef={editorRef}
												extentions={extentions}
												onChange={handleEditorChange}
												language={language}
												languages={languages}
												handleLanguageChange={handleLanguageChange}
												codeExampleValue={codeExample}
											/>
										</Box>
									)}
								</Box>
							</Box>
						) : (
							<>
								<i
									className={`fas fa-chevron-left text-2xl px-5 py-2 pt-20 rounded-l-lg text-gray-800 ${
										!loading && !audioState && questionIndex - 2 >= 0
											? "hover:bg-gray-200 cursor-pointer"
											: "pointer-events-none opacity-30"
									}`}
									onClick={() => {
										if (!loading && !audioState && questionIndex - 2 >= 0) {
											setQuestionIndex(questionIndex - 2);
										}
									}}
								></i>

								<Box className="bg-white p-5 rounded-lg shadow-lg mb-2 mx-2 w-1/2 min-w-300 relative pt-12 min-h-[300px]">
									{text2SpeechStatus
										? !avatarMode && (
												<i
													class="fas fa-volume-up absolute top-4 right-4 cursor-pointer"
													onClick={() => {
														setText2SpeechStatus(!text2SpeechStatus);
													}}
												></i>
											)
										: !avatarMode && (
												<i
													class="fas fa-volume-mute absolute top-4 right-4 cursor-pointer"
													onClick={() => {
														setText2SpeechStatus(!text2SpeechStatus);
													}}
												></i>
											)}
									{interviewLoading ? (
										<Box
											sx={{
												display: "flex",
												alignItems: "center",
												justifyContent: "center",
												paddingBlock: "1.25rem",
												paddingInline: "1.25rem",
												minHeight: "300px",
											}}
										>
											<CircularProgress /> Getting Your Interview Ready ...
										</Box>
									) : (
										<ChatGpt
											loading={loading}
											setLoading={setLoading}
											recentQIndex={recentQIndex}
											setRecentQIndex={setRecentQIndex}
											currentQ={currentQ}
											setCurrentQ={setCurrentQ}
											questionIndex={questionIndex}
											setQuestionIndex={setQuestionIndex}
											text2SpeechStatus={text2SpeechStatus}
											setText2SpeechStatus={setText2SpeechStatus}
											showIDE={showIDE}
											setShowIDE={setShowIDE}
											drawerWidth={drawerWidth}
											codeExample={codeExample}
											setCodeExample={setCodeExample}
											openingData={openingData}
											interviewReportData={interviewReportData}
											interviewEnded={interviewEnded}
											setInterviewEnded={setInterviewEnded}
											avatarMode={avatarMode}
											setAvatarMode={setAvatarMode}
											audioState={audioState}
											setAudioState={setAudioState}
											lipSync={lipSync}
											setLipSync={setLipSync}
											networkLost={networkLost}
											setNetworkLost={setNetworkLost}
											ping={ping}
											clientLogger={clientLogger}
											currentAudioDeviceId={currentAudioDeviceId}
											orgName={orgName}
											secondDeviceAdded={secondDeviceAdded}
											isPositionAccurate={isPositionAccurate}
											qrUrl={qrUrl}
											autoSkipQTimeoutRef={autoSkipQTimeoutRef}
											autoSkipQIntervalRef={autoSkipQIntervalRef}
											clearAutoSkipTimers={clearAutoSkipTimers}
											remainingSecondsForAutoSkipQ={remainingSecondsForAutoSkipQ}
											setRemainingSecondsForAutoSkipQ={setRemainingSecondsForAutoSkipQ}
										/>
									)}
									{showIDE && openingData?.isCodeEditorRequired && (
										<Box className="w-full mt-4 text-white rounded p-3">
											<CodeEditor
												handleEditorDidMount={handleEditorDidMount}
												editorRef={editorRef}
												extentions={extentions}
												onChange={handleEditorChange}
												language={language}
												languages={languages}
												handleLanguageChange={handleLanguageChange}
												codeExampleValue={codeExample}
											/>
										</Box>
									)}
								</Box>

								<i
									className={`fas fa-chevron-right text-2xl px-5 py-2 pt-20 rounded-l-lg text-gray-800 ${
										!loading && !audioState && questionIndex + 2 <= recentQIndex
											? "hover:bg-gray-200 cursor-pointer"
											: "pointer-events-none opacity-30"
									}`}
									onClick={() => {
										if (!loading && !audioState && questionIndex + 2 <= recentQIndex) {
											setQuestionIndex(questionIndex + 2);
										}
									}}
								></i>
							</>
						)
					) : (
						<Box
							className={`bg-white rounded-lg shadow-lg mb-2 mx-2 min-w-300  min-h-[300px] flex flex-col justify-around items-center ${isMobile ? (openingData?.isSecondaryCameraRequired ? "p-5" : "p-5 pt-12") : "w-1/2 p-5 pt-12"}`}
						>
							<div className="text-center text-gray-900 mb-2">
								{isMobile ? (
									interviewEnded ? (
										<h1 className="font-bold text-xl">
											Your interview is done. You will be redirected to the feedback
											page in a few seconds.
										</h1>
									) : failedFaceDetectCount > 9 ? (
										<Box className="text-center">
											<Box className="mb-4 flex justify-center">
												<Box className="flex h-12 w-12 items-center justify-center rounded-full bg-gray-100">
													<i className="fas fa-user-check text-2xl text-black" />
												</Box>
											</Box>
											<h1 className="text-2xl font-bold mb-4">Attention Required</h1>
											<Box className="text-gray-600 space-y-2 mb-6">
												<p className="text-base">
													We're unable to verify proper interview conditions.
												</p>
												<p className="text-sm">
													Please ensure only one participant is visible in the frame
													and face is clearly visible.
												</p>
												<p className="text-sm">
													This page will auto refresh in {screenRetryCountdown}{" "}
													seconds.
												</p>
											</Box>
											<Box className="flex justify-center items-center gap-1 text-sm text-gray-500">
												<span>Page didn't auto refresh?</span>
												<Box
													className="normal-case text-blue-600 hover:text-blue-800 cursor-pointer underline"
													onClick={() => window.location.reload()}
												>
													Click here
												</Box>
											</Box>
										</Box>
									) : meetingEndedForSecondDevice ? (
										<h1 className="font-bold text-xl">You can now close this tab.</h1>
									) : micGranted && cameraGranted ? (
										chimeMeetingStartedForSecondDevice ? (
											<Typography
												variant="subtitle1"
												className="text-center font-semibold"
											>
												{isPositionAccurate
													? "Position is accurate. Please continue the interview on your primary device."
													: "Your feed is getting shared. Position is not accurate. Ensure that you are facing your laptop and your hands and the back of the laptop are visible."}
											</Typography>
										) : meetingEndedForSecondDevice ? (
											<h1 className="font-bold text-xl">You can now close this tab.</h1>
										) : (
											<h1 className="font-bold text-xl">
												Please wait for the meeting to start.
											</h1>
										)
									) : (
										<h1 className="font-bold text-xl">
											Please give both camera and microphone permissions to your browser
											and the website to continue.
										</h1>
									)
								) : interviewEnded ? (
									<h1 className="font-bold text-xl">
										Your interview is done. You will be redirected to the feedback page in
										a few seconds.
									</h1>
								) : (
									<h1 className="font-bold text-xl">
										{failedFaceDetectCount > 9 ? (
											<Box className="text-center">
												<Box className="mb-4 flex justify-center">
													<Box className="flex h-12 w-12 items-center justify-center rounded-full bg-gray-100">
														<i className="fas fa-user-check text-2xl text-black" />
													</Box>
												</Box>
												<h1 className="text-2xl font-bold mb-4">
													Attention Required
												</h1>
												<Box className="text-gray-600 space-y-2 mb-6">
													<p className="text-base">
														We're unable to verify proper interview conditions.
													</p>
													<p className="text-sm">
														Please ensure only one participant is visible in the
														frame and face is clearly visible.
													</p>
													<p className="text-sm">
														This page will auto refresh in {screenRetryCountdown}{" "}
														seconds.
													</p>
												</Box>
												<Box className="flex justify-center items-center gap-1 text-sm text-gray-500">
													<span>Page didn't auto refresh?</span>
													<Box
														className="normal-case text-blue-600 hover:text-blue-800 cursor-pointer underline"
														onClick={() => window.location.reload()}
													>
														Click here
													</Box>
												</Box>
											</Box>
										) : screenShareRef.current ? (
											!screenShared ? (
												<Box className="text-center">
													<Box className="mb-4 flex justify-center">
														<Box className="flex h-12 w-12 items-center justify-center rounded-full bg-gray-100">
															<i className="fas fa-desktop text-2xl text-black" />
														</Box>
													</Box>
													<h1 className="text-2xl font-bold mb-4">
														Screen Share Missing
													</h1>
													<Box className="text-gray-600 space-y-2 mb-6">
														<p className="text-base">
															Looks like your screen is not accessible.
														</p>
														<p className="text-sm">
															Screen share permission is required to maintain
															interview integrity.
														</p>
														<p className="text-sm">
															This page will auto refresh in{" "}
															{screenRetryCountdown} seconds.
														</p>
													</Box>
													<Box className="flex justify-center items-center gap-1 text-sm text-gray-500">
														<span>Page didn't auto refresh?</span>
														<Box
															className="normal-case text-blue-600 hover:text-blue-800 cursor-pointer underline"
															onClick={() => window.location.reload()}
														>
															Click here
														</Box>
													</Box>
												</Box>
											) : (
												"Please refresh the page to continue or close the tab to exit."
											)
										) : micStreamRef.current ? (
											!micGranted ? (
												<Box className="text-center">
													<Box className="mb-4 flex justify-center">
														<Box className="flex h-12 w-12 items-center justify-center rounded-full bg-gray-100">
															<i className="fas fa-microphone text-2xl text-black" />
														</Box>
													</Box>
													<h1 className="text-2xl font-bold mb-4">
														Microphone Missing
													</h1>
													<Box className="text-gray-600 space-y-2 mb-6">
														<p className="text-base">
															Looks like your microphone is not accessible.
														</p>
														<p className="text-sm">
															Microphone permission is required for the
															interview.
														</p>
														<p className="text-sm">
															This page will auto refresh in{" "}
															{screenRetryCountdown} seconds.
														</p>
													</Box>
													<Box className="flex justify-center items-center gap-1 text-sm text-gray-500">
														<span>Page didn't auto refresh?</span>
														<Box
															className="normal-case text-blue-600 hover:text-blue-800 cursor-pointer underline"
															onClick={() => window.location.reload()}
														>
															Click here
														</Box>
													</Box>
												</Box>
											) : (
												"Please refresh the page to continue or close the tab to exit."
											)
										) : videoStreamRef.current ? (
											!cameraGranted ? (
												<Box className="text-center">
													<Box className="mb-4 flex justify-center">
														<Box className="flex h-12 w-12 items-center justify-center rounded-full bg-gray-100">
															<i className="fas fa-camera text-2xl text-black" />
														</Box>
													</Box>
													<h1 className="text-2xl font-bold mb-4">
														Webcam Missing
													</h1>
													<Box className="text-gray-600 space-y-2 mb-6">
														<p className="text-base">
															Looks like your webcam is not accessible.
														</p>
														<p className="text-sm">
															Webcam permission is required for monitoring your
															video feed to maintain interview integrity.
														</p>
														<p className="text-sm">
															This page will auto refresh in{" "}
															{screenRetryCountdown} seconds.
														</p>
													</Box>
													<Box className="flex justify-center items-center gap-1 text-sm text-gray-500">
														<span>Page didn't auto refresh?</span>
														<Box
															className="normal-case text-blue-600 hover:text-blue-800 cursor-pointer underline"
															onClick={() => window.location.reload()}
														>
															Click here
														</Box>
													</Box>
												</Box>
											) : (
												"Please refresh the page to continue or close the tab to exit."
											)
										) : (
											"Please refresh the page to continue or close the tab to exit."
										)}
									</h1>
								)}
							</div>
							{isMobile &&
								!interviewEnded &&
								openingData?.isSecondaryCameraRequired &&
								interviewReportData?.preferredName &&
								!interviewReportData?.interviewCompleted &&
								micStream &&
								videoStream && (
									<Box className="flex items-center justify-center">
										{/* <Typography variant="h6" className="text-center">
											videoTiles should be here
										</Typography> */}
										<VideoTiles
											preferredName="secondDevice"
											interviewEnded={interviewEnded}
											existingMeetingId={
												interviewReportData.isLatestMeetingIdInvalid
													? null
													: interviewReportData.meetingIds &&
														  interviewReportData.meetingIds.length > 0
														? interviewReportData.meetingIds[
																interviewReportData.meetingIds.length - 1
															]
														: null
											}
											micStream={micStream}
											videoStream={videoStream}
											// isRecordingEnabled={isRecordingEnabled}
											isRecordingEnabledRef={isRecordingEnabledRef}
											isMobile={isMobile}
											chimeMeetingStartedForSecondDevice={
												chimeMeetingStartedForSecondDevice
											}
											setChimeMeetingStartedForSecondDevice={
												setChimeMeetingStartedForSecondDevice
											}
											candidateName={
												interviewReportData?.firstName +
												" " +
												interviewReportData?.lastName
											}
											openingTitle={openingData?.title}
											clientLogger={clientLogger}
											setChimeStarted={setChimeStarted}
											setMeetingEndedForSecondDevice={setMeetingEndedForSecondDevice}
											isSecondaryCameraRequired={openingData?.isSecondaryCameraRequired}
											isPositionAccurate={isPositionAccurate}
											setIsPositionAccurate={setIsPositionAccurate}
											failedFaceDetectCount={failedFaceDetectCount}
											setFailedFaceDetectCount={setFailedFaceDetectCount}
											activeSessionRef={activeSessionRef}
										/>
									</Box>
								)}
						</Box>
					)}
				</Box>
			</Box>
		);
	};

	const confirmEmail = () => {
		return (
			<Box className="">
				{openingStatus ? (
					interviewEnded ? (
						<Box
							id="panel1"
							className="bg-white p-6 rounded-lg shadow-lg w-3/4 md:w-1/2 lg:w-1/3 mt-10 mx-auto"
						>
							<h2 className="text-center text-2xl font-bold mb-6">Interview has ended.</h2>
						</Box>
					) : showQrCode ? (
						deviceLimitReached ? (
							<Box
								id="panel1"
								className="bg-white p-6 rounded-lg shadow-lg w-3/4 md:w-1/2 lg:w-1/3 mt-10 mx-auto"
							>
								<h2 className="text-center text-2xl font-bold mb-6">
									Device limit reached. You can only connect with two devices.
								</h2>
							</Box>
						) : (
							<Box
								id="panel1"
								className="bg-white p-6 rounded-lg shadow-lg w-3/4 md:w-1/2 lg:w-1/3 mt-10 mx-auto"
							>
								<h2 className="text-center text-2xl font-bold mb-6">User Information</h2>
								<form onSubmit={handleUserEmailSubmit}>
									<Box className="mb-4">
										<label
											for="email"
											className="block text-sm font-medium text-gray-600 mb-2"
										>
											Email Address
										</label>
										<TextField
											sx={{
												"& fieldset": {
													border: "1px solid #F2F3F5",
												},
											}}
											color="black"
											size="small"
											required
											type="text"
											id="email"
											name="email"
											className=" border-gray-200 rounded w-full"
											onChange={handleUserDetailsChange}
											disabled={savingUserDeets}
										/>
									</Box>
									<Box className="flex justify-end mt-4">
										<Box
											onClick={handleUserEmailSubmit}
											id="continue1"
											className={`normal-case cursor-pointer bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 focus:outline-none focus:border-none text-xs ${
												savingUserDeets && "cursor-not-allowed opacity-50"
											}`}
											type="button"
											disabled={savingUserDeets}
										>
											{savingUserDeets ? (
												<span>
													{"Saving Details... "}{" "}
													<i className="fas fa-circle-notch fa-spin mr-1"></i>
												</span>
											) : (
												<span>
													{"Continue "} <i className="fas fa-arrow-right mr-1"></i>
												</span>
											)}
										</Box>
									</Box>
								</form>
							</Box>
						)
					) : (
						<Box
							id="panel1"
							className="bg-white p-6 rounded-lg shadow-lg w-3/4 md:w-1/2 lg:w-1/3 mt-10 mx-auto"
						>
							<h2 className="text-center text-2xl font-bold mb-6">
								Multiple devices are not allowed.
							</h2>
						</Box>
					)
				) : (
					<Box
						id="panel1"
						className="bg-white p-6 rounded-lg shadow-lg w-3/4 md:w-1/2 lg:w-1/3 mt-10 mx-auto"
					>
						<h2 className="text-center text-2xl font-bold mb-6">
							This opening is no longer accepting new applications.
						</h2>
					</Box>
				)}
			</Box>
		);
	};

	const handleAfterCandidatePhotoCapture = (message) => {
		if (message) {
			setSnackbarOpen(true);
			setSnackbarMessage(message);
		}
		if (temporaryOpeningData?.isFaceMatchRequired === false) {
			setCurrentPage(pages.DEVICE_TEST);
		} else {
			setCurrentPage(pages.IDENTITY_VERIFICATION);
		}
	};

	const handleAfterIdentityVerification = (message) => {
		setSnackbarOpen(true);
		setSnackbarMessage(message);
		setCurrentPage(pages.DEVICE_TEST);
	};

	return (
		<ThemeProvider theme={theme}>
			<Box
				// className={`bg-gray-200 h-screen ${
				// 	avatarMode && 'overflow-hidden'
				// }`}
				className={`${currentPage === pages.INTERVIEW ? "bg-gray-100" : "bg-gray-200"} min-h-screen`}
			>
				{/* {openingData?.supportName && openingData?.supportPhone && (
					<Box className="bg-white text-gray-600 text-sm p-2 text-center border-b border-gray-200 mb-[0px]">
						Need help? Contact <strong>({openingData?.supportName})</strong> at{" "}
						<Link
							href={`tel:${openingData?.supportPhone}`}
							className="text-blue-500 hover:text-blue-600 no-underline"
						>
							{openingData?.supportPhone}
						</Link>
					</Box>
				)} */}
				{openingData?.allowSupportContact
					? openingData?.supportName &&
						(openingData?.supportPhone || openingData?.supportEmail) && (
							<Box className="bg-white text-gray-600 text-sm p-2 text-center border-b border-gray-200 mb-[0px] ">
								Need help? Contact <strong>{openingData?.supportName}</strong> at{" "}
								{openingData?.supportPhone && (
									<>
										{showDefaultSupportNumber && (
											<>
												<Link
													href={`tel:+918000926262`}
													target="_blank"
													className="text-blue-500 hover:text-blue-600 no-underline"
												>
													+918000926262
												</Link>
												/
											</>
										)}

										<Link
											href={`tel:${openingData?.supportPhone}`}
											target="_blank"
											className="text-blue-500 hover:text-blue-600 no-underline"
										>
											{openingData?.supportPhone}
										</Link>
									</>
								)}{" "}
								{openingData?.supportEmail && (
									<Link
										href={`mailto:${openingData?.supportEmail}`}
										target="_blank"
										className="text-blue-500 hover:text-blue-600 no-underline"
									>
										{openingData?.supportEmail}
									</Link>
								)}
							</Box>
						)
					: showDefaultSupportNumber && (
							<Box className="bg-white text-gray-600 text-sm p-2 text-center border-b border-gray-200 mb-[0px] ">
								Need help? Contact{" "}
								<Link
									href={`tel:+918000926262`}
									target="_blank"
									className="text-blue-500 hover:text-blue-600 no-underline"
								>
									+918000926262
								</Link>
							</Box>
						)}
				<Toolbar
					className="sticky top-0 w-full bg-white p-4 flex justify-between items-center border-b border-gray-200"
					sx={{
						zIndex: "1000",
					}}
				>
					<Box className="flex flex-row items-center justify-center">
						{orgLogoUrl && (
							<Box className="flex items-center">
								<img
									src={orgLogoUrl}
									alt="logo"
									className="h-10 w-10 mr-2"
									style={{ objectFit: "contain" }}
								/>
							</Box>
						)}
						<Typography
							variant="span"
							className={`font-bold ${
								isMobile && openingData?.isMobileInterviewAllowed
									? "text-lg"
									: orgLogoUrl
										? "text-xl"
										: "pl-10 text-xl"
							}`}
						>
							{orgName ? orgName : "Acme Corp"}
						</Typography>
					</Box>
					{isActiveSession && !interviewEnded && currentPage === pages.INTERVIEW && (
						<Box
							className={`relative mr-4 ${
								isMobile && openingData?.isMobileInterviewAllowed ? "w-28" : "w-32"
							}`}
						>
							<Typography
								component={"span"}
								variant="span"
								className={`block mb-1  ${
									isMobile && openingData?.isMobileInterviewAllowed ? "text-xs" : "text-sm"
								}`}
							>
								Progress
							</Typography>
							<LinearProgress
								className="bg-gray-300 w-full rounded h-2"
								variant="determinate"
								value={
									Math.round((currentQ * 100) / (openingData?.maxQuestions + 1)) > 90
										? 90
										: Math.round((currentQ * 100) / (openingData?.maxQuestions + 1))
								}
								color="green"
							/>
						</Box>
					)}
					{!interviewEnded &&
						openingData?.proctoring &&
						(isMobile ? openingData?.isMobileInterviewAllowed : isActiveSession) &&
						interviewReportData?.preferredName &&
						!interviewReportData?.interviewCompleted &&
						micStream &&
						videoStream &&
						(screenStream ? screenStream : isMobile) && (
							<Box className="flex items-center">
								{/* <Typography variant="h6" className="text-center">
									videoTiles should be here
								</Typography> */}
								<VideoTiles
									preferredName={
										openingData?.isSecondaryCameraRequired
											? "firstDevice"
											: interviewReportData?.preferredName
									}
									interviewEnded={interviewEnded}
									existingMeetingId={
										interviewReportData.isLatestMeetingIdInvalid
											? null
											: interviewReportData.meetingIds &&
												  interviewReportData.meetingIds.length > 0
												? interviewReportData.meetingIds[
														interviewReportData.meetingIds.length - 1
													]
												: null
									}
									micStream={micStream}
									videoStream={videoStream}
									screenStream={screenStream}
									// isRecordingEnabled={isRecordingEnabled}
									isRecordingEnabledRef={isRecordingEnabledRef}
									isMobile={isMobile}
									candidateName={
										interviewReportData?.firstName + " " + interviewReportData?.lastName
									}
									openingTitle={openingData?.title}
									clientLogger={clientLogger}
									setChimeStarted={setChimeStarted}
									isSecondaryCameraRequired={openingData?.isSecondaryCameraRequired}
									isPositionAccurate={isPositionAccurate}
									setIsPositionAccurate={setIsPositionAccurate}
									failedFaceDetectCount={failedFaceDetectCount}
									setFailedFaceDetectCount={setFailedFaceDetectCount}
									activeSessionRef={activeSessionRef}
								/>
							</Box>
						)}
					{currentPage === pages.INTERVIEW && isActiveSession && (
						<Box className="relative inline-block text-left" ref={dropdownRef}>
							<Button
								type="button"
								color="primary"
								className="normal-case text-xs bg-blue-500 text-white py-2 px-3 rounded hover:bg-blue-600 focus:outline-none focus:border-none"
								onClick={() => setShowDropdown(!showDropdown)}
							>
								<Box>
									<i className="fas fa-sign-out-alt mr-1"></i>
									Exit Interview
								</Box>
							</Button>

							{showDropdown && (
								<Box className="origin-top-right absolute right-0 mt-2 w-44 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
									<Box className="py-1">
										<Tooltip
											title="You may be able to join back later."
											placement="left"
											arrow
										>
											<MenuItem
												onClick={() => {
													setShowDropdown(false);
													audioStateRef.current?.pause?.();
													clientLogger("User clicked leave interview button");
													setEventName("leaveInterviewButton");
													setCurrentPage(pages.CAN_LEAVE);
													clientLogger(
														"FinalBeaconCall triggered by clicking the leave interview button.",
													);
													const data = JSON.stringify({
														activeSession: false,
														interviewReportId: params.interviewReportId,
														candidateName:
															interviewReportData?.firstName +
															" " +
															interviewReportData?.lastName,
														preferredName: interviewReportData?.preferredName
															? interviewReportData?.preferredName
															: "noPreferredName",
														openingTitle: openingData?.title
															? openingData.title
															: "noOpeningTitle",
														eventName: "beforeunload",
														clickedLeaveInterview: true,
													});
													const beaconSent = navigator.sendBeacon(
														"/api/interviewReports/finalBeaconCall",
														new Blob([data], {
															type: "application/json",
														}),
													);
													if (!beaconSent) {
														console.log(">>>>>> Beacon could not be sent");
													} else {
														setEventName("beforeunload");
														setIsActiveSession(false);
													}
													// Attempt to close the tab
													// window.close();
													// setTimeout(() => {
													// 	if (!window.closed) {

													// 	}
													// }, 500);
												}}
												className="text-sm text-gray-700 hover:bg-gray-100 cursor-pointer"
											>
												<span>Leave Interview</span>
											</MenuItem>
										</Tooltip>
										<Tooltip
											title="This will end the interview, and you won't be able to join back again."
											placement="left"
											arrow
										>
											<MenuItem
												onClick={async () => {
													setShowDropdown(false);
													audioStateRef.current?.pause?.();
													clientLogger("User clicked end interview button");
													// await clearSessionStorage();
													setEventName("endInterviewButton");
													setInterviewEnded(true);
													await endInterviewWithGivenId(params.interviewReportId);
													setTimeout(() => {
														navigate("/end", {
															replace: true,
															state: {
																orgName: orgName,
															},
														});
													}, 5000);
												}}
												className="text-sm text-gray-700 hover:bg-gray-100 cursor-pointer"
											>
												<span>End Interview</span>
											</MenuItem>
										</Tooltip>
									</Box>
								</Box>
							)}
						</Box>
					)}
				</Toolbar>
				{currentPage === pages.CHECK_PERMISSIONS && permissionChecker()}
				{currentPage === pages.DEVICE_TEST && deviceTest()}
				{currentPage === pages.TOPICS_PICKER && topicOptions()}
				{currentPage === pages.SD_CONTINUE && <SDContinue />}
				{currentPage === pages.INTERVIEW && interview()}
				{currentPage === pages.CONFIRM_EMAIL && confirmEmail()}
				{currentPage === pages.USE_PREVIOUS_DEVICE && <DifferentDevice />}
				{currentPage === pages.MULTIPLE_TABS && <MultipleTabs />}
				{currentPage === pages.INVALID_TOKEN && <InvalidResumeToken />}
				{currentPage === pages.RESUME_PROMPT && <ResumePrompt />}
				{currentPage === pages.LOADING && <Loading />}
				{currentPage === pages.CAN_LEAVE && <CanLeave />}
				{currentPage === pages.JOINED_MOBILE && <JoinedOnMobile />}
				{currentPage === pages.MOBILE_NOT_ALLOWED && <MobileNotAllowed />}
				{currentPage === pages.CORRECTION_FORM && (
					<CorrectionForm
						isResumeRequired={isResumeRequired}
						report={temporaryReportData}
						isMobileInterviewAllowed={isMobileInterviewAllowed}
						orgName={orgName}
						opening={temporaryOpeningData}
					/>
				)}
				{currentPage === pages.NETWORK_LOST && (
					<NetworkLost
						ping={ping}
						loadingForLost={loadingForLost}
						setLoadingForLost={setLoadingForLost}
						retryCountdown={retryCountdown}
						autoRetried={autoRetried}
						setAutoRetried={setAutoRetried}
					/>
				)}
				{currentPage === pages.JOINED_EARLY && joinedEarly()}
				{currentPage === pages.TIMEOUT && <InterviewTimeout scheduledTime={scheduledTime} />}
				{currentPage === pages.CANCELLED && <Cancelled />}
				{showMicModal && <MicModal showModal={showMicModal} setShowModal={setShowMicModal} />}
				{showCamModal && <CamModal showModal={showCamModal} setShowModal={setShowCamModal} />}
				{showModal403 && <Modal403 showModal={showModal403} setShowModal={setShowModal403} />}
				{showScreenModal && (
					<ScreenModal showModal={showScreenModal} setShowModal={setShowScreenModal} />
				)}

				{currentPage === pages.WELCOME && (
					<Welcome
						report={temporaryReportData}
						opening={temporaryOpeningData}
						setCurrentPage={setCurrentPage}
					/>
				)}
				{currentPage === pages.CHEATING_WARNING && (
					<CheatingWarning
						_id={temporaryReportData?._id}
						createdByAdmin={temporaryReportData?.createdByAdmin}
						isFaceMatchRequired={temporaryOpeningData?.isFaceMatchRequired}
					/>
				)}

				{currentPage === pages.CANDIDATE_PHOTO_CAPTURE && (
					<CandidatePhotoCapture
						userinterviewreport_id={temporaryReportData?._id}
						isCreatedByAdmin={temporaryReportData?.createdByAdmin}
						handleAfterCandidatePhotoCapture={handleAfterCandidatePhotoCapture}
						videoDevices={videoDevices}
						selectedVideoDevice={selectedVideoDevice}
						handleVideoDeviceChange={handleVideoDeviceChange}
						videoStream={videoStream}
						opening={temporaryOpeningData}
					/>
				)}
				{currentPage === pages.IDENTITY_VERIFICATION && (
					<IdentityVerification
						userinterviewreport_id={temporaryReportData?._id}
						isCreatedByAdmin={temporaryReportData?.createdByAdmin}
						handleAfterIdentityVerification={handleAfterIdentityVerification}
						videoDevices={videoDevices}
						selectedVideoDevice={selectedVideoDevice}
						handleVideoDeviceChange={handleVideoDeviceChange}
						videoStream={videoStream}
					/>
				)}
				{currentPage === pages.INTERVIEW_LANGUAGE_OPTION && (
					<InterviewLanguageSelection
						openingData={openingData}
						interviewReporData={interviewReportData}
						setCurrentPage={setCurrentPage}
						setInterviewReportData={setInterviewReportData}
					/>
				)}
				<Box
					className={`flex justify-center items-center pb-[20px] ${
						currentPage === pages.TOPICS_PICKER ? "mt-[-15px]" : "mt-8"
					}`}
				>
					<Typography variant="span" className="text-xs text-gray-500">
						Powered by{" "}
					</Typography>
					<img src={poster} alt="zinterview" className="h-[48px] object-contain" />
				</Box>
				<Snackbar
					open={snackbarOpen}
					autoHideDuration={3500}
					onClose={handleSnackbarClose}
					message={snackbarMessage}
					action={action}
				/>
				<LogRocketInterviewSessionController interviewReportData={interviewReportData} />
			</Box>
		</ThemeProvider>
	);
}

export default Interview;
