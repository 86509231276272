import React, { useState, useEffect } from "react";
import { Box, Button, Checkbox, FormControlLabel, Modal, TextField } from "@mui/material";
import ReportsLoader from "../components/Skeleton-Loaders/ReportsLoader";
import {
	downloadAllOrganizationInterviews,
	getAllOrganizationInterviews,
} from "../utilities/organizationApi";
import { toast } from "react-toastify";
import ReportsDataGridAllOrganization from "../components/grid/ReportsDataGridAllOrganization";
import { useNavigate } from "react-router-dom";
import { Download } from "@mui/icons-material";

const TabularAllOrganizationsEvaluations = () => {
	const [isLoading, setIsLoading] = useState(false);
	const [allCandidates, setAllCandidates] = useState([]);
	const [showCompletedInterviews, setShowCompletedInterviews] = useState(false);
	const [selectedMonth, setSelectedMonth] = useState(new Date().toISOString().slice(0, 7));
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(100);
	const [totalRecords, setTotalRecords] = useState(0);
	const history = useNavigate();
	const fetchCandidates = async (year, month) => {
		try {
			setIsLoading(true);
			const response = await getAllOrganizationInterviews(
				year,
				month,
				page + 1,
				rowsPerPage,
				showCompletedInterviews,
			);
			setAllCandidates(response.data);
			setTotalRecords(response.totalRecords);
		} catch (error) {
			console.error(error);
			toast.error("Error fetching candidate interviews.");
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		const [year, month] = selectedMonth.split("-");
		fetchCandidates(year, month);
	}, [selectedMonth, page, rowsPerPage, showCompletedInterviews]);

	const handleMonthChange = (event) => {
		setSelectedMonth(event.target.value);
		setPage(0);
	};

	return (
		<Box className="bg-gray-100 min-h-screen p-6">
			<div className="mx-auto mb-2 flex justify-between items-center flex-wrap gap-2 ">
				<Button
					variant="outlined"
					className="text-sm px-3 py-1"
					onClick={() => history("/admin/interviews")}
				>
					Back
				</Button>
				<DonwloadReports
					showCompletedInterviews={showCompletedInterviews}
					selectedMonth={selectedMonth}
					totalRecords={totalRecords}
				/>
			</div>
			<Box className="container mx-auto">
				<Box className="bg-white p-6 rounded-lg shadow-md">
					<div className="flex justify-between flex-wrap mb-2">
						<FormControlLabel
							control={
								<Checkbox
									checked={showCompletedInterviews}
									onChange={() => setShowCompletedInterviews((prev) => !prev)}
									inputProps={{ "aria-label": "controlled" }}
									size="small"
									disabled={isLoading}
								/>
							}
							label="Show All Completed Interviews"
							sx={{
								"& .MuiFormControlLabel-label": { fontSize: "0.9rem" },
							}}
						/>
						{/* Date Filter (Month Selector) */}
						<TextField
							id="monthFilter"
							type="month"
							value={selectedMonth}
							onChange={handleMonthChange}
							variant="outlined"
							size="small"
							InputLabelProps={{ shrink: true }}
							sx={{ width: "200px" }} // Ensures full width
						/>

						{/* Checkbox for Completed Interviews */}
					</div>
					{isLoading ? (
						<ReportsLoader />
					) : (
						<ReportsDataGridAllOrganization
							candidates={allCandidates}
							page={page}
							rowsPerPage={rowsPerPage}
							totalRecords={totalRecords}
							setPage={setPage}
							setRowsPerPage={setRowsPerPage}
						/>
					)}
				</Box>
			</Box>
		</Box>
	);
};

export default TabularAllOrganizationsEvaluations;

const DonwloadReports = ({ selectedMonth, showCompletedInterviews, totalRecords }) => {
	const [isLoading, setIsLoading] = useState(false);
	const [year, month] = selectedMonth.split("-");
	const handleDownloadExcel = async () => {
		try {
			setIsLoading(true);
			const response = await downloadAllOrganizationInterviews(year, month, showCompletedInterviews);
			if (response.data) {
				const url = window.URL.createObjectURL(new Blob([response.data]));
				const link = document.createElement("a");
				link.href = url;
				link.setAttribute("download", `zinterview_${month}_${year}_data.xlsx`);
				document.body.appendChild(link);
				link.click();
				link.remove();
			}
		} catch (error) {
			console.log(error);
		} finally {
			setIsLoading(false);
		}
	};
	return (
		<Button
			variant="outlined"
			className="text-sm px-3 py-1"
			onClick={handleDownloadExcel}
			disabled={isLoading || totalRecords > 4000}
			title={totalRecords > 4000 ? "Data too large to download" : ""}
			startIcon={<Download />}
		>
			{isLoading ? "Downloading..." : "Download"}
		</Button>
	);
};
